<template>
    <painel-component :items="itemsOne">

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Nome do APP:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect disabled v-model="formData.company_id" label="name" :searchable="true"
                                 :options="selectCompanyList" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <hr>
            <h5 class="text-uppercase">Configurações do Layout</h5>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Fonte:</label>
                    <Multiselect disabled v-model="formData.font_id" :searchable="true" :options="[{label: 'Arial', value: 1}]"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Alinhamento Texto:</label>
                    <Multiselect disabled v-model="formData.text_align" :searchable="true" :options="selectAlignment"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Margem Topo %:</label>
                    <input-component disabled v-model="formData.margin_top" type-input="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Transparência %:</label>
                    <input-component disabled v-model="formData.list_transparency" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Cor da 1º fonte:</label>
                    <input-component disabled v-model="formData.font_color_1" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Cor da 1º listra:</label>
                    <input-component disabled v-model="formData.list_color_1" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Cor da 2º fonte:</label>
                    <input-component disabled v-model="formData.font_color_2" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Cor da 2º listra:</label>
                    <input-component disabled v-model="formData.list_color_2" type-input="color"/>
                </row-col-component>
            </row-component>

            <row-component class="my-4">
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Texto em Maiúsculo:</label>
                    <input disabled v-model="formData.text_upercase" type="radio" id="no-uppercase" value="1">
                    <label for="no-uppercase">Não</label>

                    <input disabled v-model="formData.text_upercase" type="radio" id="yes-uppercase" value="2">
                    <label for="yes-uppercase">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Fonte em Negrito:</label>
                    <input disabled v-model="formData.font_bold" type="radio" id="no-bold" value="1">
                    <label for="no-bold">Não</label>

                    <input disabled v-model="formData.font_bold" type="radio" id="yes-bold" value="2">
                    <label for="yes-bold">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Fonte em Itálico:</label>
                    <input disabled v-model="formData.font_italic" type="radio" id="no-italic" value="1">
                    <label for="no-italic">Não</label>

                    <input disabled v-model="formData.font_italic" type="radio" id="yes-italic" value="2">
                    <label for="yes-italic">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Fonte em Sublinhado:</label>
                    <input disabled v-model="formData.font_underline" type="radio" id="no-underlined" value="1">
                    <label for="no-underlined">Não</label>

                    <input disabled v-model="formData.font_underline" type="radio" id="yes-underlined" value="2">
                    <label for="yes-underlined">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: "MenuTableShow",
    components: {
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.checkACL()
        this.loadStorage()
        this.loadItems()
        this.$store.dispatch("changeNamePage", "App Menu Tabela")
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                route: "/menu-table/search",
                name: "Voltar",
            },

            formData: {
                client_id: "",
                name: "",
                status: "",
                company_id: "",
                font_id: "",
                margin_top: "",
                list_transparency: "",
                text_align: "",
                font_color_1: "#000000",
                font_color_2: "#000000",
                list_color_1: "#000000",
                list_color_2: "#000000",
                text_upercase: "",
                font_bold: "",
                font_italic: "",
                font_underline: ""
            },

            params: {
                id: this.id,
                client_id: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadMenuTableApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 15)
                .then((r) => {
                    if (r.data.indexOf('app-menu-table-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectAlignment() {
            return this.$store.state.var.selectAlignment
        }
    }
}
</script>