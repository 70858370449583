<template>
    <painel-component class="shadow-none painel">
        <div @click="$emit('close')" role="button" class="btn-close"></div>

        <table class="table table-striped">
            <thead>
            <tr>
                <th scope="col" style="width: 40%">Nome</th>
                <th scope="col" style="width: 40%">Dia Da Semana | Dia Fechamento</th>
                <th scope="col" style="width: 10%">Liga / Desliga</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Dia Da Semana | Dia Fechamento"
                    v-if="parseInt(data.date_close_check) === 2">
                    {{ data.date_close }}
                </td>
                <td v-else data-label="Dia Da Semana | Dia Fechamento">
                    {{ data.days_week }}
                </td>
                <td data-label="Liga / Desliga">
                    <label class="switches position-relative"
                           :class="[{ 'switches_on': parseInt(data.type) === 1 }, { 'switches_off': parseInt(data.type) === 2 }]">
                        <input disabled type="checkbox" :checked="parseInt(data.type) === 2">
                        <span class="slider"></span>
                    </label>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "PlayerHours",
    components: {
        PainelComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadItems()
    },

    data() {
        return {
            object: {}
        }
    },

    methods: {

        loadItems() {
            let formData = {
                id: this.id,
                client_id: localStorage.getItem("id_client")
            }

            this.$store.dispatch("hoursPlayer", formData)
                .then((response) => {
                    this.object = response
                }).catch(() => {
                toast.error("Erro desconhecido!")
            })
        }

    },
}
</script>

<style scoped src="@/assets/css/player.css">

</style>

<style scoped>
.painel {
    width: 60%;
    height: 50%;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 1024px) {
    .painel {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
        padding-top: 2.5rem;
    }
}

@media (max-width: 991px) {
    .painel-component {
        margin-top: 5%;
    }
}
</style>