<template>
    <div class="modal-w position-fixed d-grid" v-if="entrance.show || exit.show">
        <div class="modal-p position-relative p-4 pt-5" v-if="entrance.show">
            <div @click="entranceClose" role="button" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.product_id}">
                        <label>Produto:</label>
                        <Multiselect v-model="entrance.formData.product_id" label="name" :options="selectProductList"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.local_id}">
                        <label>Local:</label>
                        <Multiselect v-model="entrance.formData.local_id" :options="selectLocalList" :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.price}">
                        <label>Preço Unitário:</label>
                        <input-component v-model="entrance.formData.price" v-money="MONEY" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.amount}">
                        <label>Quantidade:</label>
                        <input-component v-model="entrance.formData.amount" type-input="text"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.sector}">
                        <label>Setor:</label>
                        <Multiselect v-model="entrance.formData.sector" :options="selectSector" :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.initial_inventory}">
                        <label>Estoque Inicial:</label>
                        <input-component v-model="entrance.formData.initial_inventory" type-input="number"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.column}">
                        <label>Coluna:</label>
                        <input-component v-model="entrance.formData.column" type-input="number"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.shelf}">
                        <label>Prateleira:</label>
                        <input-component v-model="entrance.formData.shelf" type-input="number"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @click="submitEntrance" name="Adicionar" icon="plus"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>

        <div class="modal-p position-relative p-4 pt-5" v-if="exit.show">
            <div @click="exitClose" role="button" class="btn-close"></div>

            <form>
                <row-component>
                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.product_id}">
                        <label>Produto:</label>
                        <Multiselect v-model="exit.formData.product_id" label="name" :options="selectProductList"
                                     @select="selectBatch(exit.formData.product_id)"
                                     :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.amount}">
                        <label>Quantidade:</label>
                        <input-component v-model="exit.formData.amount" type-input="number"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : errors.product_batch_id}">
                        <label>Lote:</label>
                        <Multiselect v-model="exit.formData.product_batch_id" :searchable="true"
                                     :options="selectProductBatchList" :noResultsText="noResultsText"
                                     :noOptionsText="noOptionsText"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit :disabled="sendForm" @click="submitExit" name="Remover" icon="close"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo:</label>
                    <Multiselect v-model="formData.type" :options="selectEntranceExit" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-submit v-if="buttonCheck.entrance" @click="entrance.show = true" class="me-2" icon="arrow up icon"/>
                    <button-submit v-if="buttonCheck.exit" @click="exit.show = true" icon="arrow down icon"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 15%">Código</th>
                <th scope="col" style="width: 25%">Nome</th>
                <th scope="col" style="width: 20%">Entrada/Saída</th>
                <th scope="col" style="width: 20%">Quantidade</th>
                <th scope="col" style="width: 40%">Data/Hora de criação</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Entrada/Saída">{{ stringType(data.type) }}</td>
                <td data-label="Quantidade">{{ data.amount }}</td>
                <td data-label="Data/Hora de criação">{{ data.date_create }} / {{ data.hour_create }}</td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object.itens" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "InventorySearch",
    components: {
        ButtonPagination,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    created() {
        this.$store.dispatch("loadLocalList")
        this.$store.dispatch("loadProductList")
        this.$store.dispatch("changeNamePage", "Entrada/Saída")
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/user/create"
            },

            buttonCheck: {
                entrance: false,
                exit: false,
            },

            MONEY,

            formData: {
                code: "",
                name: "",
                type: ""
            },

            entrance: {
                show: false,
                formData: {
                    product_id: "",
                    price: "",
                    amount: "",
                    initial_inventory: "",
                    local_id: "",
                    sector: "",
                    column: "",
                    shelf: ""
                }
            },

            exit: {
                show: false,
                formData: {
                    product_id: "",
                    amount: "",
                    product_batch_id: ""
                }
            },

            data: {},

            errors: [],
            sendForm: false,
            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadInventories", {...this.params, page})
                    .then((r) => {
                        this.data = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        submitEntrance() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeInventoryAdd', this.entrance.formData)
                    .then(() => {
                        this.sendForm = false
                        this.errors = []
                        this.entranceClose()
                        this.loadItems(1)
                        toast.success("Entrada realizada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitExit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeInventoryRemove', this.exit.formData)
                    .then(() => {
                        this.sendForm = false
                        this.errors = []
                        this.exitClose()
                        this.loadItems(1)
                        toast.success("Saída realizada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 33)
                .then((r) => {
                    if (r.data.indexOf('product-entrance-and-exit-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('product-entrance-and-exit-create') !== -1) {
                            this.buttonCheck.entrance = true
                            this.buttonCheck.exit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        entranceClose() {
            this.errors = []
            this.entrance.show = false
            this.entrance.formData = {
                product_id: "",
                price: "",
                amount: "",
                initial_inventory: "",
                local_id: "",
                sector: "",
                column: "",
                shelf: ""
            }
        },

        exitClose() {
            this.errors = []
            this.exit.show = false
            this.exit.formData = {
                product_id: "",
                amount: "",
                product_batch_id: ""
            }
        },

        selectBatch(id) {
            this.$store.dispatch("loadProductBatchList", id)
        },

        stringType(value) {
            if (parseInt(value) === 1)
                return "Entrada"
            return "Saída"
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },

        selectProductList() {
            return this.$store.state.var.selectProductList
        },

        selectLocalList() {
            return this.$store.state.var.selectLocalList
        },

        selectSector() {
            return this.$store.state.var.selectSector
        },

        selectProductBatchList() {
            return this.$store.state.var.selectProductBatchList
        },

        object() {
            return this.$store.state.inventory.items
        },

        params() {
            return {
                page: this.object.current_page,
                code: this.formData.code,
                name: this.formData.name,
                type: this.formData.type
            }
        }
    }
}
</script>

<style scoped>
.modal-w {
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 70%;
    height: auto;
    background-color: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 991px) {
    .modal-p {
        width: 95%;
        overflow: scroll;
        max-height: 850px;
    }
}
</style>