<template>
    <button type="button" class="btn btn-danger button-delete">
        <i class="trash icon"></i>
    </button>
</template>

<script>
export default {
    name: "ButtonDelete",
}
</script>

<style scoped>
.button-delete {
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: 15px;
    border-radius: 0;
}

.button-delete i {
    margin: 0 auto;
}
</style>