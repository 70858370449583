<template>
    <div class="modal-window" v-if="modal_hours">

        <painel-component class="shadow-none modal-hours">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input type="radio" id="hour" v-model="openingHours.date_close_check" value="1">
                    <label for="hour">Horário</label>

                    <input type="radio" id="holiday" v-model="openingHours.date_close_check" value="2">
                    <label for="holiday">Desligar Feriado</label>
                </row-col-component>
            </row-component>

            <div v-if="openingHours.date_close_check === '1'">
                <row-component>
                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Nome:</label>
                        <input-component v-model="openingHours.name" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Horário:</label>
                        <input-component v-model="openingHours.hour" type-input="time"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Ligar / Desligar:</label>
                        <Multiselect v-model="openingHours.type" :options="selectOnOff" :searchable="true"
                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                        <label>Dias da Semana:</label>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="1">
                            <label class="form-check-label">Segunda-Feira</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="2">
                            <label class="form-check-label">Terça-Feira</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="3">
                            <label class="form-check-label">Quarta-Feira</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="4">
                            <label class="form-check-label">Quinta-Feira</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="5">
                            <label class="form-check-label">Sexta-Feira</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="6">
                            <label class="form-check-label">Sábado</label>
                        </div>

                        <div class="form-check form-check-inline">
                            <input v-model="openingHours.days_week" class="form-check-input" type="checkbox" value="7">
                            <label class="form-check-label">Domingo</label>
                        </div>
                    </row-col-component>
                </row-component>
            </div>

            <div v-else>
                <row-component>
                    <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                        <label>Nome:</label>
                        <input-component v-model="openingHours.name" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Data:</label>
                        <input-component v-model="openingHours.date_close" v-mask="'##/##/####'" type-input="text"/>
                    </row-col-component>
                </row-component>
            </div>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Player:</label>
                    <div v-if="listPlayers.length > 0">
                        <div class="form-check" v-for="(data, index) in listPlayers" :key="index">
                            <input v-model="openingHours.players" class="form-check-input" type="checkbox"
                                   :value="data.value">
                            <label class="form-check-label">{{ data.label }}</label>
                        </div>
                    </div>
                    <p class="mt-2" v-else>Nenhum player cadastrado nessa localização</p>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="isEdit" @click="submitEdit" class="w-100" icon="save" name="Salvar"/>
                    <button-submit v-else @click="submitAdd" class="w-100" icon="plus" name="Adicionar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="closeHour" class="w-100" icon="close" name="Fechar"/>
                </row-col-component>
            </row-component>
        </painel-component>
    </div>

    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix mb-3" v-if="buttonCheck.create">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="cog icon"></i>
                <span>Equipamento</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="clock icon"></i>
                <span>Horário de funcionamento</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="wifi icon"></i>
                <span>Senhas WIFI</span>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3">
            <span>
                <i class="users icon"></i>
                <span>Responsáveis</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form v-if="showLoader">
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.company_id}">
                                <label>Empresa:</label>
                                <Multiselect label="name" v-model="formData.company_id" :searchable="true"
                                             :options="selectCompanyList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.state}">
                                <label>Estado:</label>
                                <Multiselect v-model="formData.state" :searchable="true"
                                             @select="loadCityList(formData.state)"
                                             :options="selectStateList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.city}">
                                <label>Cidade:</label>
                                <Multiselect v-model="formData.city" :searchable="true"
                                             :options="selectCityList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.cep}">
                                <label>CEP:</label>
                                <input-component v-mask="'#####-###'" @change="searchAPICEP" v-model="formData.cep"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.district}">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.address}">
                                <label>Endereço:</label>
                                <input-component v-model="formData.address" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.complement}">
                                <label>Referência:</label>
                                <text-area rows="5" v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <button-submit class="mb-3" @click="addEquipment" icon="plus"/>

                    <template v-for="(data, index) in equipmentAdd" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <div class="clearfix" v-if="equipmentAdd.length > 1">
                                    <button-delete @click="removeEquipment(index)" class="float-end"/>
                                </div>
                            </row-component>
                        </div>
                    </template>
                </section>

                <section id="tab-item-3">
                    <button-submit class="mb-3" icon="plus" @click="closeHour"/>

                    <row-component v-if="formData.openingHours.length === 0">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                            <div class="alert alert-secondary" role="alert">
                                <i class="clock icon"></i>
                                Adicione um novo horário
                            </div>
                        </row-col-component>
                    </row-component>

                    <template v-for="(data, index) in formData.openingHours" :key="index">
                        <div class="bg-body-secondary p-3 mb-3">
                            <div class="clearfix">
                                <div class="float-end">
                                    <button-submit icon="edit" @click="editHour(data)"/>
                                    <button-delete @click="destroy(data)"/>
                                </div>
                            </div>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <input disabled type="radio" id="hour" v-model="data.date_close_check" value="1">
                                    <label for="hour">Horário</label>

                                    <input disabled type="radio" id="holiday" v-model="data.date_close_check" value="2">
                                    <label for="holiday">Desligar Feriado</label>
                                </row-col-component>
                            </row-component>

                            <div v-if="data.date_close_check === '1'">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Nome:</label>
                                        <input-component disabled v-model="data.name" type-input="text"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Horário:</label>
                                        <input-component disabled v-model="data.hour" type-input="time"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Ligar / Desligar:</label>
                                        <Multiselect disabled v-model="data.type" :options="selectOnOff"
                                                     :searchable="true"
                                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Dias da Semana:</label>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="1">
                                            <label class="form-check-label">Segunda-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="2">
                                            <label class="form-check-label">Terça-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="3">
                                            <label class="form-check-label">Quarta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="4">
                                            <label class="form-check-label">Quinta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="5">
                                            <label class="form-check-label">Sexta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="6">
                                            <label class="form-check-label">Sábado</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="7">
                                            <label class="form-check-label">Domingo</label>
                                        </div>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <div v-else>
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <label>Nome:</label>
                                        <input-component disabled v-model="data.name" type-input="text"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Data:</label>
                                        <input-component disabled v-model="data.date_close" v-mask="'##/##/####'"
                                                         type-input="text"/>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Player:</label>
                                    <div v-if="data.players.length > 0">
                                        <div class="form-check" v-for="(dataTwo, indexTwo) in listPlayers"
                                             :key="indexTwo">
                                            <input disabled v-model="data.players" class="form-check-input" type="checkbox"
                                                   :value="dataTwo.value">
                                            <label class="form-check-label">{{ dataTwo.label }}</label>
                                        </div>
                                    </div>
                                    <p class="mt-2" v-else>Nenhum player cadastrado nessa localização</p>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>

                <section id="tab-item-4">
                    <button-submit class="mb-3" @click="addWifi" icon="plus"/>

                    <template v-for="(data, index) in wifiAdd" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome Rede:</label>
                                    <input-component v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Senha:</label>
                                    <input-component v-model="data.password" type-input="text"/>
                                </row-col-component>
                            </row-component>

                            <div class="clearfix" v-if="wifiAdd.length > 1">
                                <button-delete @click="removeWifi(index)" class="float-end"/>
                            </div>
                        </div>
                    </template>
                </section>

                <section id="tab-item-5">
                    <button-submit class="mb-3" @click="addResponsible" icon="plus"/>

                    <template v-for="(data, index) in responsibleAdd" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Função</label>
                                    <input-component v-model="data.function" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component v-model="data.email" type-input="email"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-mask="['(##) ####-####', '(##) #####-####']" v-model="data.phone"
                                                     type-input="text"/>
                                </row-col-component>
                            </row-component>

                            <div class="clearfix" v-if="responsibleAdd.length > 1">
                                <button-delete @click="removeResponsible(index)" class="float-end"/>
                            </div>
                        </div>
                    </template>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit @click="submit" :disabled="sendForm" class="w-100" icon="save"
                                       name="Salvar"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import ErrorComponent from "@/components/alerts/ErrorComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import {useToast} from "vue-toastification";
import {mask} from "vue-the-mask";
import swal from "sweetalert";

const toast = useToast()

export default {
    name: "SpotUpdate",
    components: {
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent,
        ErrorComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Local")
        this.checkACL()
        this.loadStorage()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/spot/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/spot/create"
            },

            buttonCheck: {
                create: false
            },

            formData: {
                client_id: "",
                company_id: "",
                name: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                status: "",
                equipments: [],
                passwordswifi: [],
                responsibles: [],
                openingHours: []
            },

            params: {
                id: this.id,
                client_id: ""
            },

            listPlayers: [],
            isEdit: false,
            modal_hours: false,
            openingHours: {
                client_id: "",
                spot_id: this.id,
                name: "",
                days_week: [],
                type: "1",
                date_close_check: "1",
                date_close: "",
                players: [],
                hour: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateSpot', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Local atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.params.client_id = this.idClient
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.$store.dispatch("loadStateList")
            this.$store.dispatch("loadSpotPlayers", this.params)
                .then((r) => {
                    this.listPlayers = r
                })
        },

        loadCityList(id) {
            this.$store.dispatch("loadCityList", id)
            if (this.formData.city)
                this.formData.city = ""
        },

        loadItems() {
            this.$store.dispatch('loadSpot', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                    this.$store.dispatch("loadCityList", r.state)
                    this.nullInput()
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 20)
                .then((r) => {
                    if (r.data.indexOf('spot-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('spot-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchAPICEP() {
            let cep = this.formData.cep.replace(/\D/g, '');
            const params = {'cep': cep}
            this.$store.dispatch('searchApiCEP', params).then((r) => {
                this.formData.district = r.data.bairro
                this.formData.address = r.data.logradouro
                this.formData.complement = r.data.complemento
            })
        },

        submitAdd() {
            this.openingHours.client_id = this.idClient
            this.$store.dispatch('storeSpotHour', this.openingHours)
                .then(() => {
                    this.loadItems()
                    this.closeHour()
                    toast.success("Horário adicionada com sucesso", {timeout: 1200});
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitEdit() {
            this.openingHours.client_id = this.idClient
            this.openingHours.spot_id = this.id
            this.$store.dispatch('updateSpotHour', this.openingHours)
                .then(() => {
                    this.loadItems()
                    this.closeHour()
                    toast.success("Horário atualizado com sucesso", {timeout: 1200});
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: this.id,
                        idhour: item.id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroySpotHour', params).then(() => {
                        this.loadItems()
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        closeHour() {
            this.isEdit = false
            this.modal_hours = !this.modal_hours

            this.openingHours = {
                client_id: this.idClient,
                spot_id: this.id,
                name: "",
                days_week: [],
                type: "1",
                date_close_check: "1",
                date_close: "",
                players: [],
                hour: ""
            }
        },

        editHour(data) {
            this.isEdit = true
            this.openingHours = data
            this.openingHours.days_week = data.days_weeks
            this.modal_hours = !this.modal_hours
        },

        nullInput() {
            if (this.formData.equipments.length === 0)
                this.addEquipment()
            if (this.formData.passwordswifi.length === 0)
                this.addWifi()
            if (this.formData.responsibles.length === 0)
                this.addResponsible()
        },

        addEquipment() {
            this.formData.equipments.push({
                name: ""
            })
        },

        removeEquipment(index) {
            this.formData.equipments.splice(index, 1)
        },

        addWifi() {
            this.formData.passwordswifi.push({
                name: "",
                password: ""
            })
        },

        removeWifi(index) {
            this.formData.passwordswifi.splice(index, 1)
        },

        addResponsible() {
            this.formData.responsibles.push({
                name: "",
                function: "",
                email: "",
                phone: ""
            })
        },

        removeResponsible(index) {
            this.formData.responsibles.splice(index, 1)
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectOnOff() {
            return this.$store.state.var.selectOnOff
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectStateList() {
            return this.$store.state.var.selectStateList
        },

        selectCityList() {
            return this.$store.state.var.selectCityList
        },

        equipmentAdd() {
            return this.formData.equipments
        },

        wifiAdd() {
            return this.formData.passwordswifi
        },

        responsibleAdd() {
            return this.formData.responsibles
        }
    }
}
</script>

<style scoped>
.modal-window {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-window .modal-hours {
    width: 60%;
    height: auto;
    position: relative;
}

.form-check-inline {
    display: inline-block;
}

@media (max-width: 1440px) {
    .modal-window .modal-hours {
        width: 75%;
    }
}

@media (max-width: 991px) {
    .modal-window .modal-hours {
        width: 75%;
    }
}

@media (max-width: 430px) {
    .modal-window .modal-hours {
        width: 95%;
    }
}

@media (max-width: 375px) {
    .modal-window .modal-hours {
        width: 95%;
        overflow-y: scroll;
    }
}
</style>