<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
                               v-if="log">
                <label>Ativar Logs?</label>

                <input type="radio" id="docs-no" v-model="formData.log" value="1" checked>
                <label for="docs-no">Não</label>

                <input type="radio" id="docs-yes" v-model="formData.log" value="2">
                <label for="docs-yes">Sim</label>
            </row-col-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.token}">
                    <label>Token:</label>
                    <input-component v-model="formData.token" class="text-uppercase" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.type_midia_id}">
                    <label>Tipo de Mídia:</label>
                    <Multiselect v-model="formData.type_midia_id" :options="selectTypeMediaPlayerList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.force_orientation}">
                    <label>Forçar Orientação:</label>
                    <Multiselect v-model="formData.force_orientation" :options="selectYesNo" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.segmento_id}">
                    <label>Segmento:</label>
                    <Multiselect v-model="formData.segmento_id" :options="selectSegmentPlayerList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.spot_id}">
                    <label>Localização:</label>
                    <Multiselect label="name" v-model="formData.spot_id" :options="selectSpotList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.orientation}">
                    <label>Resolução em Pixel:</label>
                    <Multiselect label="name" v-model="formData.orientation" :options="selectResolutionList"
                                 @select="selectResolution(formData.orientation)"
                                 @clear="selectResolution(formData.orientation)"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.width}">
                    <label>Largura(Pixel):</label>
                    <input-component v-model="formData.width" type-input="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.heigth}">
                    <label>Altura(Pixel):</label>
                    <input-component v-model="formData.heigth" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Adicionar"
                                   icon="plus circle"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "PlayerCreate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        ErrorComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Player")
        this.checkACL()
        this.loadStorage()
    },

    data() {
        return {
            itemsOne: {
                title: "Adicionar",
                icon: "pen square"
            },

            itemsTwo: {
                name: "Voltar",
                icon: "reply all",
                route: "/player/search"
            },

            formData: {
                client_id: "",
                token: "",
                name: "",
                type_midia_id: "",
                segmento_id: "",
                force_orientation: "",
                spot_id: "",
                width: "",
                heigth: "",
                log: "1",
                orientation: ""
            },

            log: "",

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storePlayer', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Player criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'player-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadTypeMediaPlayerList", this.idClient)
            this.$store.dispatch("loadResolutionList", this.idClient)
            this.$store.dispatch("loadSegmentList", this.idClient)
            this.$store.dispatch("loadSpotList", this.idClient)
            this.formData.client_id = this.idClient
            this.loadPlayerLog()
        },

        loadPlayerLog() {
            this.$store.dispatch("loadPlayerLog", this.idClient)
                .then(r => this.log = r.show_log)
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 24)
                .then((r) => {
                    if (r.data.indexOf('player-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        selectResolution(value) {
            switch (value) {
                case 1:
                    this.formData.width = '1280'
                    this.formData.heigth = '720'
                    break
                case 2:
                    this.formData.width = '1920'
                    this.formData.heigth = '1080'
                    break
                case 3:
                    this.formData.width = ''
                    this.formData.heigth = ''
                    break
                case 4:
                    this.formData.width = '720'
                    this.formData.heigth = '1280'
                    break
                case 5:
                    this.formData.width = '1080'
                    this.formData.heigth = '1920'
                    break
                case 6:
                    this.formData.width = ''
                    this.formData.heigth = ''
                    break
                case 7:
                    this.formData.width = '720'
                    this.formData.heigth = '1280'
                    break
                case 8:
                    this.formData.width = '1080'
                    this.formData.heigth = '1920'
                    break
                case 9:
                    this.formData.width = ''
                    this.formData.heigth = ''
                    break
                default:
                    this.formData.width = ''
                    this.formData.heigth = ''
                    break
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectTypeMediaPlayerList() {
            return this.$store.state.var.selectTypeMediaPlayerList
        },

        selectSegmentPlayerList() {
            return this.$store.state.var.selectSegmentPlayerList
        },

        selectSpotList() {
            return this.$store.state.var.selectSpotList
        },

        selectResolutionList() {
            return this.$store.state.var.selectResolutionList
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },
    }
}


</script>