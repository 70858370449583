import axios from "axios"

export default {
    actions: {
        searchApiCNPJ(context, formData) {
            return new Promise((resolve, reject) => {
                const config = {
                    "headers": {"Authorization": "f5118c39-2416-40f4-9178-5ade11c9572f-c42c1f0c-07f9-4c20-b0e3-25f5eda772b3"},
                }
                axios.get(`https://api.cnpja.com/office/${formData.cnpj}`, config)
                    .then((r) => {
                        resolve(r)
                    }).catch(errors => {
                    reject(errors)
                })
            })
        },

        searchApiCEP(context, formData) {
            context.commit('LOADER', true)
            return new Promise((resolve, reject) => {
                axios.get(`https://viacep.com.br/ws/${formData.cep}/json/`)
                    .then((r) => {
                        resolve(r)
                    }).catch(errors => {
                    reject(errors)
                    context.commit('LOADER', false)
                }).finally(() => context.commit('LOADER', false))
            })
        }
    }
}





