import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'entrance-and-exit';

export default {

    loadEntranceExits(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_ENTRANCE_EXIT", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeEntranceExit(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadEntranceExit(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },
}