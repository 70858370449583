<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                </div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Saldo:</label>
                    <input-component v-money="MONEY" disabled v-model="formData.balance" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Banco:</label>
                    <Multiselect disabled v-model="formData.bank" :options="selectBank" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Tipo de conta:</label>
                    <Multiselect disabled v-model="formData.account_type" :options="selectAccountType"
                                 :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Agência:</label>
                    <input-component disabled v-model="formData.agency" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Conta/Dígito:</label>
                    <input-component disabled v-model="formData.digit_account" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>PIX Tipo:</label>
                    <Multiselect disabled v-model="formData.pix_type" :options="selectPIX" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Chave:</label>
                    <input-component disabled :type-input="'text'" v-model="formData.key_pix"
                                     v-if="parseInt(this.formData.pix_type) === 1"
                                     v-mask="['###.###.###-##', '##.###.###/####-##']"/>

                    <input-component disabled type-input="text" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 2"
                                     v-mask="['(##) ####-####', '(##) #####-####']"/>

                    <input-component disabled type-input="email" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 3"/>

                    <input-component disabled type-input="text" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 4"/>

                    <input-component disabled type-input="text" v-model="formData.key_pix" v-else/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Observações:</label>
                    <text-area disabled v-model="formData.obs" :rows="5"/>
                </row-col-component>
            </row-component>

            <row-component class="mb-4">
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Conta Principal ?:</label>

                    <input disabled type="radio" id="main_account_n" v-model="formData.main_account" value="1">
                    <label for="main_account_n">Não</label>

                    <input disabled type="radio" id="main_account_s" v-model="formData.main_account" value="2">
                    <label for="main_account_s">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Permitir Saldo Negativo ?:</label>

                    <input type="radio" id="allow_negative_balance_n" v-model="formData.allow_negative_balance"
                           disabled value="1">
                    <label for="allow_negative_balance_n">Não</label>

                    <input type="radio" id="allow_negative_balance_s" v-model="formData.allow_negative_balance"
                           disabled value="2">
                    <label for="allow_negative_balance_s">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import {useToast} from "vue-toastification";
import {MONEY} from "@/configs/constants";

const toast = useToast()
export default {
    name: "BankAccountShow",
    components: {
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Conta Bancária")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/bank-account/search"
            },

            MONEY,

            formData: {
                name: "",
                bank: "",
                balance: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "",
                key_pix: "",
                allow_negative_balance: "",
                main_account: "",
                status: "",
                obs: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadBankAccount', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 27)
                .then((r) => {
                    if (r.data.indexOf('account-bank-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBank() {
            return this.$store.state.var.selectBank
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType
        },

        selectPIX() {
            return this.$store.state.var.selectPIX
        },
    }
}
</script>