import MainComponent from "@/components/dashboard/MainComponent";

import TutorialView from "@/views/search/TutorialView";

export default {
    path: "/tutorial",
    meta: {auth: true},
    component: MainComponent,
    children:[
        {path: "search", name: "tutorial-search", component: TutorialView}
    ]
}