import axios from "axios";
import {URL_BASE_SYSTEM} from "@/configs/constants";

const RESOURCE = "player"

export default {

    loadPlayers(context, params) {
        context.commit("LOADER", false)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_PLAYERS", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadPlayerLog(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-show-log?client_id=${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    storePlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadPlayer(context, params) {
        context.commit("LOADER", false)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}/${params.id}?client_id=${params.client_id}`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updatePlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyPlayer(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_SYSTEM}${RESOURCE}/${params.id}?client_id=${params.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    hoursPlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-opening-hours/${formData.id}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    synchronizePlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-synchronize/${formData.id}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    restartApp(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-restart-app/${formData.id}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    resetPlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-delete-all/${formData.id}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    restartPlayer(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-delete-all/${formData.id}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    onOffPlayer(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-on-off-device`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadPlayerInfo(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-info/${formData.id}?client_id=${formData.client_id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadPlayerCampaigns(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-add-campaing`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storePlayerCampaign(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}player-add-campaing`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyPlayerCampaigns(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_SYSTEM}player-add-campaing`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    }
}