<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Email (Login):</label>
                    <input-component disabled v-model="formData.email" type-input="email"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mt-4 pt-2">
                    <label>Sexo:</label>
                    <input disabled type="radio" id="feminino" v-model="formData.sexo" value="1">
                    <label for="feminino">Feminino</label>

                    <input disabled type="radio" id="masculino" v-model="formData.sexo" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Grupo:</label>
                    <Multiselect disabled v-model="formData.group_id" :searchable="true"
                                 :options="selectGroup" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <div v-if="disabledCompany" class="alert alert-secondary icon-message" role="alert">
                    Nenhum cliente selecionado
                </div>

                <div v-else-if="selectCompany.length === 0" class="alert alert-secondary icon-message" role="alert">
                    Não existe empresas cadastradas no cliente selecionado
                </div>

                <div v-else>
                    <div class="form-check check-box-header">
                        <input disabled class="form-check-input " type="checkbox" id="checkBoxMaster">
                        <label class="form-check-label">Empresas</label>
                    </div>

                    <div class="form-check" v-for="(company, index) in selectCompany" :key="index">
                        <input disabled type="checkbox" :value="company.value"
                               :id="'checkOne' + company.value" :class="'form-check-input checkBoxAll'">
                        <label class="form-check-label">{{ company.name }}</label>
                    </div>
                </div>
            </row-col-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute";

const toast = useToast()
export default {
    name: "UserSystemShow",
    components: {
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadReleasesAccessList")
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user-system/search"
            },

            formData: {
                id_tenant: "",
                name: "",
                sexo: "",
                status: "",
                email: "",
                group_id: "",
                companies: []
            },

            showLoader: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUserSystem', {...this.params})
                .then((r) => {
                    this.formData = r;
                    this.loadAllList(r.id_tenant)
                    setTimeout(() => {
                        this.checkStart()
                    }, 1000)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 10)
                .then((r) => {
                    if (r.data.indexOf('user-system-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            // this.formData.tenant_id = this.idClient
        },

        loadAllList(id) {
            if (id) {
                this.$store.dispatch('loadUserSystemGroup', id)
                    .then(() => {
                        this.selectGroup.push({"label": "Master", "value": 1})
                    })
                this.$store.dispatch('loadUserSystemCompany', id)
            } else {
                this.disabledGroup = true
                this.disabledCompany = true
                this.formData = {}
            }
        },

        checkStart() {
            let totalPage = this.selectCompany.length;
            let cont = 0;
            let i = 0;
            let companiesList = this.formData.companies;
            this.selectCompany.forEach(function (element) {
                if (companiesList.includes(element.value)) {
                    document.getElementById("checkOne" + element.value).checked = true
                    cont = cont + 1;
                }
                if (cont >= totalPage) {
                    document.getElementById("checkBoxMaster").checked = true
                }
                i = i + 1;
            });
        },
    },

    beforeMount() {
        this.selectGroup.push({"label": "Master", "value": 1})
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClient() {
            return this.$store.state.release_access.list
        },

        selectGroup() {
            return this.$store.state.user_system.list_group
        },

        selectCompany() {
            return this.$store.state.user_system.list_companies
        },

        params() {
            return {
                id: this.id,
                client_id: this.idClient
            }
        }
    }
}
</script>