import axios from "axios";
import {URL_BASE_SYSTEM} from "@/configs/constants";

const RESOURCE = 'home';

export default {

    actions: {
        loadHome(context) {
            context.commit("LOADER", true)
            return new Promise((resolve, reject) => {
                axios.get(`${URL_BASE_SYSTEM}${RESOURCE}`)
                    .then((response) => {
                        return resolve(response.data)
                    }).catch((errors) => {
                    return reject(errors)
                }).finally(() => {
                    context.commit("LOADER", false)
                })
            })
        }
    }

}