<template>
    <painel-component :items="itemsOne">

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }" checked>
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="user circle icon"></i>
                <span>Sócio</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="envelope icon"></i>
                <span>Email</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
            <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="phone square icon"></i>
                <span>Telefone</span>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
            <span v-if="picked === 2">
                <i class="tasks icon"></i>
                <span>Atividades econômicas secundárias</span>
            </span>

            <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component v-if="picked === 2">
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>CNPJ:</label>
                                <input-component disabled type-input="text" v-model="formData.cnpj"
                                                 v-mask="'##.###.###/####-##'"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Porte:</label>
                                <input-component disabled type-input="text" v-model="formData.size"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect disabled :searchable="true" :options="selectActiveInative"
                                             v-model="formData.status"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component disabled type-input="text" v-model="formData.name"/>
                            </row-col-component>
                        </row-component>

                        <row-component v-if="picked === 2">
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Nome Fantasia:</label>
                                <input-component disabled type-input="text" v-model="formData.name_f"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.surname}">
                                <label>Apelido:</label>
                                <input-component disabled type-input="text" v-model="formData.surname"/>
                            </row-col-component>
                        </row-component>

                        <row-component v-if="picked === 1">
                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.cpf}">
                                <label>CPF:</label>
                                <input-component disabled v-mask="'###.###.###-##'" type-input="text"
                                                 v-model="formData.cpf"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>RG:</label>
                                <input-component v-mask="['##.###.###-X','#.###.###']" type-input="text" disabled
                                                 v-model="formData.rg"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Data de Nascimento:</label>
                                <input-component disabled type-input="text" v-model="formData.birth_date"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect disabled :searchable="true" :options="selectActiveInative"
                                             v-model="formData.status" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <div v-if="picked === 2">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Natureza Jurídica:</label>
                                    <input-component disabled type-input="text" v-model="formData.legal_nature"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Status da Empresa:</label>
                                    <input-component disabled type-input="text" v-model="formData.situation"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Capital Social:</label>
                                    <input-component disabled v-money="money" type-input="text"
                                                     v-model="formData.share_capital"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Atividade Econômica Principal:</label>
                                    <input-component disabled type-input="text" v-model="formData.main_activity"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Data de Abertura:</label>
                                    <input-component v-mask="'##/##/####'" type-input="text" disabled
                                                     v-model="formData.date_open"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Matriz:</label>
                                    <input-component disabled type-input="text" v-model="formData.headquarters"/>
                                </row-col-component>
                            </row-component>
                        </div>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component disabled v-mask="'#####-###'" type-input="text"
                                                 v-model="formData.cep"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <input-component disabled type-input="text" v-model="formData.city"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <input-component disabled type-input="text" v-model="formData.state"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component disabled type-input="text" v-model="formData.district"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <label>Endereço:</label>
                                <input-component disabled type-input="text" v-model="formData.address"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>País:</label>
                                <input-component disabled type-input="text" v-model="formData.country"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Refêrencia:</label>
                                <text-area rows="5" disabled v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.members.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-else>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in formData.members" :key="index">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>CPF / CNPJ:</label>
                                    <input-component disabled v-model="data.cpf_cnpj" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Idade:</label>
                                    <input-component disabled v-model="data.age" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Qualificação:</label>
                                    <input-component disabled v-model="data.qualification" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.emails.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-else>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in formData.emails" :key="index">
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Receber notificação?</label>

                                <input disabled type="radio" :id="'no-email' + index" v-model="data.notification"
                                       value="1">
                                <label :for="'no-email' + index">Não</label>

                                <input disabled type="radio" :id="'yes-email'+ index" v-model="data.notification"
                                       value="2">
                                <label :for="'yes-email'+ index">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component disabled v-model="data.email" type-input="email"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.phones.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-else>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in formData.phones" :key="index">
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Receber notificação?</label>

                                <input disabled type="radio" :id="'no-phone' + index" v-model="data.notification"
                                       value="1">
                                <label :for="'no-phone' + index">Não</label>

                                <input disabled type="radio" :id="'yes-phone'+ index" v-model="data.notification"
                                       value="2">
                                <label :for="'yes-phone'+ index">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component disabled v-mask="['(##) ####-####','(##) #####-####']"
                                                     v-model="data.phone" type-input="email"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </form>
                </section>

                <section id="tab-item-5">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.secondary_activitys.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <form v-else>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in formData.secondary_activitys" :key="index">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Código:</label>
                                    <input-component disabled v-model="data.code" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Descrição:</label>
                                    <input-component disabled v-model="data.desc" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </form>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {mask} from "vue-the-mask";
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ClientShow",
    components: {
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Cliente")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/client/search"
            },

            formData: {
                cpf: "",
                cnpj: "",
                size: "",
                name: "",
                name_f: "",
                surname: "",
                legal_nature: "",
                share_capital: "",
                federative_entity: "",
                main_activity: "",
                date_open: "",
                birth_date: "",
                headquarters: "",
                date_situation: "",
                situation: "",
                reason_situation: "",
                special_date: "",
                special_situation: "",
                members: [],
                emails: [],
                phones: [],
                secondary_activitys: [],
                rg: "",
                type: "",
                status: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: "",
            },

            picked: 2,
            money: MONEY,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadClient', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 1)
                .then((r) => {
                    if (r.data.indexOf('client-search') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('client-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        tabMobile() {
            return parseInt(this.picked) !== 2;
        },
    }
}
</script>