<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-3" v-if="create">
            <button-route :items="itemsCreate" class="float-end"/>
        </div>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="envelope icon"></i>
                <span>Email</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="phone square icon"></i>
                <span>Telefone</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="money icon"></i>
                <span>Conta Bancária</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <div class="aux mb-4 mt-3">
                            <div class="avatar-form">
                                <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                                <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                            </div>
                            <p>{{ formData.name }}</p>
                            <router-link :to="{ name: 'employee-photo', id: this.id }" class="figure">
                                <i class="camera icon"></i>
                            </router-link>
                        </div>

                        <row-col-component
                                :class-row-col="'col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12'">
                            <input type="radio" id="docs-employee" v-model="formData.type" value="1" checked>
                            <label for="docs-employee">Funcionário</label>

                            <input type="radio" id="docs-service-provider" v-model="formData.type" value="2">
                            <label for="docs-service-provider">Prestador de serviço</label>
                        </row-col-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-8 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.sexo}">
                                <label>Sexo:</label>
                                <Multiselect v-model="formData.sexo" :searchable="true" :options="selectSexo"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.cpf}">
                                <label>CPF:</label>
                                <input-component v-model="formData.cpf" v-mask="'###.###.###-##'" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>RG:</label>
                                <input-component v-model="formData.rg" v-mask="['##.###.###-X','#.###.###']"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Data Nascimento:</label>
                                <input-component v-model="formData.birth_date" v-mask="'##/##/####'" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component v-model="formData.cep" @change="searchAPICEP" v-mask="'#####-###'"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <input-component v-model="formData.city" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <input-component v-model="formData.state" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component v-model="formData.district" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Refêrencia:</label>
                                <text-area v-model="formData.complement" rows="4"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="clearfix  mb-3">
                        <button-submit @click="addEmails" icon="plus" class="rounded-0 float-end"/>
                    </div>

                    <form>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in emailsAdd" :key="index">
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Receber notificação?</label>

                                <input type="radio" :id="'no-email' + index" v-model="data.notification" value="1">
                                <label :for="'no-email' + index">Não</label>

                                <input type="radio" :id="'yes-email'+ index" v-model="data.notification" value="2">
                                <label :for="'yes-email'+ index">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component type-input="text" v-model="data.name"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component type-input="email" v-model="data.email"/>
                                </row-col-component>
                            </row-component>

                            <div class="clearfix" v-if="emailsAdd.length > 1">
                                <button-delete class="rounded-0 float-end" @click="removeEmail(index)"/>
                            </div>
                        </div>
                    </form>
                </section>

                <section id="tab-item-3">
                    <div class="clearfix  mb-3">
                        <button-submit @click="addPhones" icon="plus" class="rounded-0 float-end"/>
                    </div>

                    <form>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in phonesAdd" :key="index">
                            <row-col-component
                                    :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                                <label>Receber notificação?</label>

                                <input type="radio" :id="'no-phone' + index" v-model="data.notification" value="1">
                                <label :for="'no-phone' + index">Não</label>

                                <input type="radio" :id="'yes-phone'+ index" v-model="data.notification" value="2">
                                <label :for="'yes-phone'+ index">Sim</label>
                            </row-col-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component type-input="text" v-model="data.name"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component v-mask="['(##) ####-####','(##) #####-####']" type-input="text"
                                                     v-model="data.phone"/>
                                </row-col-component>
                            </row-component>

                            <div class="clearfix" v-if="phonesAdd.length > 1">
                                <button-delete class="rounded-0 float-end" @click="removePhone(index)"/>
                            </div>
                        </div>
                    </form>
                </section>

                <section id="tab-item-4">
                    <div class="clearfix  mb-3">
                        <button-submit @click="addBankAccounts" icon="plus" class="rounded-0 float-end"/>
                    </div>

                    <form>
                        <div class="bg-body-secondary p-3 mb-3"
                             v-for="(data, index) in bankAccountsAdd" :key="index">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Banco:</label>
                                    <Multiselect v-model="data.bank" :searchable="true" :options="selectBank"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Tipo de Conta:</label>
                                    <Multiselect v-model="data.account_type" :searchable="true"
                                                 :options="selectAccountType" :noOptionsText="noOptionsText"
                                                 :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Agência:</label>
                                    <input-component type-input="text" v-model="data.agency"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Conta/Dígito:</label>
                                    <input-component type-input="text" v-model="data.digit_account"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-2 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Tipo de pix:</label>
                                    <Multiselect v-model="data.pix_type" :searchable="true" :options="selectPIX"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12'">
                                    <label>Chave:</label>
                                    <input-component :type-input="'text'" v-model="data.key_pix"
                                                     v-mask="['###.###.###-##', '##.###.###/####-##']"
                                                     v-if="parseInt(this.formData.bank_accounts[index].pix_type)  === 1"/>

                                    <input-component :type-input="'text'" v-model="data.key_pix"
                                                     v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-else-if="parseInt(this.formData.bank_accounts[index].pix_type) === 2"/>

                                    <input-component :type-input="'email'" v-model="data.key_pix"
                                                     v-else-if="parseInt(this.formData.bank_accounts[index].pix_type) === 3"/>

                                    <input-component :type-input="'text'" v-model="data.key_pix"
                                                     v-else-if="parseInt(this.formData.bank_accounts[index].pix_type) === 4"/>

                                    <input-component :type-input="'text'" v-model="data.key_pix" v-else/>
                                </row-col-component>

                                <row-col-component
                                        :class-row-col="'col-xxl-3 col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 mt-4 pt-2'">
                                    <label>Conta Principal ?</label>

                                    <input type="radio" :id="'no-account-main' + index" v-model="data.main_account"
                                           value="1">
                                    <label :for="'no-account-main' + index">Não</label>

                                    <input type="radio" :id="'yes-account-main'+ index" v-model="data.main_account"
                                           value="2">
                                    <label :for="'yes-account-main'+ index">Sim</label>
                                </row-col-component>
                            </row-component>

                            <div class="clearfix" v-if="bankAccountsAdd.length > 1">
                                <button-delete class="rounded-0 float-end" @click="removeBankAccount(index)"/>
                            </div>
                        </div>
                    </form>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Salvar"
                                       icon="save"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import TextArea from "@/components/form/TextArea";
import RowColComponent from "@/components/row/RowColComponent";
import RowComponent from "@/components/row/RowComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "EmployeeUpdate",
    components: {
        ButtonDelete,
        Multiselect,
        TextArea,
        InputComponent,
        ErrorComponent,
        ButtonSubmit,
        RowComponent,
        RowColComponent,
        ButtonRoute,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Funcionário")
        this.loadItems()
        this.nullInput()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/employee/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/employee/create"
            },

            create: false,

            formData: {
                name: "",
                cpf: "",
                rg: "",
                birth_date: "",
                type: "1",
                status: "",
                sexo: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                photo: "",
                complement: "",
                country: "Brasil",
                emails: [],
                phones: [],
                bank_accounts: [],
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadEmployee', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateEmployee', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Funcionário atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 4)
                .then((r) => {
                    if (r.data.indexOf('employee-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('employee-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        nullInput() {
            if (this.formData.emails.length === 0)
                this.addEmails()
            if (this.formData.phones.length === 0)
                this.addPhones()
            if (this.formData.bank_accounts.length === 0)
                this.addBankAccounts()
        },

        addEmails() {
            this.formData.emails.push({
                name: "",
                email: "",
                notification: 1
            })
        },

        addPhones() {
            this.formData.phones.push({
                name: "",
                phone: "",
                notification: 1
            })
        },

        addBankAccounts() {
            this.formData.bank_accounts.push({
                bank: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "",
                key_pix: "",
                main_account: 2
            })
        },

        removeEmail(index) {
            this.formData.emails.splice(index, 1)
        },

        removePhone(index) {
            this.formData.phones.splice(index, 1)
        },

        removeBankAccount(index) {
            this.formData.bank_accounts.splice(index, 1)
        },

        searchAPICEP() {
            let cep = this.formData.cep.replace(/\D/g, '');
            const params = {'cep': cep}
            this.$store.dispatch('searchApiCEP', params)
                .then((r) => {
                    this.formData.city = r.data.localidade
                    this.formData.state = r.data.uf
                    this.formData.stateView = r.data.uf
                    this.formData.district = r.data.bairro
                    this.formData.address = r.data.logradouro
                    this.formData.complement = r.data.complemento
                })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectSexo() {
            return this.$store.state.var.selectSexo
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBank() {
            return this.$store.state.var.selectBank
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType
        },

        selectPIX() {
            return this.$store.state.var.selectPIX
        },

        emailsAdd() {
            return this.formData.emails
        },

        phonesAdd() {
            return this.formData.phones
        },

        bankAccountsAdd() {
            return this.formData.bank_accounts
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 25%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 25%;
}

#tab-3,
#tab-3 + span,
#tab-3:checked ~ .line {
    left: 50%;
}

#tab-4,
#tab-4 + span,
#tab-4:checked ~ .line {
    left: 75%;
}
</style>