import MainComponent from "@/components/dashboard/MainComponent";

import EntranceExitSearch from "@/views/search/EntranceExitSearch";
import EntranceExitCreate from "@/views/create/EntranceExitCreate";

export default {
    path: "/entrance-exit",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "entrance-exit-search", component: EntranceExitSearch},
        {path: "create", name: "entrance-exit-create", component: EntranceExitCreate}
    ]
}