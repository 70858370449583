<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <form>
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="me.photo" :src="me.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ me.name }}</p>
                <router-link :to="{ name: 'user-photo' }" class="figure">
                    <i class="camera icon"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.password}">
                    <label>Senha:</label>
                    <input-component v-model="formData.password" type-input="password"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.passwordconfirm}">
                    <label>Confirmação de Senha:</label>
                    <input-component v-model="formData.passwordconfirm" type-input="password"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" icon="save" name="Salvar" :disabled="sendForm"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "UserUpdatePassword",
    components: {
        ErrorComponent,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
      this.$store.dispatch("changeNamePage", "Usuário")
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Alterar senha"
            },

            formData: {
                password: '',
                passwordconfirm: '',
            },

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPassword', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        toast.success("Senha atualizada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        }
    },
}
</script>