<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end ms-2" :items="itemsCreate"/>
            <button-submit class="float-end" v-if="pass" @click="resetPass" icon="unlock alternative"/>
        </div>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.email}">
                    <label>Email (Login):</label>
                    <input-component disabled v-model="formData.email" type-input="email"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12 mt-4 pt-2">
                    <label>Sexo:</label>
                    <input type="radio" id="feminino" v-model="formData.sexo" value="1">
                    <label for="feminino">Feminino</label>

                    <input type="radio" id="masculino" v-model="formData.sexo" value="2">
                    <label for="masculino">Masculino</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.group_id}">
                    <label>Grupo:</label>
                    <Multiselect :disabled="disabledGroup" v-model="formData.group_id" :searchable="true"
                                 :options="selectGroup" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <div v-if="disabledCompany" class="alert alert-secondary icon-message" role="alert">
                    Nenhum cliente selecionado
                </div>

                <div v-else-if="selectCompany.length === 0" class="alert alert-secondary icon-message" role="alert">
                    Não existe empresas cadastradas no cliente selecionado
                </div>

                <div v-else>
                    <div class="form-check check-box-header">
                        <input :disabled="disabledCompany" class="form-check-input " type="checkbox"
                               @click="checkAll" id="checkBoxMaster">
                        <label class="form-check-label">Empresas</label>
                    </div>

                    <div class="form-check" v-for="(company, index) in selectCompany" :key="index">
                        <input :disabled="disabledCompany" type="checkbox" :value="company.value"
                               @click="checkOne(company.value)"
                               :id="'checkOne' + company.value" :class="'form-check-input checkBoxAll'">
                        <label class="form-check-label">{{ company.name }}</label>
                    </div>
                </div>
            </row-col-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" @click="submit" icon="save" name="Salvar"
                                   :disabled="sendForm"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "UserSystemUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        ErrorComponent,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadReleasesAccessList")
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user-system/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/user-system/create"
            },

            create: false,
            pass: false,

            formData: {
                id_tenant: "",
                name: "",
                sexo: "",
                status: "",
                email: "",
                group_id: "",
                companies: [],
                tenant_id: ""
            },

            disabledGroup: true,
            disabledCompany: true,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUserSystem', {...this.params})
                .then((r) => {
                    this.formData = r;
                    this.formData.tenant_id = this.idClient
                    this.loadAllList(r.id_tenant)
                    setTimeout(() => {
                        this.checkStart()
                    }, 1500)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserSystem', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Usuário atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.formData.tenant_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 10)
                .then((r) => {
                    if (r.data.indexOf('user-system-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('user-system-create') !== -1) {
                            this.create = true
                        }

                        if (r.data.indexOf('user-system-reset-password') !== -1) {
                            this.pass = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadAllList(id) {
            if (id) {
                this.$store.dispatch('loadUserSystemGroup', id)
                    .then(() => {
                        this.disabledGroup = false
                        this.selectGroup.push({"label": "Master", "value": 1})
                    })
                this.$store.dispatch('loadUserSystemCompany', id)
                    .then(() => {
                        this.disabledCompany = false
                    })
            } else {
                this.disabledGroup = true
                this.disabledCompany = true
                this.formData = {}
            }
        },

        checkOne(id) {
            let company = document.getElementById("checkOne" + id);
            if (company.checked) {
                if (!this.formData.companies.includes(id)) {
                    this.formData.companies.push(id)
                }
            } else {
                let indexArray = this.formData.companies.indexOf(id);
                if (indexArray !== -1) {
                    this.formData.companies.splice(indexArray, 1);
                }
            }
            let companyLength = this.selectCompany.length
            let listCompany = document.getElementsByClassName("checkBoxAll");
            let contChecked = 0

            for (let i = 0; i < listCompany.length; ++i) {
                if (listCompany[i].checked)
                    contChecked = contChecked + 1
            }
            document.getElementById("checkBoxMaster").checked = companyLength === contChecked;
        },

        checkAll() {
            let listCompany = document.getElementsByClassName("checkBoxAll");
            if (document.getElementById("checkBoxMaster").checked) {
                for (let i = 0; i < listCompany.length; ++i) {
                    listCompany[i].checked = true;
                    let valueCompany = parseInt(listCompany[i].value);
                    if (!this.formData.companies.includes(valueCompany)) {
                        this.formData.companies.push(valueCompany)
                    }
                }
            } else {
                for (let cont = 0; cont < listCompany.length; ++cont) {
                    listCompany[cont].checked = false;
                    let valueCompany = parseInt(listCompany[cont].value);
                    let indexArray = this.formData.companies.indexOf(valueCompany);
                    if (indexArray !== -1) {
                        this.formData.companies.splice(indexArray, 1);
                    }
                }
            }
        },

        checkStart() {
            let totalPage = this.selectCompany.length;
            let cont = 0;
            let i = 0;
            let companiesList = this.formData.companies;
            this.selectCompany.forEach(function (element) {
                if (companiesList.includes(element.value)) {
                    document.getElementById("checkOne" + element.value).checked = true
                    cont = cont + 1;
                }
                if (cont >= totalPage) {
                    document.getElementById("checkBoxMaster").checked = true
                }
                i = i + 1;
            });
        },

        resetPass() {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente resetar a senha de ${this.formData.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: this.id,
                        client_id: this.idClient
                    }

                    this.$store.dispatch('userSystemResetPassword', params).then(() => {
                        this.loadItems(1)
                        toast.success("Senha resetada com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Ocorreu um erro!`, {timeout: 1200});
                    })
                }
            });
        },
    },

    beforeMount() {
        this.selectGroup.push({"label": "Master", "value": 1})
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClient() {
            return this.$store.state.release_access.list
        },

        selectGroup() {
            return this.$store.state.user_system.list_group
        },

        selectCompany() {
            return this.$store.state.user_system.list_companies
        },

        params() {
            return {
                id: this.id,
                client_id: this.idClient
            }
        }
    }
}
</script>