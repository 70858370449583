<template>
    <div class="container-chat">
        <div class="d-flex">
            <nav class="menu">
                <ul class="items">
                    <li class="item">
                        <i class="home icon" aria-hidden="true"></i>
                    </li>
                    <li class="item">
                        <i class="user icon" aria-hidden="true"></i>
                    </li>
                    <li class="item">
                        <i class="pencil icon" aria-hidden="true"></i>
                    </li>
                    <li class="item" @click="openChat">
                        <i class="commenting icon" aria-hidden="true"></i>
                    </li>
                    <li class="item">
                        <i class="file icon" aria-hidden="true"></i>
                    </li>
                    <li class="item">
                        <i class="cog icon" aria-hidden="true"></i>
                    </li>
                </ul>
            </nav>

            <section :class="{'d-none' : discussions}" class="discussions">
                <div class="discussion search">
                    <div class="searchbar">
                        <i class="search icon" aria-hidden="true"></i>
                        <input type="text" placeholder="Search...">
                    </div>
                </div>
                <div class="discussion message-active" @click="selectChat">
                    <div class="photo"
                         style="background-image: url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);">
                        <div class="online"></div>
                    </div>
                    <div class="desc-contact">
                        <p class="name">Megan Leib</p>
                        <p class="message">9 pm at the bar if possible 😳</p>
                    </div>
                    <div class="timer">12 sec</div>
                </div>

                <div class="discussion" @click="selectChat">
                    <div class="photo"
                         style="background-image: url(https://i.pinimg.com/originals/a9/26/52/a926525d966c9479c18d3b4f8e64b434.jpg);">
                        <div class="online"></div>
                    </div>
                    <div class="desc-contact">
                        <p class="name">Dave Corlew</p>
                        <p class="message">Let's meet for a coffee or something today ?</p>
                    </div>
                    <div class="timer">3 min</div>
                </div>

                <!--<div class="discussion">
                    <div class="photo"
                         style="background-image: url(https://images.unsplash.com/photo-1497551060073-4c5ab6435f12?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80);">
                    </div>
                    <div class="desc-contact">
                        <p class="name">Jerome Seiber</p>
                        <p class="message">I've sent you the annual report</p>
                    </div>
                    <div class="timer">42 min</div>
                </div>

                <div class="discussion">
                    <div class="photo" style="background-image: url(https://card.thomasdaubenton.com/img/photo.jpg);">
                        <div class="online"></div>
                    </div>
                    <div class="desc-contact">
                        <p class="name">Thomas Dbtn</p>
                        <p class="message">See you tomorrow ! 🙂</p>
                    </div>
                    <div class="timer">2 hour</div>
                </div>

                <div class="discussion">
                    <div class="photo"
                         style="background-image: url(https://images.unsplash.com/photo-1553514029-1318c9127859?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=700&q=80);">
                    </div>
                    <div class="desc-contact">
                        <p class="name">Elsie Amador</p>
                        <p class="message">What the f**k is going on ?</p>
                    </div>
                    <div class="timer">1 day</div>
                </div>

                <div class="discussion">
                    <div class="photo"
                         style="background-image: url(https://images.unsplash.com/photo-1541747157478-3222166cf342?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=967&q=80);">
                    </div>
                    <div class="desc-contact">
                        <p class="name">Billy Southard</p>
                        <p class="message">Ahahah 😂</p>
                    </div>
                    <div class="timer">4 days</div>
                </div>

                <div class="discussion">
                    <div class="photo"
                         style="background-image: url(https://images.unsplash.com/photo-1435348773030-a1d74f568bc2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1050&q=80);">
                        <div class="online"></div>
                    </div>
                    <div class="desc-contact">
                        <p class="name">Paul Walker</p>
                        <p class="message">You can't see me</p>
                    </div>
                    <div class="timer">1 week</div>
                </div>-->
            </section>

            <section :class="{'d-none' : chat}" class="chat">
                <div class="header-chat">
                    <!--                    <i class="icon user icon" aria-hidden="true"></i>-->
                    <p class="name mb-0">Megan Leib</p>
                    <!--                    <i class="icon clickable fa fa-ellipsis-h right" aria-hidden="true"></i>-->
                </div>
                <div class="messages-chat">
                    <div class="message">
                        <div class="photo"
                             style="background-image: url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);">
                            <div class="online"></div>
                        </div>
                        <p class="text"> Hi, how are you ? </p>
                    </div>
                    <div class="message text-only">
                        <p class="text"> What are you doing tonight ? Want to go take a drink ?</p>
                    </div>
                    <p class="time"> 14h58</p>
                    <div class="message text-only">
                        <div class="response">
                            <p class="text"> Hey Megan ! It's been a while 😃</p>
                        </div>
                    </div>
                    <div class="message text-only">
                        <div class="response">
                            <p class="text"> When can we meet ?</p>
                        </div>
                    </div>
                    <p class="response-time time"> 15h04</p>
                    <div class="message">
                        <div class="photo"
                             style="background-image: url(https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80);">
                            <div class="online"></div>
                        </div>
                        <p class="text"> 9 pm at the bar if possible 😳</p>
                    </div>
                    <p class="time"> 15h09</p>
                </div>
                <div class="footer-chat">
                    <input type="text" class="write-message" placeholder="Type your message here">
                    <div class="clickable send-b">
                        <i class="paper plane icon text-white" aria-hidden="true"></i>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatView",

    created() {
        this.screenAdjustment()
    },

    data() {
        return {
            chat: false,
            discussions: false
        }
    },

    methods: {
        openChat() {
            if (window.innerWidth <= 1023) {
                this.chat = !this.chat
                this.discussions = !this.discussions
            }
        },

        selectChat() {
            if (window.innerWidth >= 1024) {
                this.discussions = false
                this.chat = false
            }
        },

        screenAdjustment() {
            window.addEventListener("resize", () => {
                if (window.innerWidth <= 1023) {
                    this.chat = false
                    this.discussions = true
                } else {
                    this.discussions = false
                    this.chat = false
                }
            })
        }

    }
}
</script>

<style>
.container-chat {
    padding: 0;
    min-height: 100vh;
    background-color: #FFF;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

/* ===== MENU ===== */
.menu {
    float: left;
    width: 70px;
    min-height: 100vh;
    background: #F25C05;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19);
    /*background: linear-gradient(#4768b5, #35488e);
    background: -o-linear-gradient(#4768b5, #35488e);
    background: -moz-linear-gradient(#4768b5, #35488e);
    background: -webkit-linear-gradient(#4768b5, #35488e);*/
}

.menu .items {
    margin: auto;
    padding: 0;
    list-style: none;
}

.menu .items .item {
    height: 70px;
    display: flex;
    color: #FFFFFF;
    font-size: 17pt;
    align-items: center;
    justify-content: center;
    /*border-bottom: 1px solid #dddddd;*/
}

.menu .items .item-active {
    color: #FFF;
    background-color: #ebebeb;
}

.menu .items .item:hover {
    color: #cfe5ff;
    cursor: pointer;
    opacity: 0.8;
    background-color: #F25C05;
}

/* === CONVERSATIONS === */

.discussions {
    width: 35%;
    min-height: 100%;
    overflow: hidden;
    display: inline-block;
    background-color: #FAFAFA;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.20);
}

.discussions .discussion {
    width: 100%;
    height: 90px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #FAFAFA;
    border-bottom: solid 1px #E0E0E0;
}

.discussions .search {
    display: flex;
    color: #E0E0E0;
    align-items: center;
    justify-content: center;
}

.discussions .search .searchbar {
    width: 70%;
    height: 40px;
    display: flex;
    padding: 0 20px;
    cursor: pointer;
    border-radius: 50px;
    align-items: center;
    background-color: #FFF;
    border: 1px solid #EEEEEE;
}

.discussions .search .searchbar input {
    width: 100%;
    height: 38px;
    border: none;
    margin-left: 15px;
    font-family: 'Montserrat', sans-serif;;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
    color: #E0E0E0;
}

.discussions .search .searchbar input *:-moz-placeholder {
    color: #E0E0E0;
}

.discussions .search .searchbar input *::-moz-placeholder {
    color: #E0E0E0;
}

.discussions .search .searchbar input *:-ms-input-placeholder {
    color: #E0E0E0;
}

.discussions .message-active {
    width: 100%;
    height: 90px;
    background-color: #FFF;
    border-right: solid 8px #F25C05;
    border-bottom: solid 1px #E0E0E0;
}

.discussions .discussion .photo {
    width: 45px;
    height: 45px;
    display: block;
    margin-left: 20px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background: #E6E7ED no-repeat center;
    background-size: cover;
}

.online {
    top: 30px;
    left: 35px;
    width: 13px;
    height: 13px;
    position: relative;
    border-radius: 13px;
    background-color: #8BC34A;
    border: 3px solid #FAFAFA;
}

.desc-contact {
    width: 50%;
    height: 43px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.discussions .discussion .name {
    color: #515151;
    font-size: 11pt;
    margin: 0 0 0 20px;
    font-family: 'Montserrat', sans-serif;
}

.discussions .discussion .message {
    color: #515151;
    font-size: 9pt;
    margin: 6px 0 0 20px;
    font-family: 'Montserrat', sans-serif;
}

.timer {
    margin-left: 15%;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    padding: 3px 8px;
    color: #BBB;
    background-color: #FFF;
    border: 1px solid #E5E5E5;
    border-radius: 15px;
}

.chat {
    width: calc(65%);
}

.header-chat {
    background-color: #FFF;
    height: 90px;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.100);
    display: flex;
    align-items: center;
}

.chat .header-chat .icon {
    margin-left: 30px;
    color: #515151;
    font-size: 14pt;
}

.chat .header-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 13pt;
    color: #515151;
}

.chat .header-chat .right {
    position: absolute;
    right: 40px;
}

.chat .messages-chat {
    padding: 25px 35px;
}

.chat .messages-chat .message {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat .messages-chat .text {
    margin: 0 35px;
    background-color: #f6f6f6;
    padding: 15px;
    border-radius: 12px;
}

.text-only {
    margin-left: 45px;
}

.time {
    font-size: 10px;
    color: lightgrey;
    margin-bottom: 10px;
    margin-left: 85px;
}

.response-time {
    float: right;
    margin-right: 40px !important;
}

.response {
    float: right;
    margin-right: 0 !important;
    margin-left: auto; /* flexbox alignment rule */
}

.response .text {
    background-color: #e3effd !important;
}

.footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;

}

.chat .footer-chat .icon {
    color: #C0C0C0;
    font-size: 14pt;
}

.chat .footer-chat .send-b {
    color: #fff;
    background-color: #F25C05;
    position: absolute;
    right: 15px;
    height: 45px;
    width: 45px;
    padding: 8px 10px;
    border-radius: 50px;
    font-size: 14pt;
}

.chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 13pt;
    color: #515151;
}

.chat .footer-chat .right {
    position: absolute;
    right: 40px;
}

.write-message {
    border: none !important;
    width: 80%;
    height: 50px;
    margin-left: 20px;
    padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.footer-chat input *:-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.footer-chat input *::-moz-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
    margin-left: 5px;
}

.footer-chat input *:-ms-input-placeholder {
    color: #C0C0C0;
    font-size: 13pt;
}

.clickable {
    cursor: pointer;
}

@media (max-width: 1023px) {
    .menu {
        bottom: 0;
        width: 100%;
        min-height: 50px;
        max-height: 50px;
        position: absolute;
    }

    .menu .items {
        display: flex;
    }

    .menu .items .item {
        width: 100%;
        height: 40px;
    }

    .discussions {
        width: 100%;
    }

    .footer-chat {
        width: 100%;
        bottom: 51px;
    }

    .chat {
        width: 100%;
    }
}
</style>