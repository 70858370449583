<template>
    <router-link :to="items.route" class="btn button-route">
        <i :class="'icon ' + items.icon"></i>
        {{ items.name }}
    </router-link>
</template>

<script>
export default {
    name: "ButtonRoute",

    props: {
        items: {
            "required": true,
            "type": Object,
            "default": []
        },
    },
}
</script>

<style scoped>
.button-route {
    font-family: 'Comfortaa', cursive;
    color: white;
    height: 42px;
    min-width: 42px;
    padding: 0.4rem;
    font-size: 18px;
    text-align: center;
    border-radius: 0;
    text-decoration: none;
    border: 1px solid #e0e0e0;
    background-color: #F25C05;
}

.button-route i.icon {
    margin: 0;
}

.button-route:active,
.button-route:focus,
.button-route:hover,
.button-route:focus-visible {
    font-family: 'Comfortaa', cursive;
    color: white;
    border-color: #F25C05;
    background-color: #F25C05;
    box-shadow: none !important;
}
</style>