<template>
    <router-link :to="{name:route, params:{id: id}}" class="btn btn-secondary button-show">
        <i class="eye icon"></i>
    </router-link>
</template>

<script>
export default {
    name: "ButtonShow",

    props: {
        route: {
            'type': String,
            "required": true,
        },

        id: {
            'type': Number,
            "required": true,
        }
    }
}
</script>

<style scoped>
.button-show {
    padding: 0;
    width: 40px;
    height: 40px;
    font-size: 15px;
    line-height: 37px;
    border-radius: 0;
}

.button-show i {
    margin: 0 auto;
}
</style>