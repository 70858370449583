export default {
    icon: "calculator",
    name: "Financeiro",
    subLinks: [
        {
            name: 'Conta Bancária',
            route: '/bank-account/search',
            pagePermissions: [
                "account-bank-search",
                "account-bank-create",
                "account-bank-edit",
                "account-bank-delete"
            ],
        },

        {
            name: 'Entrada e Saída',
            route: '/entrance-exit/search',
            pagePermissions: [
                "entrance-exit-search",
                "entrance-exit-create"
            ],
        },

        {
            name: 'Entrada e Saída (Previsão)',
            route: '/entrance-exit-preview/search',
            pagePermissions: [
                "entrance-exit-preview-search",
                "entrance-exit-preview-create",
                "entrance-exit-preview-low",
                "entrance-exit-preview-delete"
            ],
        },

        {
            name: 'Recibo',
            route: '/receipt/search',
            pagePermissions: [
                "receipt-search",
                "receipt-create",
                "receipt-edit",
                "receipt-delete",
            ],
        },

        {
            name: 'Tipo de conta',
            route: '/account-type/search',
            pagePermissions: [
                "account-type-search",
                "account-type-create",
                "account-type-edit",
            ],
        },
    ],
    pageLinksNavCheck: [],
}