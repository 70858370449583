import MainComponent from "@/components/dashboard/MainComponent";

import ReleaseAccessSearch from "@/views/search/ReleaseAccessSearch";
import ReleaseAccessCreate from "@/views/create/ReleaseAccessCreate";
import ReleaseAccessUpdate from "@/views/update/ReleaseAccessUpdate";
import ReleaseAccessShow from "@/views/show/ReleaseAccessShow";

export default {
    path: "/release-access",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "release-access-search", component: ReleaseAccessSearch},
        {path: "create", name: "release-access-create", component: ReleaseAccessCreate},
        {path: "edit/:id", name: "release-access-edit", props: true, component: ReleaseAccessUpdate},
        {path: "show/:id", name: "release-access-show", props: true, component: ReleaseAccessShow}
    ]
}