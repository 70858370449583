<template>
    <painel-component :items="itensOne">
        <error-component :errors="errors" v-if="showError" class="mb-5"/>

        <div class="aux my-4">
            <div class="avatar-form">
                <img v-if="me.photo" :src="me.photo" class="avatar__image" alt="">
                <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
            </div>
            <p>{{ me.name }}</p>
        </div>

        <form autocomplete="off">
            <row-component>
                <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                    <label>Foto:</label>
                    <input type="file" name="file" id="formFile" @change="onFileChange">
                    <label for="formFile" class="labelFile">
                        <span>{{ this.namePhoto }}</span>
                        <span><i class="icon photo"></i></span>
                    </label>

                    <button-submit class="my-3" icon="cloud upload" name="Enviando" v-if="sendForm"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>

import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "UserUpdatePhoto",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        ButtonSubmit,
        ErrorComponent,
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
    },

    data() {
        return {
            imagePreview: "",

            itensOne: {
                icon: "edit",
                title: "Alterar Foto",
            },

            formData: {
                photo: '',
            },

            namePhoto: "",

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUserPhoto', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.$store.commit("UPDATE_PHOTO_USER", r[0].photo)
                        toast.success("Foto atualizada com sucesso", {timeout: 2000});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.formData.photo = files[0]
            this.namePhoto = this.formData.photo.name
            if (files[0].type.indexOf('image') === 0)
                this.previewImage(files[0])
            this.submit()
        },

        previewImage(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview = e.target.result
            }
            reader.readAsDataURL(file)
        },

        photoPreviewTimer() {
            this.imagePreview = this.formData.photo
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        }
    },

    beforeMount() {
        setTimeout(() => this.photoPreviewTimer(), 1000);
    },
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

.labelFile {
    color: #000;
    padding: 0;
    height: 42px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 5px;
    justify-content: space-between;
    border-bottom-right-radius: 5px;
}

.labelFile span:nth-child(2) {
    color: #fff;
    width: 42px;
    height: 42px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    background-color: #F25C05;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
</style>