<template>
    <div class="modal-w position-fixed d-grid start-0 top-0" v-if="clauses.show">
        <div class="modal-p position-relative p-4 pt-3" v-if="clauses.show">
            <div @click="clausesClose" role="button" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Importante:</label>
                    <switch-component v-if="!isEdit" @check-click="importantChange" class="mt-2"/>
                    <switch-component v-else :input-checked="parseInt(clauses.formData.important) === 2"
                                      @check-click="importantChange" class="mt-2"/>
                </row-col-component>
            </row-component>

            <QuillEditor style="min-height: 200px" :contentType="'html'" :toolbar="toolbar" theme="snow"
                         v-model:content="clauses.formData.item"/>

            <row-component class="mt-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="!isEdit" @click="submitItem" name="Adicionar" icon="plus"/>
                    <button-submit v-else @click="updateItem" name="Salvar" icon="save"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file outline icon"></i>
                <span>Cláusulas</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.price}">
                                <label>Valor total:</label>
                                <input-component v-money="MONEY" v-model="formData.price" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                             :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.product}">
                                <label>Produto:</label>
                                <Multiselect v-model="formData.product" :options="selectBusiness" :searchable="true"
                                             :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.obs}">
                                <label>Observações:</label>
                                <text-area v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-submit class="w-100" @click="submit" :disabled="sendForm" name="Salvar"
                                               icon="save"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-route class="w-100" :items="itemsTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <button-submit @click="clauses.show = true" class="mb-3" icon="plus"/>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <div v-else>
                        <draggable v-model="formData.itens" @start="drag=true" @end="updateOrder" item-key="id">
                            <template #item="{element, index}">
                                <div class="accordion rounded-0 mb-4" :id="'accordionExample' + index">
                                    <div class="accordion-item rounded-0">
                                        <h2 class="accordion-header rounded-0 position-relative">
                                            <button class="accordion-button rounded-0" type="button"
                                                    :class="{'collapsed' : parseInt(element.important) === 2}"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                    :aria-controls="'collapseOne' + index">
                                                Cláusula {{ index + 1 }}
                                            </button>

                                            <div class="btn-group" role="group">
                                                <button @click="clausesEdit(element)" type="button"
                                                        class="btn btn-main">
                                                    <i class="edit icon"></i>
                                                </button>
                                                <button @click="destroy(element)" type="button" class="btn btn-main">
                                                    <i class="trash icon"></i>
                                                </button>
                                            </div>
                                        </h2>

                                        <div :id="'collapseOne' + index" class="accordion-collapse collapse"
                                             :class="{'show' : parseInt(element.important) === 2}"
                                             :data-bs-parent="'#accordionExample' + index">
                                            <div class="accordion-body">
                                                <row-component>
                                                    <row-col-component
                                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <label>Importante:</label>
                                                        <switch-component class="mt-2" :disabled="true"
                                                                          :input-checked="parseInt(element.important) === 2"/>
                                                    </row-col-component>
                                                </row-component>

                                                <QuillEditor style="min-height: 200px; border: 1px solid #d1d5db"
                                                             :contentType="'html'" read-only="read-only" theme="bubble"
                                                             v-model:content="element.item"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import SwitchComponent from "@/components/form/SwitchComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import draggable from "vuedraggable";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "ContractModelUpdate",
    components: {
        draggable,
        QuillEditor,
        SwitchComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Modelo de Contrato")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/contract-model/search"
            },

            MONEY,

            formData: {
                name: "",
                price: "",
                obs: "",
                status: "",
                product: "",
                itens: []
            },

            clauses: {
                show: false,
                formData: {
                    contract_model_id: this.id,
                    item: "",
                    important: "1",
                    order: ""
                }
            },

            drag: false,
            important: 1,
            isEdit: false,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean']
            ],

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadContractModel', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateContractModel', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Modelo de contrato atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 34)
                .then((r) => {
                    if (r.data.indexOf('contract-model-edit') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        submitItem() {
            this.clauses.formData.important = this.important
            this.clauses.formData.order = this.formData.itens.length + 1
            this.$store.dispatch('storeContractModelItem', this.clauses.formData)
                .then(() => {
                    toast.success("Item criado com sucesso", {timeout: 1200});
                    this.clauses.show = false
                    this.loadItems()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        updateItem() {
            this.$store.dispatch('updateContractModelItem', this.clauses.formData)
                .then(() => {
                    toast.success("Item atualizado com sucesso", {timeout: 1200});
                    this.clauses.show = false
                    this.loadItems()
                }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar este item?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyContractModelItem', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Item deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        updateOrder() {
            this.drag = false
            let clauses = this.formData.itens
            let formData = {
                id: this.id,
                itens: []
            }

            clauses.forEach((element, index) => {
                formData.itens.push({
                    id: element.id,
                    order: index
                })
            })

            this.$store.dispatch("updateContractModelOrder", formData)
        },

        clausesClose() {
            this.isEdit = false
            this.clauses.show = false
            this.clauses.formData.item = ""
            this.clauses.formData.important = 1
        },

        clausesEdit(data) {
            this.isEdit = true
            this.clauses.show = true
            this.clauses.formData = {
                id: data.id,
                contract_model_id: this.id,
                item: data.item,
                important: data.important,
            }
        },

        importantChange() {
            if (parseInt(this.important) === 1) {
                this.important = 2
            } else {
                this.important = 1
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.modal-w {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 65%;
    height: auto;
    overflow-y: auto;
    max-height: 457px;
    background-color: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.btn-group {
    top: 0;
    right: 40px;
    margin: 6px;
    z-index: 20;
    position: absolute;
}

.btn-main {
    color: #F25C05;
    --bs-btn-border-width: 0;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
    border: 0;
    color: #F25C05;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

@media (max-width: 768px) {
    .modal-p{
        width: 98%;
    }
}
</style>