<template>
    <div class="modal-w position-fixed d-grid" v-if="menu.show || submenu.show || preview.show">
        <div class="modal-v" v-if="menu.show">
            <error-component v-if="menu.showError" :errors="menu.errors"/>

            <div role="button" @click="modalMenu" class="btn-close"></div>

            <div class="my-2">
                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : menu.errors.name}">
                        <label>Nome:</label>
                        <input-component v-model="menu.formData.name" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                       :class="{'formError' : menu.errors.status}">
                        <label>Status:</label>
                        <Multiselect v-model="menu.formData.status" :options="selectActiveInative"
                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-submit v-if="!edit" @click="submit" name="Adicionar" icon="plus"/>
                        <button-submit v-else @click="update" name="Salvar" icon="save"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>

        <div class="modal-v" v-if="submenu.show">
            <error-component v-if="submenu.showError" :errors="submenu.errors"/>

            <div role="button" @click="modalSubMenu" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : submenu.errors.name_sub}">
                    <label>Nome:</label>
                    <input-component v-model="submenu.formData.name_sub" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : submenu.errors.file}" v-if="!edit">
                    <label>Arquivo:</label>
                    <input-component @change="onFileChange" type-input="file"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="!edit" @click="submitVideo" name="Adicionar" icon="plus"/>
                    <button-submit v-else @click="updateVideo" name="Salvar" icon="save"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="preview" v-if="preview.show">
            <div @click="preview.show = false"
                 class="p-2 rounded-3 bg-body-secondary position-absolute top-0 end-0 m-3">
                <div class="btn-close" role="button"></div>
            </div>

            <video controls autoplay loop>
                <source :src="preview.video">
            </video>
        </div>
    </div>

    <painel-component>
        <div class="clearfix mb-3">
            <button-submit @click="menu.show = true" class="float-end" icon="plus"/>
        </div>

        <draggable v-model="object.data" @start="drag=true" @end="orderTutorial" item-key="id">
            <template #item="{element, index}">
                <div class="accordion mb-3" :id="'accordionExample' + index" v-if="object.data.length >= 0">
                    <div class="accordion-item rounded-0 position-relative">
                        <h2 class="accordion-header">
                            <button class="accordion-button rounded-0 collapsed" type="button" data-bs-toggle="collapse"
                                    :data-bs-target="'#collapseMenu' + index" aria-expanded="false"
                                    :aria-controls="'collapseMenu' + index">
                                {{ element.name }}
                            </button>

                            <div class="btn-group" role="group">
                                <button class="btn btn-main" @click="modalSubMenuCreate(element.id)">
                                    <i class="plus icon"></i>
                                </button>

                                <button class="btn btn-main" @click="modalMenuEdit(element)">
                                    <i class="edit icon"></i>
                                </button>

                                <button class="btn btn-main" @click="destroy(element)">
                                    <i class="trash icon"></i>
                                </button>
                            </div>
                        </h2>

                        <div :id="'collapseMenu' + index" class="accordion-collapse collapse"
                             :data-bs-parent="'#accordionExample' + index">
                            <div class="accordion-body">
                                <div class="draggable">
                                    <draggable v-model="element.submenu" @start="drag=true" @end="orderVideo(element.submenu)"
                                               item-key="id">
                                        <template #item="{element, index}">
                                            <div class="campaign-items bg-body-secondary" :data-count="index + 1">
                                                <div class="d-flex align-items-center w-50">
                                                    <i class="fs-3 file video icon"></i>
                                                    <p class="text-truncate w-100 mb-0">{{ element.name_sub }}</p>
                                                </div>

                                                <div class="btn-group two" role="group">
                                                    <button class="btn btn-main" @click="previewVideo(element.link)">
                                                        <i class="eye icon"></i>
                                                    </button>

                                                    <input-component @change="onFileEdit" type-input="file"
                                                                     class="d-none" id="file-label"/>
                                                    <label @click="getId(element.id)" class="btn btn-main"
                                                           for="file-label">
                                                        <i class="file video icon"></i>
                                                    </label>

                                                    <button class="btn btn-main" @click="modalSubMenuEdit(element)">
                                                        <i class="edit icon"></i>
                                                    </button>

                                                    <button class="btn btn-main" @click="destroyVideo(element)">
                                                        <i class="trash icon"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                    </draggable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </draggable>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import draggable from 'vuedraggable'
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "TutorialView",
    components: {
        draggable,
        ErrorComponent,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ButtonSubmit,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Tutorial")
        this.loadItems(1)
    },

    data() {
        return {
            formData: {
                name: "",
                status: ""
            },

            edit: false,
            drag: false,

            menu: {
                errors: [],
                show: false,
                showError: false,
                formData: {
                    name: "",
                    status: "",
                    order: 1
                }
            },

            submenu: {
                errors: [],
                show: false,
                showError: false,
                formData: {
                    menu_id: "",
                    name_sub: "",
                    file: "",
                    order: 1
                }
            },

            preview: {
                show: false,
                video: ""
            },

            searchSubmit: false,
        }
    },

    methods: {
        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadTutorials", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        submit() {
            this.$store.dispatch('storeTutorial', this.menu.formData)
                .then(() => {
                    this.menu.showError = false
                    this.menu.errors = []
                    this.modalMenu()
                    this.loadItems(1)
                    toast.success("Cadastro efetuado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.menu.showError = true;
                this.menu.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },

        update() {
            this.$store.dispatch('updateTutorial', this.menu.formData)
                .then(() => {
                    this.menu.showError = false
                    this.menu.errors = []
                    this.modalMenu()
                    this.loadItems(1)
                    toast.success("Cadastro atualizado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.menu.showError = true;
                this.menu.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyTutorial', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        orderTutorial() {
            this.drag = false
            let formData = {itens: []}

            this.object.data.forEach((element, index) => {
                formData.itens.push({
                    id: element.id,
                    order: index,
                })
            })

            this.$store.dispatch("updateOrderTutorial", formData)
        },

        modalMenu() {
            this.edit = false
            this.menu.show = false
            this.menu = {
                errors: [],
                show: false,
                showError: false,
                formData: {
                    name: "",
                    status: "",
                    order: 1
                }
            }
        },

        modalMenuEdit(data) {
            this.edit = true
            this.menu.show = true
            this.menu.formData.id = data.id
            this.menu.formData.name = data.name
            this.menu.formData.status = data.status
            this.menu.formData.order = data.order
        },

        submitVideo() {
            this.$store.dispatch('storeTutorialVideo', this.submenu.formData)
                .then(() => {
                    this.submenu.showError = false
                    this.submenu.errors = []
                    this.modalSubMenu()
                    this.loadItems(1)
                    toast.success("Cadastro efetuado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.submenu.showError = true;
                this.submenu.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },

        updateVideo() {
            let formData = {
                id: this.submenu.formData.menu_id,
                name_sub: this.submenu.formData.name_sub,
                order: this.submenu.formData.order,
            }

            this.$store.dispatch('updateTutorialVideo', formData)
                .then(() => {
                    this.submenu.showError = false
                    this.submenu.errors = []
                    this.modalSubMenu()
                    this.loadItems(1)
                    toast.success("Cadastro atualizado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.submenu.showError = true;
                this.submenu.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },

        updateFile() {
            let formData = {
                id: this.submenu.formData.menu_id,
                file: this.submenu.formData.file,
            }

            this.$store.dispatch('storeVideoTutorialVideo', formData)
                .then(() => {
                    this.submenu.showError = false
                    this.submenu.errors = []
                    this.modalSubMenu()
                    this.loadItems(1)
                    toast.success("Video atualizado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.submenu.showError = true;
                this.submenu.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },

        destroyVideo(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name_sub} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyTutorialVideo', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name_sub} `, {timeout: 1200});
                    })
                }
            });
        },

        orderVideo(data) {
            this.drag = false
            let formData = {itens: []}

            data.forEach((element, index) => {
                formData.itens.push({
                    id: element.id,
                    order: index,
                })
            })

            this.$store.dispatch("updateOrderTutorialVideo", formData)
        },

        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.submenu.formData.file = files[0]
        },

        onFileEdit(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.submenu.formData.file = files[0]
            this.updateFile()
        },

        getId(id) {
            this.submenu.formData.menu_id = id
        },

        modalSubMenuCreate(id) {
            this.submenu.formData.menu_id = id
            this.submenu.show = true
        },

        modalSubMenuEdit(data) {
            this.edit = true
            this.submenu.show = true
            this.submenu.formData.menu_id = data.id
            this.submenu.formData.name_sub = data.name_sub
            this.menu.formData.order = data.order
        },

        modalSubMenu() {
            this.edit = false
            this.submenu.show = false
            this.submenu = {
                errors: [],
                show: false,
                showError: false,
                formData: {
                    menu_id: "",
                    name_sub: "",
                    file: "",
                    order: 1
                }
            }
        },

        previewVideo(video) {
            this.preview.show = true
            this.preview.video = video
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        object() {
            return this.$store.state.tutorial.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                status: this.formData.status
            }
        }
    }
}

</script>

<style scoped>
.btn-group {
    top: 5px;
    right: 52px;
    z-index: 20;
    position: absolute;
}

.btn-group.two {
    top: 9px;
    right: 10px;
}

.draggable > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.campaign-items {
    width: 49.6%;
    height: 60px;
    margin-top: 1rem;
    position: relative;
    align-items: center;
    display: inline-flex;
    padding-left: 0.5rem;
    justify-content: space-between;
}

.campaign-items i {
    line-height: 30px;
    margin-right: 0.5rem;
}

.btn-main {
    height: 42px;
    color: #000000;
    min-width: 42px;
    padding: 0.4rem;
    font-size: 20px;
    border-radius: 0;
    text-align: center;
    text-decoration: none;
}

.btn-main:hover{
    color: #F25C05;
}

.btn-main i.icon {
    margin: 0;
}

.modal-w {
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-v {
    width: 60%;
    height: auto;
    position: relative;
    padding: 2.5rem 1rem;
    background-color: #ffffff;
}

.preview > video {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    transition: -webkit-transform 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
}

.modal-v .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

@media (max-width: 768px) {
    .campaign-items {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .modal-v{
        width: 95%;
    }
}
</style>