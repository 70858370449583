import MainComponent from "@/components/dashboard/MainComponent";

import AccountTypeSearch from "@/views/search/AccountTypeSearch";
import AccountTypeCreate from "@/views/create/AccountTypeCreate";
import AccountTypeUpdate from "@/views/update/AccountTypeUpdate";
import AccountTypeShow from "@/views/show/AccountTypeShow";

export default {
    path: "/account-type",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "account-type-search", component: AccountTypeSearch},
        {path: "create", name: "account-type-create", component: AccountTypeCreate},
        {path: "edit/:id", name: "account-type-edit", component: AccountTypeUpdate, props: true},
        {path: "show/:id", name: "account-type-show", component: AccountTypeShow, props: true},
    ]
}