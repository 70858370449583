import MainComponent from "@/components/dashboard/MainComponent"

import UserGroupSearch from "@/views/search/UserGroupSearch";
import UserGroupCreate from "@/views/create/UserGroupCreate";
import UserGroupUpdate from "@/views/update/UserGroupUpdate";
import UserGroupShow from "@/views/show/UserGroupShow";

export default {
    path: "/user-group",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "user-group-search", component: UserGroupSearch},
        {path: "create", name: "user-group-create", component: UserGroupCreate},
        {path: "edit/:id", name: "user-group-edit", props: true, component: UserGroupUpdate},
        {path: "show/:id", name: "user-group-show", props: true, component: UserGroupShow},
    ]
}