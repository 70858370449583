export default {
    icon: 'file outline',
    name: 'Contrato',
    subLinks: [
        {
            name: 'Liberar Acesso',
            route: '/release-access/search',
            pagePermissions: [
                "release-access-search",
                "release-access-create",
                "release-access-edit",
            ],
        },

        {
            name: 'Contrato',
            route: '/contract/search',
            pagePermissions: [
                "contract-search",
                "contract-create",
                "contract-edit"
            ],
        },

        {
            name: 'Modelo de Contrato',
            route: '/contract-model/search',
            pagePermissions: [
                "contract-model-search",
                "contract-model-create",
                "contract-model-edit"
            ],
        },
    ],
    pageLinksNavCheck: [],
}