import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'account-bank';

export default {

    loadBankAccounts(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_BANK_ACCOUNT", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeBankAccount(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadBankAccount(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateBankAccount(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateBankAccountPhoto(context, formData) {
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}account-bank-photo`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    }
}