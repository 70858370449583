<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.type}">
                    <label>Entrada/Saída:</label>
                    <Multiselect v-model="formData.type" :options="selectEntranceExit" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.attended}">
                    <label>Antecipado:</label>
                    <Multiselect v-model="formData.attended" :options="selectYesNo" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.bank_accounts_id}">
                    <label>Conta Bancária:</label>
                    <Multiselect label="name" v-model="formData.bank_accounts_id" :options="selectBankAccountList"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.account_types_id}">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_types_id" :options="selectAccountTypeList" :searchable="true"
                                 @select="selectAccountType(formData.account_types_id)" @clear="clearAccountType"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.account_type_sub_id}">
                    <label>Tipo de Sub Conta:</label>
                    <Multiselect :disabled="disabled" v-model="formData.account_type_sub_id"
                                 :options="selectSubAccountTypeList" :searchable="true" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.amount}">
                    <label>Quantidade:</label>
                    <input-component v-model="formData.amount" type-input="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.frequency}">
                    <label>Periodicidade:</label>
                    <Multiselect v-model="formData.frequency" :options="selectFrequency" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price}">
                    <label>Valor:</label>
                    <input-component v-money="MONEY" v-model="formData.price" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.due_date}">
                    <label>Data de Vencimento:</label>
                    <input-component v-mask="'##/##/####'" v-model="formData.due_date" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.obs}">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" :rows="5"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" :disabled="sendForm" @click="submit" name="Adicionar"
                                   icon="circle plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {VMoney} from "v-money";
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "EntranceExitPreviewCreate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("loadBankAccountList")
        this.$store.dispatch("loadAccountTypeList")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/entrance-exit-preview/search"
            },

            MONEY,
            disabled: true,

            formData: {
                type: "",
                bank_accounts_id: "",
                account_types_id: "",
                account_type_sub_id: "",
                due_date: this.currentData(),
                price: "",
                attended: 2,
                business: "",
                amount: "",
                frequency: "",
                obs: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeEntranceExitPreview', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.$router.push({name: 'entrance-exit-preview-search'})
                        toast.success("Entrada e saída criada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 28)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-preview-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        currentData() {
            const currentTime = new Date();
            const day = String(currentTime.getDate()).padStart(2, '0');
            const month = String(currentTime.getMonth() + 1).padStart(2, '0');
            const year = currentTime.getFullYear();

            return `${day}/${month}/${year}`;
        },

        selectAccountType(id) {
            if (this.formData.account_types_id !== "") {
                this.disabled = false
                this.$store.dispatch("loadSubAccountTypeList", id)
            }
        },

        clearAccountType() {
            this.disabled = true
            this.formData.account_type_sub_id = ""
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },

        selectAccountTypeList() {
            return this.$store.state.var.selectAccountTypeList
        },

        selectSubAccountTypeList() {
            return this.$store.state.var.selectSubAccountTypeList
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },

        selectFrequency() {
            return this.$store.state.var.selectFrequency
        },
    }
}
</script>