<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Carousel :breakpoints="breakpoints">
                        <Slide v-for="(data, index) in thumbs" :key="index">
                            <div class="carousel__item">
                                <input v-model="formData.layout" :value="index" type="radio" name="thumbs"
                                       :id="'thumbs' + index">
                                <label :for="'thumbs' + index">
                                    <img :src="data.thumb" alt=""/>
                                </label>
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation/>
                        </template>
                    </Carousel>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome do APP:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.app_url_id}">
                    <label>Tipo de notícias:</label>
                    <Multiselect v-model="formData.app_url_id" :options="selectNoticeList"
                                 :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time}">
                    <label>Tempo de exibição em segundos:</label>
                    <input-component v-model="formData.time" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" label="name"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Adicionar"
                                   icon="plus circle"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {Carousel, Navigation, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "NewsCreate",
    components: {
        Slide,
        Navigation,
        Carousel,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Notícias")
        this.$store.dispatch("loadNoticeList", this.params)
        this.loadStorage()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/news/search"
            },

            thumbs: [
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_noticias.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_two_noticias.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_three_noticias.jpg'},
            ],

            breakpoints: {
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },

                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },

            formData: {
                client_id: "",
                layout: "",
                name: "",
                app_url_id: "",
                time: "",
                status: "",
                company_id: "",
                data_source: 1
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeNewsApp', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Aplicativo criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'news-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.formData.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 12)
                .then((r) => {
                    if (r.data.indexOf('app-news-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectNoticeList() {
            return this.$store.state.var.selectNoticeList
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        params() {
            return {
                type: '1',
                client_id: this.idClient
            }
        }
    }
}
</script>

<style scoped>
.carousel__item {
    width: 100%;
    display: flex;
    font-size: 20px;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

input[type="radio"][id^="cb"] {
    display: none;
}

.carousel__item label {
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.carousel__item label img {
    width: 100%;
    height: auto;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.carousel__item label:before {
    content: " ";
    top: 0;
    left: 5%;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid grey;
    background-color: white;
    transition-duration: 0.4s;
}

.carousel__item input:checked + label:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.carousel__item input[type="radio"] + label::after {
    display: none;
}

.carousel__item input:checked + label img {
    z-index: -1;
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
}
</style>