<template>
    <painel-component class="main-div">
        <div role="button" @click="this.$emit('close')" class="btn-close"></div>
        <row-component>
            <row-col-component :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                <input type="radio" id="type-one" v-model="formData.type" value="1">
                <label for="type-one">Pessoa Física</label>

                <input type="radio" id="type-two" v-model="formData.type" value="2" checked>
                <label for="type-two">Pessoa Jurídica</label>
            </row-col-component>
        </row-component>

        <row-component>
            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component type-input="text" v-model="formData.name" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                               v-if="formData.type === '2'">
                <label>CNPJ:</label>
                <input-component type-input="text" v-model="formData.cnpj" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                <label>CPF:</label>
                <input-component type-input="text" v-model="formData.cpf" :type="'text'"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                <button-submit @submit="submit" icon="search"/>
            </row-col-component>
        </row-component>

        <div class="mt-4">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-for="(data, index) in object.data" :key="index">
                    <div class="d-flex align-items-center bg-body-secondary p-2 position-relative">
                        <div class="avatar__image__div">
                            <img class="avatar__image" v-if="data.photo" :src="data.photo" alt="">
                            <img class="avatar__image" v-else src="@/assets/avatar.png" alt="">
                        </div>
                        <div class="ms-3 w-50">
                            <p class="mb-1 text-truncate">{{ data.name }}</p>
                            <p class="mb-1">{{ data.cnpj }} {{ data.cpf }}</p>
                        </div>
                        <div class="position-absolute end-0 me-1 me-lg-3">
                            <button-submit @click="saveClient(data)" icon="plus icon"/>
                        </div>
                    </div>
                </row-col-component>
            </row-component>

            <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
        </div>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "SelectClient",
    components: {
        PainelComponent,
        RowComponent,
        RowColComponent,
        InputComponent,
        ButtonSubmit,
        ButtonPagination
    },

    created() {
        this.loadItems()
    },

    data() {
        return {
            formData: {
                type: "2",
                name: "",
                cnpj: "",
                cpf: ""
            },

            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadSelectClient", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        saveClient(data) {
            this.$store.commit("LOADER", true)
            localStorage.setItem("client", JSON.stringify(data))
            localStorage.setItem("isClient", "true")
            this.$emit("load")
            this.$emit("close")
            this.$store.commit("IS_CLIENT", true)
            this.$store.dispatch("releaseAccessGetClientId", data.id)
                .then((r) => {
                    this.$store.commit("ID_CLIENT", r)
                    localStorage.setItem("id_client", r)
                })
            setTimeout(() => {
                this.$store.commit("LOADER", false)
            }, 700)

            this.routerBack()
        },

        routerBack() {
            let route = this.$route.path

            if (route.indexOf("create") !== -1) {
                let newRoute = route.replace("create", "search")
                this.$router.push({path: newRoute})
            }

            if (route.indexOf("edit") !== -1) {
                let newRoute = route.substr(0, route.indexOf("edit"))
                this.$router.push({path: `${newRoute}search`})
            }

            if (route.indexOf("show") !== -1) {
                let newRoute = route.substr(0, route.indexOf("show"))
                this.$router.push({path: `${newRoute}search`})
            }
        }
    },

    computed: {
        isClient() {
            return this.$store.state.var.isClient
        },

        object() {
            return this.$store.state.client.items_client
        },

        params() {
            return {
                page: this.object.current_page,
                type: this.formData.type,
                name: this.formData.name,
                cnpj: this.formData.cnpj,
                cpf: this.formData.cpf
            }
        }
    }
}
</script>

<style scoped>
.avatar__image__div {
    max-width: 50px;
}

.btn-close {
    top: 15px;
    right: 15px;
    position: absolute;
}

@media (max-width: 991px) {
    .main-div{
        overflow: auto;
        min-height: 800px;
    }
}

@media (max-width: 375px) {
    .main-div {
        width: 95%;
    }
}

@media (max-width: 321px) {
    .main-div {
        width: 80%;
    }
}
</style>