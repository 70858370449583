export default {
    LOAD_USER_SYSTEM(state, user_system) {
        state.items = user_system
    },

    LOAD_USER_SYSTEM_GROUP(state, items) {
        state.list_group = items
    },

    LOAD_USER_SYSTEM_COMPANY(state, items) {
        state.list_companies = items
    }
}