import MainComponent from "@/components/dashboard/MainComponent";

import ContractSearch from "@/views/search/ContractSearch";
import ContractCreate from "@/views/create/ContractCreate";
import ContractUpdate from "@/views/update/ContractUpdate";

export default {
    path: "/contract",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "contract-search", component: ContractSearch},
        {path: "create", name: "contract-create", component: ContractCreate},
        {path: "edit/:id", name: "contract-edit", component: ContractUpdate, props: true},
    ]
}