import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'video-tutorial';

export default {

    loadTutorials(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_TUTORIAL", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeTutorial(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateTutorial(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyTutorial(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_ADMIN}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateOrderTutorial(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}video-tutorial-menu-order`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    storeTutorialVideo(context, formData) {
        context.commit("LOADER", true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}video-tutorial-sub-menu-add`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateTutorialVideo(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}video-tutorial-sub-menu-edit/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyTutorialVideo(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_ADMIN}video-tutorial-sub-menu-delete/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateOrderTutorialVideo(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}video-tutorial-sub-menu-order`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    storeVideoTutorialVideo(context, formData) {
        context.commit("LOADER", true)
        let config = {headers: {'Content-Type': 'multipart/form-data'}}
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}video-tutorial-sub-menu-update-file`, formData, config)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },
}