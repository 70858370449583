<template>
    <div class="modal-window" v-if="option || content || application || player || preview">
        <div class="option" v-if="option">
            <div @click="option = false" role="button" class="btn-close"></div>

            <div role="button" @click="optionsModal(2)"
                 v-if="buttonCheck.application">
                <i class="fs-1 cogs icon"></i>
                Aplicativo
            </div>

            <div role="button" @click="optionsModal(1)"
                 v-if="buttonCheck.files">
                <i class="fs-1 file image icon"></i>
                Conteúdo
            </div>
        </div>

        <CampaignContent v-if="content" :id="this.id" @close="contentModal"/>

        <CampaignApp v-if="application" :id="this.id" @close="application = false"/>

        <CampaignPlayer v-if="player" :id="this.id" @close="player = false"/>

        <div class="preview" v-if="preview">
            <div @click="preview = false"
                 class="p-2 rounded-3 bg-body-secondary position-absolute top-0 end-0 m-3">
                <div class="btn-close" role="button"></div>
            </div>

            <img v-if="img_video_on" :src="pre_img_video" alt="">
            <video v-if="!img_video_on" controls autoplay loop>
                <source :src="pre_img_video">
            </video>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <div class="clearfix" v-if="buttonCheck.create">
            <div class="float-end">
                <button-route class="me-2" :items="itemsCreate"/>
                <button-submit v-if="buttonCheck.player" @click="player = true" icon="play"/>
            </div>
        </div>

        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.name}">
                    <label>Nome da Campanha:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError':errors.layout}">
                    <label>Layout:</label>
                    <Multiselect v-model="formData.layout" label="name" :options="selectLayoutList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noResultsText">
                        <template v-slot:singlelabel="{ value }">
                            <div class="multiselect-single-label">
                                <img class="character-label-icon" :src="value.icon" alt=""> {{ value.name }}
                            </div>
                        </template>

                        <template v-slot:option="{ option }">
                            <img class="character-option-icon" :src="option.icon" alt=""> {{ option.name }}
                        </template>
                    </Multiselect>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" :disabled="sendForm" @click="submit" icon="save"
                                   name="Salvar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>

            <hr class="mb-0">
            <h5 class="text-uppercase">Adicionar campanha</h5>

            <div class="d-flex justify-content-between">
                <button-submit v-if="buttonCheck.files && buttonCheck.application" @click="optionsModal" icon="plus"/>

                <div>
                    <button-delete @click="removeButton" :class="{'opacity-50' : !remove}"/>
                    <button-submit v-if="buttonCheck.sync" @click="submitSynchronize" icon="sync alternate"/>
                </div>
            </div>

            <div class="draggable">
                <draggable v-model="formData.files_apps" group="people" @start="drag=true" @end="drag=false"
                           item-key="id">
                    <template #item="{element, index}">
                        <div class="campaign-items bg-body-secondary" :data-count="index + 1">
                            <div class="d-flex align-items-center w-50">
                                <i v-if="parseInt(element.type) === 1" class="fs-3 file image icon"></i>
                                <i v-else-if="parseInt(element.type) === 2" class="fs-3 video icon"></i>
                                <i v-else class="fs-3 cogs icon"></i>
                                <p class="text-truncate w-100 mb-0">{{ element.name }}</p>
                            </div>

                            <div class="status" :class="[remove ? 'd-none' : 'd-flex']">
                                <div>
                                    <i :class="{'double-a' : element.double === '2'}"
                                       class="mx-2 text-black-50 clone icon"></i>
                                </div>
                                <div>
                                    <i :class="{'sync-a' : element.synced === '2'}"
                                       class="mx-2 text-black-50 sync icon"></i>
                                </div>
                                <div @click="openAction(index)" id="arrow">
                                    <i class="mx-2 chevron down icon"></i>
                                </div>
                            </div>

                            <div class="action d-none">
                                <button type="button">
                                    <i class="info circle icon"></i>
                                </button>

                                <button type="button" @click="submitDuplicate(element.id, index)">
                                    <i class="clone outline icon"></i>
                                </button>

                                <button type="button" @click="previewItem(element)">
                                    <i class="eye icon"></i>
                                </button>
                            </div>

                            <div v-if="remove" class="action end-0">
                                <button type="button" @click="deleteItems(index)">
                                    <i :class="{'text-danger' :element.delete === 2}"
                                       class="text-secondary trash alternate outline icon"></i>
                                </button>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import CampaignContent from "@/views/search/CampaignContent";
import CampaignApp from "@/views/search/CampaignApp";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import draggable from "vuedraggable";
import {useToast} from "vue-toastification";
import CampaignPlayer from "@/views/search/CampaignPlayer.vue";

const toast = useToast()
export default {
    name: "CampaignUpdate",
    components: {
        CampaignPlayer,
        draggable,
        CampaignApp,
        ButtonDelete,
        CampaignContent,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Campanha")
        this.checkACL()
        this.loadStorage()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                title: "Editar",
                icon: "edit"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/campaign/search",
            },

            itemsCreate: {
                icon: "plus",
                route: "/campaign/create",
            },

            buttonCheck: {
                sync: false,
                files: false,
                player: false,
                create: false,
                application: false,
            },

            formData: {
                client_id: "",
                name: "",
                company_id: "",
                layout: "",
                files_apps: []
            },

            params: {
                id: this.id,
                client_id: ""
            },

            drag: "",
            remove: false,

            option: false,
            player: false,
            content: false,
            application: false,

            preview: false,
            pre_img_video: "",
            img_video_on: true,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateCampaign', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Campanha atualizada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadItems() {
            this.$store.dispatch('loadCampaign', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.idClient
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadLayoutList", localStorage.getItem("id_client"))
            this.params.client_id = localStorage.getItem("id_client")
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 23)
                .then((r) => {
                    if (r.data.indexOf('campaing-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('campaing-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('campaing-add-player') !== -1) {
                            this.buttonCheck.player = true
                        }

                        if (r.data.indexOf('campaing-add-files') !== -1) {
                            this.buttonCheck.files = true
                        }

                        if (r.data.indexOf('campaing-add-app') !== -1) {
                            this.buttonCheck.application = true
                        }

                        if (r.data.indexOf('campaing-synchronize') !== -1) {
                            this.buttonCheck.sync = true
                        }

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        optionsModal(options) {
            this.option = true

            if (options === 1) {
                this.content = true
                this.option = false
            }

            if (options === 2) {
                this.application = true
                this.option = false
            }
        },

        openAction(index) {
            let arrow = document.querySelectorAll("#arrow")
            let action = document.querySelectorAll(".action")

            if (arrow[index].classList.contains("open")) {
                arrow[index].classList.remove("open")
                action[index].classList.add("d-none")

            } else {
                arrow[index].classList.add("open")
                action[index].classList.remove("d-none")
            }
        },

        submitSynchronize() {
            let formData = {
                campaign_id: this.id,
                client_id: this.idClient,
                files_apps_synchronize: []
            }

            this.formData.files_apps.forEach((_element) => {
                formData.files_apps_synchronize.push({
                    id: _element.id,
                    delete: _element.delete
                })
            })

            this.$store.dispatch('synchronizeCampaign', formData)
                .then(() => {
                    this.loadItems()
                    toast.success("Campanha sincronizada com sucesso", {timeout: 1200});
                }).catch(() => {
                window.scrollTo(0, 0)
                toast.error("Erro desconhecido!", {timeout: 1200});
            })
        },

        submitDuplicate(id, index) {
            let params = {
                campaign_id: this.id,
                app_file_id: id,
                client_id: this.idClient
            }

            this.$store.dispatch('duplicateCampaignFilesApps', {...params})
                .then(() => {
                    this.loadItems()
                    this.openAction(index)
                    toast.success("Item duplicado com sucesso", {timeout: 1200});
                }).catch(() => {
                window.scrollTo(0, 0)
                toast.error("Erro desconhecido!", {timeout: 1200});
            })
        },

        deleteItems(index) {
            let status = this.formData.files_apps[index]
            if (status.delete === 1)
                return status.delete = 2
            return status.delete = 1
        },

        removeButton() {
            this.remove = !this.remove
            this.formData.files_apps.forEach((el) => {
                el.delete = 1
            })
        },

        previewItem(data) {
            this.pre_img_video = data.show_url
            this.preview = true

            if (parseInt(data.type) === 2)
                this.img_video_on = false

            if (parseInt(data.type) === 3) {
                this.preview = false
                window.open(data.show_url, "_blank")
            }
        },

        contentModal(){
            this.content = false
            this.loadItems()
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectLayoutList() {
            return this.$store.state.var.selectLayoutList
        },
    }
}
</script>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 35;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-window .option {
    width: 25%;
    height: 30%;
    display: flex;
    position: relative;
    background: #dddddd;
    align-items: center;
    justify-content: space-evenly;
}

.option .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.option div:not(.btn-close) {
    width: 35%;
    height: 70%;
    color: #000;
    display: grid;
    place-items: center;
    border: 1px solid black;
    background-color: #fff;
    text-transform: uppercase;
}

.campaign-items {
    width: 49.6%;
    height: 55px;
    margin-top: 1rem;
    position: relative;
    align-items: center;
    display: inline-flex;
    padding-left: 0.5rem;
    justify-content: space-between;
}

.campaign-items:before {
    content: attr(data-count);
    top: -5px;
    left: -5px;
    width: 18px;
    height: 18px;
    display: grid;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    position: absolute;
    place-items: center;
    background: #F25C05;
}

.campaign-items i {
    line-height: 30px;
    margin-right: 0.5rem;
}

.draggable > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.campaign-items .status div {
    width: 55px;
    height: 100%;
    text-align: center;
}

.status .open {
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
}

.status .double-a {
    color: #2078FA !important;
}

.status .sync-a {
    color: #30BB3B !important;
}

.preview > img,
.preview > video {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    transition: -webkit-transform 0.3s ease-in-out;
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
}

.campaign-items .action {
    right: 55px;
    z-index: 25;
    height: 100%;
    position: absolute;
}

.campaign-items .action button {
    border: 0;
    width: 55px;
    height: 100%;
}

.campaign-items .action button i {
    margin: 0;
}

.campaign-items .action button:hover {
    background: #dddddd;
}

@media (max-width: 1024px) {
    .modal-window .option {
        width: 45%;
        height: 35%;
    }

    .option div:not(.btn-close) {
        width: 40%;
    }

    .campaign-items {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .modal-window .option {
        width: 55%;
        height: 40%;
    }

    .option div:not(.btn-close) {
        width: 45%;
    }
}

@media (max-width: 430px) {
    .modal-window .option {
        width: 95%;
        height: 35%;
    }
}
</style>