import MainComponent from "@/components/dashboard/MainComponent";

import ClientSearch from "@/views/search/ClientSearch";
import ClientCreate from "@/views/create/ClientCreate";
import ClientUpdate from "@/views/update/ClientUpdate";
import ClientShow from "@/views/show/ClientShow";
import ClientUpdatePhoto from "@/views/update/ClientUpdatePhoto";

export default {
    path: "/client",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "client-search", component: ClientSearch},
        {path: "create", name: "client-create", component: ClientCreate},
        {path: "edit/:id", name: "client-edit", props: true, component: ClientUpdate},
        {path: "show/:id", name: "client-show", props: true, component: ClientShow},
        {path: "photo/:id", name: "client-photo", props: true, component: ClientUpdatePhoto},
    ]
}