import axios from "axios";
import {URL_BASE_SYSTEM} from "@/configs/constants";

const RESOURCE = 'app/menu-table';

export default {
    loadMenuTablesApps(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_MENU_TABLE_APP", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeMenuTableApp(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadMenuTableApp(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}/${params.id}`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateMenuTableApp(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyMenuTableApp(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_SYSTEM}${RESOURCE}/${params.id}`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadMenuTablesAppsContents(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/menu-table-contents`, {params})
                .then((response) => {
                    context.commit("LOAD_MENU_TABLE_APP_CONTENTS", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadMenuTablesAppsFiles(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/menu-table-files`, {params})
                .then((response) => {
                    context.commit("LOAD_MENU_TABLE_APP_FILES", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeMenuTableAppFile(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}app/menu-table-add-file`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadMenuTablesAppsItems(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/menu-table-itens`, {params})
                .then((response) => {
                    context.commit("LOAD_MENU_TABLE_APP_ITEMS", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeMenuTableAppItems(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}app/menu-table-add-item`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateMenuTableAppItems(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}app/menu-table-item-update`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyMenuTableAppItems(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_SYSTEM}app/menu-table-item-delete/${formData.id}/${formData.id_item}?client_id=${formData.client_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

}