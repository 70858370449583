<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>CPF:</label>
                    <input-component v-mask="'###.###.###-##'" type-input="text" v-model="formData.cpf"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-xxl-4">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-route :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 35%">Nome</th>
                <th scope="col" style="width: 20%">CPF</th>
                <th scope="col" style="width: 20%">RG</th>
                <th scope="col" style="width: 15%">Status</th>
                <th scope="col" style="width: 20%" v-if="buttonCheck.edit || buttonCheck.show">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Nome">
                    <img v-if="data.photo" :src="data.photo" class="avatar__image_2" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image_2" alt="">
                    {{ data.name }}
                </td>
                <td data-label="CPF">{{ data.cpf }}</td>
                <td data-label="RG">{{ data.rg }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="employee-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="employee-show"/>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: "EmployeeSearch",
    components: {
        ButtonPagination,
        ButtonEdit,
        ButtonShow,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch("changeNamePage", "Funcionário")
        this.loadItems(1)
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisa"
            },

            itemsTwo: {
                icon: "plus",
                route: "/employee/create"
            },

            buttonCheck: {
                create: false,
                update: false,
                show: false
            },

            formData: {
                name: "",
                cpf: ""
            },

            showLoader: false,
            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadEmployees", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 4)
                .then((r) => {
                    if (r.data.indexOf('employee-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('employee-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('employee-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('employee-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        object() {
            return this.$store.state.employee.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                cpf: this.formData.cpf,
            }
        }
    }
}
</script>