<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.client_id}">
                    <label>Cliente:</label>
                    <Multiselect label="name" v-model="formData.client_id" :searchable="true"
                                 @select="loadNameClient(formData.client_id)"
                                 :options="selectClientList" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.logs}">
                    <label>Logs:</label>
                    <Multiselect v-model="formData.logs" :searchable="true" :options="selectYesNo"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.quant_user}">
                    <label>Quantidade de Usuários:</label>
                    <input-component type-input="number" v-model="formData.quant_user"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.quant_storage}">
                    <label>Quantidade GB:</label>
                    <input-component type-input="number" v-model="formData.quant_storage"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.quant_player}">
                    <label>Quantidade Player:</label>
                    <input-component type-input="number" v-model="formData.quant_player"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.compress_img}">
                    <label>Compactar Imagem:</label>
                    <Multiselect v-model="formData.compress_img" :searchable="true" :options="selectYesNo"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.compress_video}">
                    <label>Compactar Video:</label>
                    <Multiselect v-model="formData.compress_video" :searchable="true" :options="selectYesNo"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time_img}">
                    <label>Tempo de imagem (Segundos):</label>
                    <input-component type-input="number" v-model="formData.time_img"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time_img_trustee}">
                    <label>Tempo de imagem (Sindico):</label>
                    <input-component type-input="number" v-model="formData.time_img_trustee"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time_html_trustee}">
                    <label>Tempo HTML (Sindico):</label>
                    <input-component type-input="number" v-model="formData.time_html_trustee"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Adicionar"
                                   icon="plus circle"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ReleaseAccessCreate",
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Liberar Acesso")
        this.$store.dispatch("loadClientList")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/release-access/search"
            },

            formData: {
                client_id: "",
                name: "",
                time_img: "",
                time_img_trustee: "",
                time_html_trustee: "",
                quant_user: "",
                quant_player: "",
                quant_storage: "",
                compress_img: "",
                compress_video: "",
                status: "",
                logs: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeReleasesAccess', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'release-access-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 8)
                .then((r) => {
                    if (r.data.indexOf('release-access-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadNameClient(id) {
            this.$store.dispatch("loadClient", id).then(r => {
                this.formData.name = r.name
            })
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },
    }
}
</script>