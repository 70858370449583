<template>
    <painel-component class="shadow-none painel">
        <div @click="$emit('close')" class="btn-close" role="button"></div>

        <error-component v-if="showError" :errors="errors"/>

        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.spot_id}">
                    <label>Local:</label>
                    <Multiselect label="name" v-model="formData.spot_id" :options="selectSpotList"
                                 @select="loadContentFolderSpotList(formData.spot_id)"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.content_folder_id}">
                    <label>Conteúdo:</label>
                    <Multiselect v-if="!isEdit" v-model="formData.content_folder_id" :searchable="true"
                                 :options="selectContentFolderSpotList" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :disabled="disabled"/>

                    <Multiselect v-else v-model="formData.content_folder_id" :searchable="true" disabled
                                 :options="selectContentFolderSpotList"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.imagem}">
                    <label>Permitir imagem:</label>
                    <Multiselect v-model="formData.imagem" :options="selectYesNo"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.video}">
                    <label>Permitir vídeo:</label>
                    <Multiselect v-model="formData.video" :options="selectYesNo"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time_img}">
                    <label>Tempo exibição imagem(Segundos):</label>
                    <input-component v-model="formData.time_img" type-input="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.limit_files}">
                    <label>Quantidade de arquivos permitidos:</label>
                    <input-component v-model="formData.limit_files" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="!isEdit" @click="submit" :disabled="sendForm" icon="plus circle"
                                   name="Adicionar"/>

                    <button-submit v-else @click="update" :disabled="sendForm" icon="save" name="Salvar"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CampaignShare",
    components: {
        ErrorComponent,
        ButtonSubmit,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        },

        shareId: {
            required: true
        },

        isEdit: {
            required: true
        }
    },

    created() {
        this.loadStorage()
    },

    data() {
        return {

            formData: {
                client_id: "",
                campaign_id: this.id,
                spot_id: "",
                limit_files: "",
                imagem: "",
                time_img: "",
                video: "",
                content_folder_id: ""
            },

            disabled: true,

            errors: [],
            sendForm: false,
            showError: false,
        }
    },

    methods: {

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeShareCampaign', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro criado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        update() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateShareCampaign', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadStorage() {
            this.$store.dispatch("loadSpotList", localStorage.getItem("id_client"))
            this.formData.client_id = localStorage.getItem("id_client")
            this.loadItems()
        },

        loadItems() {
            if (this.isEdit) {
                let params = {
                    id: this.shareId,
                    client_id: localStorage.getItem("id_client")
                }

                this.$store.dispatch("loadShareCampaign", params)
                    .then((response) => {
                        this.formData = response
                        this.formData.id = response.id
                        this.formData.client_id = localStorage.getItem("id_client")
                        this.loadContentFolderSpotList(response.spot_id)
                    }).catch(() => {
                    toast.error("Erro desconhecido!")
                })
            }
        },

        loadContentFolderSpotList(id) {
            let formData = {
                id: id,
                client_id: localStorage.getItem("id_client")
            }

            this.$store.dispatch("loadContentFolderSpotList", formData)
                .then(() => {
                    this.disabled = false
                })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectContentFolderSpotList() {
            return this.$store.state.var.selectContentFolderSpotList
        },

        selectSpotList() {
            return this.$store.state.var.selectSpotList
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },
    }
}
</script>

<style scoped>

.painel {
    width: 70%;
    height: auto;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 1440px) {
    .painel {
        width: 85%;
    }
}

@media (max-width: 1024px) {
    .painel {
        width: 98%;
    }
}

</style>
