<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-5 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-xxl-4 mt-0">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 30%">Nome</th>
                <th scope="col" style="width: 35%">Status</th>
                <th scope="col" style="width: 15%" v-if="buttonCheck.edit || buttonCheck.show">Ações</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="release-access-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="release-access-show"/>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>

    <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: "ReleaseAccessSearch",
    components: {
        ButtonPagination,
        ButtonEdit,
        ButtonShow,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Liberar Acesso")
        this.loadItems(1)
        this.checkACL()

    },

    data() {
        return {
            itemsOne: {
                icon: "list alternate",
                title: "Pesquisa"
            },

            itemsTwo: {
                icon: "plus",
                route: "/release-access/create",
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false
            },

            formData: {
                name: '',
                status: ''
            },

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadReleasesAccess", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 8)
                .then((r) => {
                    if (r.data.indexOf('release-access-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('release-access-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('release-access-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('release-access-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        object() {
            return this.$store.state.release_access.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                status: this.formData.status,
            }
        }
    }
}
</script>