<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <input disabled type="radio" id="client" v-model="formData.client_employee_provider_type" value="1">
                    <label for="client">Cliente</label>

                    <input disabled type="radio" id="employee" v-model="formData.client_employee_provider_type"
                           value="2">
                    <label for="employee">Funcionário</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo:</label>
                    <Multiselect disabled v-model="formData.type" :options="selectTypeReceipt" :searchable="true"
                                 :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   v-if="parseInt(formData.client_employee_provider_type) === 1">
                    <label>Cliente:</label>
                    <Multiselect disabled label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectClientList" :searchable="true" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   v-if="parseInt(formData.client_employee_provider_type) === 2">
                    <label>Funcionário:</label>
                    <Multiselect disabled label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectEmployeeList" :searchable="true" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Título:</label>
                    <input-component disabled v-model="formData.title" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Data:</label>
                    <input-component disabled v-model="formData.date" v-mask="'##/##/####'" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Valor:</label>
                    <input-component disabled v-model="formData.price" v-money="MONEY" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Pago:</label>
                    <input-component disabled v-model="formData.paid" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Referente:</label>
                    <text-area disabled v-model="formData.referent" style="height: 70px;"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {mask} from "vue-the-mask"
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ReceiptUpdate",
    components: {
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("loadClientList")
        this.$store.dispatch("loadEmployeeList")
        this.$store.dispatch("changeNamePage", "Recibo")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/receipt/search"
            },

            MONEY,

            formData: {
                type: "",
                title: "",
                client_employee_provider_type: "",
                client_employee_provider_id: "",
                date: "",
                price: "",
                paid: "",
                referent: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadReceipt', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 30)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        this.showLoader = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployeeList() {
            return this.$store.state.var.selectEmployeeList
        },

        selectEmployeeBankList() {
            return this.$store.state.var.selectEmployeeBankList
        },
    }
}
</script>