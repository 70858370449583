import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'product-entrance-and-exit';

export default {
    loadInventories(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_INVENTORY", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeInventoryAdd(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}product-entrance-and-exit-add`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeInventoryRemove(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}product-entrance-and-exit-remove`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },
}