<template>
    <button :type="type" :class="['btn button-submit']">
        <i v-if="icon" :class="'icon ' + icon"></i>
        {{ name }}
    </button>
</template>

<script>
export default {
    name: "ButtonSubmit",

    props: {
        icon: {
            "required": false,
            "type": String,
            "default": ''
        },

        name: {
            "type": String,
            "default": ''
        },

        type: {
            "type": String,
            "default": "button"
        }
    },
}
</script>

<style scoped>
.button-submit {
    font-family: 'Comfortaa', cursive;
    color: white;
    height: 42px;
    min-width: 42px;
    padding: 0.4rem;
    font-size: 18px;
    text-align: center;
    border-radius: 0;
    text-decoration: none;
    border: 1px solid #e0e0e0;
    background-color: #F25C05;
}

.button-submit i.icon {
    margin: 0;
}

.button-submit:active,
.button-submit:focus,
.button-submit:hover,
.button-submit:focus-visible {
    font-family: 'Comfortaa', cursive;
    color: white;
    border-color: #F25C05;
    background-color: #F25C05;
    box-shadow: none !important;
}
</style>