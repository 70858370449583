<template>
    <painel-component>
        <div class="alert alert-warning" role="alert" v-if="!isClient">
            <i class="exclamation triangle icon"></i>
            Selecione um cliente
        </div>

        <row-component v-else>
            <row-col-component v-if="newsApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'news-search' }">
                    <div class="card-apps position-relative">
                        <div class="card-apps-top">
                            Notícias
                            <i class="newspaper icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="weatherApp"
                               :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'weather-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            Clima
                            <i class="sun outline icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="lotteryApp"
                               :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'lottery-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            Loteria
                            <i class="dollar sign icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="clockApp" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'clock-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            Relógio
                            <i class="clock outline icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="menuTableApp"
                               :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'menu-table-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            Menu Tabela
                            <i class="table icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="youtubeApp"
                               :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'youtube-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            Youtube
                            <i class="youtube icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>

            <row-col-component v-if="appWeb" :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12'">
                <router-link :to="{ name: 'web-search' }">
                    <div class="card-apps">
                        <div class="card-apps-top">
                            App Web
                            <i class="globe icon"></i>
                        </div>
                        <i class="arrow right icon card-apps-arrow"></i>
                        <div class="card-apps-bottom">
                            <img title="Horizontal" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/horizontal.png" alt="">
                            <img title="Vertical" class="img-fluid"
                                 src="https://methoduz.s3.amazonaws.com/system/vertical.png" alt="">
                        </div>
                    </div>
                </router-link>
            </row-col-component>
        </row-component>
    </painel-component>


</template>

<script>
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import PainelComponent from "@/components/dashboard/PainelComponent.vue";

export default {
    created() {
        this.$store.dispatch('changeNamePage', 'Aplicativo')
        // this.ACLApps()
        this.loadStorage()
    },

    name: 'AppSearch',

    components: {
        PainelComponent,
        RowComponent,
        RowColComponent
    },

    data() {
        return {
            weatherApp: true,
            newsApp: true,
            lotteryApp: true,
            clockApp: true,
            youtubeApp: true,
            menuTableApp: true,
            appWeb: true,
            appAvisos: true,
            appObituary: true
        }
    },

    methods: {
        loadStorage() {
            this.$store.commit("IS_CLIENT", this.convertBool(localStorage.getItem("isClient")))
        },

        convertBool(string) {
            return string === "true";
        },

        async ACLApps() {
            this.$store.dispatch('ACLApps')
                .then((r) => {

                    if (r.data.length > 1) {

                        if (r.data.indexOf('app-clima-search') !== -1) {
                            this.weatherApp = true
                        }

                        if (r.data.indexOf('app-news-search') !== -1) {
                            this.newsApp = true
                        }

                        if (r.data.indexOf('app-lottery-search') !== -1) {
                            this.lotteryApp = true
                        }

                        if (r.data.indexOf('app-clock-search') !== -1) {
                            this.clockApp = true
                        }

                        if (r.data.indexOf('app-menu-table-search') !== -1) {
                            this.menuTableApp = true
                        }

                        if (r.data.indexOf('app-youtube-search') !== -1) {
                            this.youtubeApp = true
                        }

                        if (r.data.indexOf('app-web-url-search') !== -1) {
                            this.appWeb = true
                        }

                        if (r.data.indexOf('app-notice-elevator-search') !== -1) {
                            this.appAvisos = true
                        }

                        if (r.data.indexOf('app-obituary-room-search') !== -1) {
                            this.appObituary = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        }
    },

    computed: {
        isClient() {
            return this.$store.state.var.isClient
        },
    }
}
</script>

<style scoped>
.painel-component {
    margin: 0;
    box-shadow: none;
    background-color: transparent;
}

a .card-apps:hover {
    transform: perspective(1px) translateZ(0);
    box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
}

.card-apps {
    width: 80%;
    color: #000;
    height: 105px;
    position: relative;
    background-color: #fff;
    transition-duration: 0.3s;
    transition-property: box-shadow;
    transition: background-color 1s ease 0s;
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}

.card-apps-top {
    top: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    display: flex;
    padding: 10px;
    position: absolute;
    justify-content: space-between;
}

.card-apps-bottom {
    left: 0;
    bottom: 0;
    width: 25%;
    display: flex;
    padding: 10px;
    height: 2.5rem;
    position: absolute;
    justify-content: space-between;
}

.card-apps-arrow {
    right: 0;
    bottom: 0;
    margin: 12px;
    position: absolute;
    filter: invert(48%) sepia(74%) saturate(522%) hue-rotate(350deg) brightness(96%) contrast(93%);
}

@media (max-width: 1280px) {
    .card-apps {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .card-apps {
        margin: 0 auto;
    }
}

@media (max-width: 661px) {
    .card-apps {
        width: 100%;
    }
}
</style>

