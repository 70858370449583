import MainComponent from "@/components/dashboard/MainComponent";

import InventorySearch from "@/views/search/InventorySearch";

export default {
    path: "/inventory",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "inventory-search", component: InventorySearch}
    ]
}