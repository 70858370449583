import MainComponent from "@/components/dashboard/MainComponent";

import SpotSearch from "@/views/search/SpotSearch";
import SpotCreate from "@/views/create/SpotCreate";
import SpotUpdate from "@/views/update/SpotUpdate";
import SpotShow from "@/views/show/SpotShow";

export default {
    path: "/spot",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "spot-search", component: SpotSearch},
        {path: "create", name: "spot-create", component: SpotCreate},
        {path: "edit/:id", name: "spot-edit", props: true, component: SpotUpdate},
        {path: "show/:id", name: "spot-show", props: true, component: SpotShow},
    ]
}