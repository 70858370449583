import MainComponent from "@/components/dashboard/MainComponent";

import CampaignSearch from "@/views/search/CampaignSearch";
import CampaignCreate from "@/views/create/CampaignCreate";
import CampaignUpdate from "@/views/update/CampaignUpdate";

export default {
    path: "/campaign",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "campaign-search", component: CampaignSearch},
        {path: "create", name: "campaign-create", component: CampaignCreate},
        {path: "edit/:id", name: "campaign-edit", props: true, component: CampaignUpdate}
    ]
}