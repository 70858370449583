<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>

            <table class="table table-striped" v-if="showLoader">
                <thead>
                <tr>
                    <th scope="col" style="width: 20%">Nome</th>
                    <th scope="col" style="width: 20%">Empresa</th>
                    <th scope="col" style="width: 20%">Status</th>
                    <th scope="col" style="width: 20%">Duração</th>
                    <th scope="col" style="width: 20%"
                        v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.show">
                        Ações
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(data, index) in object.data" :key="index">
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Empresa">{{ data.company_name }}</td>
                    <td data-label="Status">{{ statusString(data.status) }}</td>
                    <td data-label="Duração">{{ data.time }}</td>
                    <td data-label="Ações" v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.show">
                        <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                        <button-edit v-if="buttonCheck.edit" :id="data.id" route="lottery-edit"/>
                        <button-show v-if="buttonCheck.show" :id="data.id" route="lottery-show"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>

        <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "LotterySearch",
    components: {
        ButtonPagination,
        ButtonShow,
        ButtonDelete,
        ButtonEdit,
        ButtonSubmit,
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Loteria")
        this.checkACL()
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/lottery/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                show: false
            },

            formData: {
                name: "",
                status: "",
                company_id: "",
                client_id: ""
            },

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadLotteryApps", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroyLotteryApp', params).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 13)
                .then((r) => {
                    if (r.data.indexOf('app-lottery-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('app-lottery-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('app-lottery-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('app-lottery-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('app-lottery-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        object() {
            return this.$store.state.lottery.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                status: this.formData.status,
                client_id: this.idClient
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>