import {createStore} from 'vuex'

import AccountType from "@/store/modules/account-type";
import BankAccount from "@/store/modules/bank-account";
import Auth from "@/store/modules/auth";
import Campaign from "@/store/modules/campaign";
import Client from "@/store/modules/client";
import Clock from "@/store/modules/clock";
import Company from "@/store/modules/company";
import Content from "@/store/modules/content";
import Contract from "@/store/modules/contract";
import ContractModel from "@/store/modules/contract-model";
import Employee from "@/store/modules/employee";
import EntranceExit from "@/store/modules/entrance-exit";
import EntranceExitPreview from "@/store/modules/entrance-exit-preview";
import ExternalApi from "@/store/modules/external-api";
import File from "@/store/modules/file";
import Home from "@/store/modules/home";
import Inventory from "@/store/modules/inventory";
import Loader from "@/store/modules/loader";
import Local from "@/store/modules/local";
import Lottery from "@/store/modules/lottery";
import MenuTable from "@/store/modules/menu-table";
import News from "@/store/modules/news";
import Player from "@/store/modules/player";
import Product from "@/store/modules/product";
import Receipt from "@/store/modules/receipt";
import ReleaseAccess from "@/store/modules/release-access";
import Spot from "@/store/modules/spot";
import Tutorial from "@/store/modules/tutorial";
import User from "@/store/modules/user";
import UserGroup from "@/store/modules/user-group";
import UserSystem from "@/store/modules/user-system";
import Var from "@/store/modules/var";
import Weather from "@/store/modules/weather";
import Web from "@/store/modules/web";
import Youtube from "@/store/modules/youtube";

export default createStore({
    modules: {
        account_type: AccountType,
        auth: Auth,
        bank_account: BankAccount,
        campaign: Campaign,
        client: Client,
        clock: Clock,
        company: Company,
        content: Content,
        contract: Contract,
        contract_model: ContractModel,
        employee: Employee,
        entrance_exit: EntranceExit,
        entrance_exit_preview: EntranceExitPreview,
        external_api: ExternalApi,
        file: File,
        home: Home,
        inventory: Inventory,
        loader: Loader,
        local: Local,
        lottery: Lottery,
        menu_table: MenuTable,
        news: News,
        player: Player,
        product: Product,
        receipt: Receipt,
        release_access: ReleaseAccess,
        spot: Spot,
        tutorial: Tutorial,
        user: User,
        user_group: UserGroup,
        user_system: UserSystem,
        var: Var,
        weather: Weather,
        web: Web,
        youtube: Youtube
    }
})
