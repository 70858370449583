import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'contract-model';

export default {

    loadContractTemplates(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_CONTRACT_MODEL", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeContractModel(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadContractModel(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateContractModel(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeContractModelItem(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}contract-model-add-item`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateContractModelItem(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}contract-model-add-item-edit/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyContractModelItem(context, id) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_ADMIN}contract-model-add-item-delete/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateContractModelOrder(context, formData) {
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_ADMIN}contract-model-add-item-order/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },
}