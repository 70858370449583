import MainComponent from "@/components/dashboard/MainComponent";

import WeatherSearch from "@/views/search/WeatherSearch";
import WeatherCreate from "@/views/create/WeatherCreate";
import WeatherUpdate from "@/views/update/WeatherUpdate";
import WeatherShow from "@/views/show/WeatherShow";

export default {
    path: "/weather",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "weather-search", component: WeatherSearch},
        {path: "create", name: "weather-create", component: WeatherCreate},
        {path: "edit/:id", name: "weather-edit", props: true, component: WeatherUpdate},
        {path: "show/:id", name: "weather-show", props: true, component: WeatherShow},
    ]
}