import MainComponent from "@/components/dashboard/MainComponent";

import BankAccountSearch from "@/views/search/BankAccountSearch";
import BankAccountCreate from "@/views/create/BankAccountCreate";
import BankAccountUpdate from "@/views/update/BankAccountUpdate";
import BankAccountShow from "@/views/show/BankAccountShow.vue";
import BankAccountUpdatePhoto from "@/views/update/BankAccountUpdatePhoto";

export default {
    path: "/bank-account",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "bank-account-search", component: BankAccountSearch},
        {path: "create", name: "bank-account-create", component: BankAccountCreate},
        {path: "edit/:id", name: "bank-account-edit", component: BankAccountUpdate, props: true},
        {path: "show/:id", name: "bank-account-show", component: BankAccountShow, props: true},
        {path: "photo/:id", name: "bank-account-photo", component: BankAccountUpdatePhoto, props: true},
    ]
}