<template>
    <div class="modal-window" v-if="modal_item || modal_edit">
        <painel-component :items="titleAdd" class="modal-item shadow-none" v-if="modal_item">
            <div class="clearfix mb-3">
                <button-submit @click="addItem" class="float-end" icon="plus"/>
            </div>

            <div class="container-item">
                <template v-for="(data, index) in itemsAdd" :key="index">
                    <div class="bg-body-secondary p-3 mb-3">
                        <form>
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <label>Preço:</label>
                                    <input-component v-money="money" v-model="data.price" type-input="text"/>
                                </row-col-component>

                                <row-col-component v-if="itemsAdd.length > 1"
                                                   class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                                    <button-delete @click="removeItem(index)"/>
                                </row-col-component>
                            </row-component>
                        </form>
                    </div>
                </template>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submitItem" class="w-100" icon="plus" name="Adicionar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="modalItem" class="w-100" icon="close" name="Fechar"/>
                </row-col-component>
            </row-component>
        </painel-component>

        <painel-component :items="titleEdit" class="modal-item h-auto shadow-none" v-if="modal_edit">
            <div class="bg-body-secondary p-3 mb-3">
                <form>
                    <row-component>
                        <row-col-component
                                class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>Nome:</label>
                            <input-component v-model="itemEdit.name" type-input="text"/>
                        </row-col-component>

                        <row-col-component
                                class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <label>Preço:</label>
                            <input-component v-money="money" v-model="itemEdit.price" type-input="text"/>
                        </row-col-component>
                    </row-component>
                </form>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="updateItem" class="w-100" icon="save" name="Salvar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="modalItemEdit" class="w-100" icon="close" name="Fechar"/>
                </row-col-component>
            </row-component>
        </painel-component>
    </div>

    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-submit @click="modalItem" class="me-2" icon="plus"/>
                    <button-route :items="itemsTwo"/>
                </row-col-component>
            </row-component>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th scope="col" style="width: 30%">Nome</th>
                    <th scope="col" style="width: 30%">Preço</th>
                    <th scope="col" style="width: 10%">Ações</th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="(data, index) in object" :key="index">
                    <td data-label="Nome">{{ data.name }}</td>
                    <td data-label="Preço">R$ {{ data.price }}</td>
                    <td data-label="Ações">
                        <button-delete @click="destroy(data)"/>
                        <button-submit icon="edit" class="bg-success ms-2 border" @click="modalItemEdit(data)"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </form>

        <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "MenuTableItems",
    components: {
        ButtonPagination,
        ButtonDelete,
        ButtonSubmit,
        ButtonRoute,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Menu Tabela")
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            titleAdd: {
                icon: "pen square",
                title: "Adicionar items"
            },

            titleEdit: {
                icon: "edit",
                title: "Editar item"
            },

            itemsTwo: {
                icon: "reply",
                route: `/menu-table/edit/${this.id}`
            },

            formData: {
                id: this.id,
                name: "",
                client_id: ""
            },

            items: {
                id: this.id,
                client_id: "",
                itens: []
            },

            itemEdit: {
                id: "",
                idapp: this.id,
                name: "",
                price: "",
                client_id: 1,
            },

            modal_item: false,
            modal_edit: false,

            money: MONEY,

            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadMenuTablesAppsItems", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: this.id,
                        id_item: item.id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroyMenuTableAppItems', params).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
        },

        modalItem() {
            this.modal_item = !this.modal_item
            if (this.itemsAdd.length === 0)
                this.addItem()
        },

        modalItemEdit(data) {
            if (data) {
                this.itemEdit.id = data.id
                this.itemEdit.name = data.name
                this.itemEdit.price = data.price
            }

            this.modal_edit = !this.modal_edit
        },

        addItem() {
            this.items.itens.push({
                name: "",
                price: ""
            })
        },

        removeItem(index) {
            this.items.itens.splice(index, 1)
        },

        submitItem() {
            this.items.client_id = this.idClient
            this.$store.dispatch('storeMenuTableAppItems', this.items)
                .then(() => {
                    this.loadItems(1)
                    this.modalItem()
                    toast.success("Items adicionados com sucesso", {timeout: 1200});
                }).catch(() => {
                toast.error("Erro ao adicionar items!", {timeout: 1200});
            })
        },

        updateItem() {
            this.itemEdit.client_id = this.idClient
            this.$store.dispatch('updateMenuTableAppItems', this.itemEdit)
                .then(() => {
                    this.loadItems(1)
                    this.modalItemEdit()
                    toast.success("Item atualizado com sucesso", {timeout: 1200});
                }).catch(() => {
                toast.error("Erro ao atualizar item!", {timeout: 1200});
            })
        },
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        itemsAdd() {
            return this.items.itens
        },

        object() {
            return this.$store.state.menu_table.items_table
        },

        params() {
            return {
                page: this.object.current_page,
                id: this.id,
                name: this.formData.name,
                client_id: this.idClient
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-item {
    width: 50%;
    height: 68%;
}

.container-item {
    height: 450px;
    min-height: 450px;
    overflow-y: scroll;
    margin-bottom: 1rem;
    padding-right: 25px;
}


@media (max-width: 1440px) {
    .modal-item {
        width: 60%;
        height: 75%;
    }
}

@media (max-width: 991px) {
    .modal-item {
        width: 80%;
        height: 80%;
    }
}

@media (max-width: 430px) {
    .modal-item {
        width: 95%;
        height: 95%;
    }

    .container-item {
        height: 615px;
        min-height: 615px;
    }
}
</style>