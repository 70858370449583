<template>
    <div class="modal-window" v-if="modal">
        <CampaignFile v-if="modal" :id="this.id" :id_folder="this.id_folder" @close="modal = false"/>
    </div>

    <div class="painel">
        <div @click="$emit('close')" role="button" class="btn-close"></div>

        <row-component>
            <row-col-component class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formData.name" type-input="text"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-md-4">
                <button-submit @click="submit" icon="search"/>
            </row-col-component>
        </row-component>

        <row-component>
            <template v-for="(data, index) in object.data" :key="index">
                <row-col-component class-row-col="col-xxl-1 col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                    <div class="box-folder" @click="modalFunc(data.id)">
                        <div class="h-100">
                            <i class="folder icon"></i>
                            <p>{{ data.name }}</p>
                        </div>
                    </div>
                </row-col-component>
            </template>
        </row-component>

        <button-pagination class="pagination" :pagination="object" :offset="3" @paginate="loadItems"/>
    </div>
</template>

<script>
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import CampaignFile from "@/views/search/CampaignFile";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CampaignContent",
    components: {
        CampaignFile,
        ButtonPagination,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Conteúdo")
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            object: {},

            formData: {
                name: ""
            },

            id_folder: "",

            modal: false,

            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadCampaignContents", {...this.params, page})
                    .then((r) => {
                        this.object = r
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        modalFunc(id) {
            this.id_folder = id
            this.modal = true
        }
    },

    computed: {
        idClient() {
            return this.$store.state.var.idClient
        },

        params() {
            return {
                id: this.id,
                name: this.formData.name,
                client_id: this.idClient
            }
        }
    }
}
</script>

<style scoped>
.modal-window {
    z-index: 25;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
}

.painel {
    width: 75%;
    height: 65%;
    padding: 2%;
    position: relative;
    margin: 5% 2% 5% 2%;
    background-color: white;
}

.painel .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.painel .box-folder {
    width: 100%;
    height: 120px;
    cursor: pointer;
    text-align: center;
    position: relative;
    background: #dddddd;
    margin-bottom: 40px;
}

.box-folder > .h-100 i {
    font-size: 3rem;
}

.box-folder p {
    margin: 0 auto;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.pagination {
    right: 2%;
    bottom: 0;
    position: absolute;
}

@media (max-width: 1024px) {
    .painel {
        width: 95%;
        height: 75%;
    }
}

@media (max-width: 991px) {
    .painel {
        width: 95%;
        height: 90%;
        padding-top: 2rem;
        overflow-y: scroll;
    }

    .pagination {
        position: unset;
    }
}
</style>
