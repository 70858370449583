<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :searchable="true"
                                 :options="selectCompanyList" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.state}">
                    <label>Estado:</label>
                    <Multiselect v-model="formData.state" :searchable="true" :options="selectStateList"
                                 @select="loadCityList(formData.state)"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.city}">
                    <label>Cidade:</label>
                    <Multiselect v-model="formData.city" :searchable="true" :options="selectCityList"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.cep}">
                    <label>CEP:</label>
                    <input-component v-mask="'#####-###'" @change="searchAPICEP" v-model="formData.cep"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.district}">
                    <label>Bairro:</label>
                    <input-component v-model="formData.district" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.address}">
                    <label>Endereço:</label>
                    <input-component v-model="formData.address" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.complement}">
                    <label>Referência:</label>
                    <text-area rows="5" v-model="formData.complement"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :disabled="sendForm" class="w-100" icon="circle plus"
                                   name="Adicionar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import ErrorComponent from "@/components/alerts/ErrorComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import {mask} from "vue-the-mask";

const toast = useToast()

export default {
    name: "SpotCreate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent,
        ErrorComponent
    },

    directives: {
        mask
    },

    created() {
        this.$store.dispatch("changeNamePage", "Local")
        this.checkACL()
        this.loadStorage()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/spot/search"
            },

            formData: {
                client_id: "",
                company_id: "",
                name: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                status: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeSpot', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Local criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'spot-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.formData.client_id = this.idClient
            this.$store.dispatch("loadStateList")
        },

        loadCityList(id) {
            this.$store.dispatch("loadCityList", id)
            if (this.formData.city)
                this.formData.city = ""
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 20)
                .then((r) => {
                    if (r.data.indexOf('spot-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        searchAPICEP() {
            let cep = this.formData.cep.replace(/\D/g, '');
            const params = {'cep': cep}
            this.$store.dispatch('searchApiCEP', params).then((r) => {
                this.formData.district = r.data.bairro
                this.formData.address = r.data.logradouro
                this.formData.complement = r.data.complemento
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectStateList() {
            return this.$store.state.var.selectStateList
        },

        selectCityList() {
            return this.$store.state.var.selectCityList
        },
    }
}
</script>