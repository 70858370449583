export default {
    items: {
        data: []
    },

    items_folder: {
        data: []
    },

    items_file: {
        data: []
    },

    items_table: {
        data: []
    }
}