<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="isClient" class="me-2" @click="submit" icon="search"/>
                    <button-route v-if="buttonCheck.create && isClient" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="alert alert-warning" role="alert" v-if="!isClient">
            <i class="exclamation triangle icon"></i>
            Selecione um cliente
        </div>

        <table class="table table-striped" v-if="showLoader && isClient">
            <thead>
            <tr>
                <th scope="col" style="width: 30%">Nome</th>
                <th scope="col" style="width: 30%">Status</th>
                <th scope="col" style="width: 15%"
                    v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.show || buttonCheck.pass">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações"
                    v-if="buttonCheck.delete || buttonCheck.edit || buttonCheck.show || buttonCheck.pass">
                    <button-submit v-if="buttonCheck.pass" @click="resetPass(data)" style="margin: 0 10px" icon="unlock alternative"/>
                    <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="user-system-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="user-system-show"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination v-if="isClient" :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import Multiselect from "@vueform/multiselect";
import {useToast} from "vue-toastification";
import swal from "sweetalert";
import ButtonDelete from "@/components/buttons/ButtonDelete.vue";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: "UserSystemSearch",
    components: {
        ButtonPagination,
        ButtonDelete,
        Multiselect,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        ButtonEdit,
        ButtonShow,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadReleasesAccessList")
        this.loadStorage()
        this.loadItems(1)
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/user-system/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false,
                delete: false,
                pass: false,
            },

            formData: {
                name: "",
                status: "",
                client_id: "",
            },

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadUsersSystem", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name}, ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        tenant_id: this.idClient
                    }

                    this.$store.dispatch('destroyUserSystem', params).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        resetPass(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente resetar a senha de ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }

                    this.$store.dispatch('userSystemResetPassword', params).then(() => {
                        this.loadItems(1)
                        toast.success("Senha resetada com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Ocorreu um erro!`, {timeout: 1200});
                    })
                }
            });
        },

        loadStorage() {
            this.$store.commit("IS_CLIENT", this.convertBool(localStorage.getItem("isClient")))
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        convertBool(string) {
            return string === "true";
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 10)
                .then((r) => {
                    if (r.data.indexOf('user-system-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('user-system-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('user-system-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('user-system-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('user-system-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }

                        if (r.data.indexOf('user-system-reset-password') !== -1) {
                            this.buttonCheck.pass = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        isClient() {
            return this.$store.state.var.isClient
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClient() {
            return this.$store.state.release_access.list
        },

        object() {
            return this.$store.state.user_system.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                status: this.formData.status,
                client_id: this.idClient,
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>