<template>
    <painel-component :items="itemsOne" class="shadow-none modal-file">
        <div role="button" class="btn-close" @click="$emit('close-file')"></div>

        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-lg-4">
                    <button-submit @click="submit" icon="search"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="content-file-explorer">
            <div class="file-content" v-for="(data, index) in object.data" :key="index">
                <div class="file-link-div" @click="submitFile(data.id)">
                    <i class="file image icon"></i>
                    <p>{{ data.name_original }}</p>
                </div>
                <div class="view-file" @click="previewFile('www.google.com')">
                    <button>
                        <i class="eye icon"></i>
                    </button>
                </div>
            </div>
        </div>

        <button-pagination class="pagination-settings" :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "MenuTableBackgroundFile",
    components: {
        ButtonPagination,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        },

        folder: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Menu Tabela")
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "image",
                title: "Selecionar background"
            },

            formData: {
                id: "",
                name: "",
                company_id: "",
                client_id: ""
            },

            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadMenuTablesAppsFiles", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        submitFile(id) {
            let formData = {
                client_id: this.idClient,
                id: this.id,
                file_id: id
            }

            this.$store.dispatch('storeMenuTableAppFile', formData)
                .then(() => {
                    this.$emit('close-all')
                    toast.success("Background alterado com sucesso", {timeout: 1200});
                }).catch(() => {
                toast.error("Erro ao alterar o background!", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
        },

        previewFile(url) {
            window.open(url, "_blank")
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        object() {
            return this.$store.state.menu_table.items_file
        },

        params() {
            return {
                page: this.object.current_page,
                id: this.id,
                name: this.formData.name,
                company_id: this.formData.company_id,
                content_folder_id: this.folder,
                client_id: this.idClient
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>

<style scoped>
.modal-file {
    width: 65%;
    height: 67%;
}

.btn-close {
    top: 2%;
    right: 1%;
    position: absolute;
}

.pagination-settings {
    bottom: 0;
    right: 1%;
    margin-left: 3rem;
    position: absolute;
}

.content-file-explorer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 100%;
    align-content: flex-start;
    border-radius: 0 0 10px 10px;
}

.file-content {
    width: 105px;
    display: flex;
    font-size: 14px;
    margin: 5px 10px;
    position: relative;
    text-align: center;
    height: fit-content;
    align-items: center;
    word-wrap: break-word;
    flex-direction: column;
}

.file-link-div {
    height: 100%;
    width: 100%;
}

.file-link-div > i {
    font-size: 60px;
}

.file-link-div p {
    margin: 0 auto;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.file-link-div:hover {
    cursor: pointer;
    background-color: rgb(192, 192, 192, 0.4);
}

.file-content .view-file {
    top: 100%;
    left: 50%;
    z-index: 1;
    color: #fff;
    width: 100%;
    padding: 5px 0;
    margin-left: -50%;
    text-align: center;
    position: absolute;
}

.view-file {
    display: flex;
    justify-content: space-between;
    height: 2.5rem;
}

.view-file > button {
    border: 0;
    width: 100%;
    height: auto;
}

@media(max-width: 1024px){
    .modal-file{
        width: 95%;
        height: 70%;
    }
}

@media(max-width: 991px){
    .modal-file{
        height: 90%;
    }
}

@media(max-width: 430px){
    .modal-file{
        height: 95%;
    }

    .btn-close {
        top: 1%;
        right: 2%;
    }
}
</style>