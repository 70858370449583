import MainComponent from "@/components/dashboard/MainComponent";

import ReceiptSearch from "@/views/search/ReceiptSearch";
import ReceiptCreate from "@/views/create/ReceiptCreate";
import ReceiptUpdate from "@/views/update/ReceiptUpdate";
import ReceiptShow from "@/views/show/ReceiptShow";

export default {
    path: "/receipt",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "receipt-search", component: ReceiptSearch},
        {path: "create", name: "receipt-create", component: ReceiptCreate},
        {path: "edit/:id", name: "receipt-edit", component: ReceiptUpdate, props: true},
        {path: "show/:id", name: "receipt-show", component: ReceiptShow, props: true},
    ]
}