<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix" v-if="create">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <form v-if="showLoader">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                </div>
                <router-link :to="{ name: 'bank-account-photo', id: this.id }" class="figure">
                    <i class="camera icon"></i>
                </router-link>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.balance}">
                    <label>Saldo:</label>
                    <input-component v-model="formData.balance" v-money="MONEY" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.bank}">
                    <label>Banco:</label>
                    <Multiselect v-model="formData.bank" :options="selectBank" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.account_type}">
                    <label>Tipo de conta:</label>
                    <Multiselect v-model="formData.account_type" :options="selectAccountType" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.agency}">
                    <label>Agência:</label>
                    <input-component v-model="formData.agency" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.digit_account}">
                    <label>Conta/Dígito:</label>
                    <input-component v-model="formData.digit_account" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.pix_type}">
                    <label>PIX Tipo:</label>
                    <Multiselect v-model="formData.pix_type" :options="selectPIX" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.key_pix}">
                    <label>Chave:</label>
                    <input-component :type-input="'text'" v-model="formData.key_pix"
                                     v-if="parseInt(this.formData.pix_type) === 1"
                                     v-mask="['###.###.###-##', '##.###.###/####-##']"/>

                    <input-component type-input="text" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 2"
                                     v-mask="['(##) ####-####', '(##) #####-####']"/>

                    <input-component type-input="email" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 3"/>

                    <input-component type-input="text" v-model="formData.key_pix"
                                     v-else-if="parseInt(this.formData.pix_type) === 4"/>

                    <input-component type-input="text" v-model="formData.key_pix" v-else/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Observações:</label>
                    <text-area v-model="formData.obs" :rows="5"/>
                </row-col-component>
            </row-component>

            <row-component class="mb-4">
                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'">
                    <label>Conta Principal ?:</label>

                    <input type="radio" id="main_account_n" v-model="formData.main_account" value="1">
                    <label for="main_account_n">Não</label>

                    <input type="radio" id="main_account_s" v-model="formData.main_account" value="2">
                    <label for="main_account_s">Sim</label>
                </row-col-component>

                <row-col-component :class-row-col="'col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.allow_negative_balance }">
                    <label>Permitir Saldo Negativo ?:</label>

                    <input type="radio" id="allow_negative_balance_n" v-model="formData.allow_negative_balance"
                           value="1">
                    <label for="allow_negative_balance_n">Não</label>

                    <input type="radio" id="allow_negative_balance_s" v-model="formData.allow_negative_balance"
                           value="2">
                    <label for="allow_negative_balance_s">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" :disabled="sendForm" @click="submit" name="Salvar" icon="save"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {MONEY} from "@/configs/constants";
import {mask} from "vue-the-mask";
import {VMoney} from "v-money";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "BankAccountUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Conta Bancária")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/bank-account/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/bank-account/create"
            },

            MONEY,
            create: false,

            formData: {
                name: "",
                bank: "",
                balance: "",
                account_type: "",
                agency: "",
                digit_account: "",
                pix_type: "",
                key_pix: "",
                allow_negative_balance: "",
                main_account: "",
                status: "",
                obs: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadBankAccount', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateBankAccount', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Conta bancária atualizada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 27)
                .then((r) => {
                    if (r.data.indexOf('account-bank-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('account-bank-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBank() {
            return this.$store.state.var.selectBank
        },

        selectAccountType() {
            return this.$store.state.var.selectAccountType
        },

        selectPIX() {
            return this.$store.state.var.selectPIX
        },
    }
}
</script>