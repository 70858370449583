import MainComponent from "@/components/dashboard/MainComponent";

import YoutubeSearch from "@/views/search/YoutubeSearch";
import YoutubeCreate from "@/views/create/YoutubeCreate";
import YoutubeUpdate from "@/views/update/YoutubeUpdate";
import YoutubeShow from "@/views/show/YoutubeShow";

export default {
    path: "/youtube",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "youtube-search", component: YoutubeSearch},
        {path: "create", name: "youtube-create", component: YoutubeCreate},
        {path: "edit/:id", name: "youtube-edit", props: true, component: YoutubeUpdate},
        {path: "show/:id", name: "youtube-show", props: true, component: YoutubeShow},
    ]
}