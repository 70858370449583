import menuCadastro from "@/configs/links-menu/modules/menu-cadastro";
import menuContrato from "@/configs/links-menu/modules/menu-contrato";
import menuEstoque from "@/configs/links-menu/modules/menu-estoque";
import menuFinanceiro from "@/configs/links-menu/modules/menu-financeiro";
import menuSistema from "@/configs/links-menu/modules/menu-sistema";

export default {
    menuCadastro,
    menuContrato,
    menuEstoque,
    menuFinanceiro,
    menuSistema
}