<template>
    <painel-component :items="itemsOne">
        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="box icon"></i>
                <span>Lotes</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="aux mb-4 mt-3">
                        <div class="avatar-form">
                            <img v-if="formData.product_photo" :src="formData.product_photo" class="avatar__image"
                                 alt="">
                            <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                        </div>
                        <p>{{ formData.name }}</p>
                    </div>

                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component disabled v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Estoque Mínimo:</label>
                                <input-component disabled v-model="formData.minimum_stock" type-input="number"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                <label>Classificação:</label>
                                <Multiselect disabled v-model="formData.classification" :options="selectClassification"
                                             :searchable="true" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Observações:</label>
                                <text-area disabled v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="aux mb-4 mt-3">
                        <div class="avatar-form">
                            <img v-if="formData.product_photo" :src="formData.product_photo" class="avatar__image"
                                 alt="">
                            <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                        </div>
                        <p>{{ formData.name }}</p>
                    </div>

                    <template v-for="(data, index) in formData.batch" :key="index">
                        <div class="bg-body-secondary p-3 mb-3">
                            <div class="clearfix mb-3">
                                <button-submit class="float-end" icon="print"/>
                            </div>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Local:</label>
                                    <input-component disabled v-model="data.name_local" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nº do Lote:</label>
                                    <input-component disabled v-model="data.number_batch" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Quantidade:</label>
                                    <input-component disabled v-model="data.amount" type-input="text"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Setor:</label>
                                    <input-component disabled v-model="data.sector" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Coluna:</label>
                                    <input-component disabled v-model="data.column" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Prateleira:</label>
                                    <input-component disabled v-model="data.shelf" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ProductUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Produto")
        this.$store.dispatch("loadLocalList")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/product/search"
            },

            formData: {
                name: "",
                status: "",
                price: "",
                minimum_stock: "",
                classification: "",
                obs: "",
                product_photo: "",
                batch: [],
            },

            MONEY,

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadProduct', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 32)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectLocalList() {
            return this.$store.state.var.selectLocalList
        },

        selectClassification() {
            return this.$store.state.var.selectClassification
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>