import axios from "axios";
import {NAME_TOKEN} from '@/configs/constants'
import {URL_BASE_ADMIN} from "@/configs/constants";

const RESOURCE = 'auth';

export default {
    login(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_ADMIN}${RESOURCE}`, params)
                .then(r => {
                    context.commit('AUTH_USER_OK', r.data.user)
                    const token = r.data.user.token
                    localStorage.setItem(NAME_TOKEN, token)
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    return resolve(r)
                }).catch(errors => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        });
    },

    loginCheck(context) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem(NAME_TOKEN)
            const configHeader = {headers: {Authorization: `Bearer ${token}`}};
            if (!token) {
                context.state.authenticate = false;
                return reject()
            }
            context.state.authenticate = true;
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/me`, configHeader)
                .then(r => {
                    context.commit('AUTH_USER_OK', r.data.user)
                    resolve()
                }).catch(e => {
                return reject(e)
            })
        })
    },

    logout(context) {
        const token = localStorage.getItem(NAME_TOKEN)
        context.commit('AUTH_USER_LOGOFF')
        if (token != null) {
            const configHeader = {headers: {Authorization: `Bearer ${token}`}};
            axios.delete(`${URL_BASE_ADMIN}${RESOURCE}/logout`, configHeader).then(() => {
                localStorage.removeItem(NAME_TOKEN)
            }).catch(() => {
                localStorage.removeItem(NAME_TOKEN)
            })
        } else {
            localStorage.removeItem(NAME_TOKEN)
        }
    },

    ACLItems(context, type) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/permissions?type=${type}`,)
                .then(r => {
                    resolve(r)
                }).catch(e => {
                return reject(e)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    ACLItemsNav(context) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}${RESOURCE}/permissionsnav`,)
                .then(r => {
                    resolve(r)
                    context.commit('LOADER', false)
                }).catch(e => {
                context.commit('LOADER', false)
                return reject(e)
            })
        })
    },
}