export default {
    icon: 'cogs',
    name: 'Sistema',
    subLinks: [
        {
            name: 'Aplicativo',
            route: '/app/search',
            pagePermissions: [
                "user-system-search",
                "user-system-create",
                "user-system-edit",
                "user-system-delete"
            ],
        },

        {
            name: 'Campanha',
            route: '/campaign/search',
            pagePermissions: [
                "campaing-search",
                "campaing-create",
                "campaing-edit",
                "campaing-delete",
                "campaing-add-player",
                "campaing-add-files",
                "campaing-add-app",
                "campaing-synchronize",
                "campaing-share"
            ],
        },

        {
            name: 'Conteúdo',
            route: '/content/search',
            pagePermissions: [
                "content-folder-search",
                "content-folder-create",
                "content-folder-edit",
                "content-folder-delete",
            ],
        },

        {
            name: 'Empresa',
            route: '/company/search',
            pagePermissions: [
                "company-search",
                "company-create",
                "company-edit",
            ],
        },

        {
            name: 'Local',
            route: '/spot/search',
            pagePermissions: [
                "spot-search",
                "spot-create",
                "spot-edit"
            ],
        },

        {
            name: 'Player',
            route: '/player/search',
            pagePermissions: [
                "player-search",
                "player-create",
                "player-edit",
                "player-delete",
                "player-info",
                "player-synchronize",
                "player-restart-app",
                "player-restart-device",
                "player-opening-hours",
                "player-add-campaign",
                "player-add-off-on",
                "player-reset-app"
            ],
        },

        {
            name: 'Usuário',
            route: '/user-system/search',
            pagePermissions: [
                "user-system-search",
                "user-system-create",
                "user-system-edit",
                "user-system-delete",
                "user-system-reset-password"
            ],
        },
    ],
    pageLinksNavCheck: [],
}