import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Toast from "vue-toastification";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/css/main.css"
import "@/assets/css/icon.min.css"
import "@vueform/multiselect/themes/default.css"
import "vue-toastification/dist/index.css";

import {NAME_TOKEN} from "@/configs/constants";

const tokenAccess = localStorage.getItem(NAME_TOKEN)
if (tokenAccess)
    axios.defaults.headers.common['Authorization'] = `Bearer ${tokenAccess}`;

if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://adm.methoduz.com/';
} else {
    axios.defaults.baseURL = 'https://methoduz-admin-back.d7mmarketing.com.br/';
}
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

createApp(App)
    .use(store)
    .use(router)
    .use(Toast)
    .mount('#app')

store.dispatch('loginCheck').catch(() => {
    store.dispatch('logout')
})
