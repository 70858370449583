<template>
    <painel-component class="shadow-none painel px-0 pb-0">
        <error-component v-if="showError" :errors="errors"/>

        <div @click="this.$emit('close')" role="button" class="btn-close"></div>

        <div class="tabs effect-3 mb-0">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="plus icon"></i>
                <span>Adicionar</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="play icon"></i>
                <span>Campanhas Adicionados</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1" class="h-100 p-3">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Empresa:</label>
                                <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                             :searchable="true" :noResultsText="noResultsText"
                                             :noOptionsText="noOptionsText"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-md-4">
                                <button-submit @click="submit" icon="search"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               v-for="(data,index) in this.results.data" :key="index">
                                <input v-model="campaigns" :value="data.id" type="checkbox" name="thumbs"
                                       :id="'thumbs' + index">
                                <label class="pl-add bg-body-secondary" :for="'thumbs' + index">
                                    <div class="box-file">
                                        <div class="mb-2">
                                            <i class="tv icon"></i>
                                            {{ data.name }}
                                        </div>

                                        <div>
                                            <i class="briefcase icon"></i>
                                            {{ data.company_name }}
                                        </div>
                                    </div>
                                </label>
                            </row-col-component>
                        </row-component>
                    </form>

                    <div class="pagination">
                        <button-submit @click="storeCampaigns(campaigns)" icon="plus" name="Adicionar"/>
                        <button-pagination :pagination="results" :offset="3" @paginate="loadItems"/>
                    </div>
                </section>

                <section id="tab-item-2" class="h-100 p-3">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Empresa:</label>
                                <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                             :searchable="true" :noResultsText="noResultsText"
                                             :noOptionsText="noOptionsText"/>
                            </row-col-component>

                            <row-col-component
                                    class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-0 mt-md-4">
                                <button-submit @click="submit" icon="search"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               v-for="(data,index) in campaignAdd" :key="index">
                                <label class="pl-add bg-body-secondary d-flex justify-content-between">
                                    <div class="box-file">
                                        <div class="mb-2">
                                            <i class="play icon"></i>
                                            {{ data.name }}
                                        </div>

                                        <div>
                                            <i class="briefcase icon"></i>
                                            {{ data.company_name }}
                                        </div>
                                    </div>

                                    <button type="button" @click="destroyCampaign(data)">
                                        <i class="trash alternate outline icon"></i>
                                    </button>
                                </label>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "PlayerCampaign",
    components: {
        ErrorComponent,
        ButtonPagination,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            formData: {
                name: "",
                company_id: "",
            },

            results: {},
            campaignAdd: {},

            campaigns: [],

            errors: [],
            showError: false,
            sendForm: false,
            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadPlayerCampaigns", {...this.params, page})
                    .then((r) => {
                        this.results = r.result
                        this.campaignAdd = r.campaignAdd

                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", localStorage.getItem("id_client"))
        },

        storeCampaigns(data) {
            let formData = {
                player_id: this.id,
                client_id: this.idClient,
                campaings: []
            }

            data.forEach(element => {
                formData.campaings.push(element)
            });

            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storePlayerCampaign', formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadItems(1)
                        toast.success("Campanha adicionada a campanha com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        destroyCampaign(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        player_id: this.id,
                        campaign_id: item.campaign_id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroyPlayerCampaigns', params).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        params() {
            return {
                name: this.formData.name,
                company_id: this.formData.company_id,
                player_id: this.id,
                client_id: this.idClient
            }
        }
    }
}
</script>

<style scoped>

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 2);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.painel {
    width: 80%;
    height: 85%;
}

.painel .tab-content {
    height: 75vh;
    padding: 60px 0 0;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.pagination {
    left: 0;
    bottom: 0;
    height: 0;
    width: 100%;
    display: flex;
    padding: 0 1rem;
    position: absolute;
    align-items: center;
    justify-content: space-between;
}

input[type="checkbox"] {
    display: none;
}

.painel .pl-add {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
    line-height: unset;
    position: relative;
}

.painel .pl-add button {
    border: 0;
    background: transparent;
}

.painel .pl-add:before {
    content: " ";
    top: 0;
    right: 0;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid grey;
    background-color: white;
    transition-duration: 0.4s;
}

.painel input:checked + .pl-add:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.painel input[type="checkbox"] + .pl-add::after {
    display: none;
}

@media (max-width: 1024px) {
    .painel {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
        padding-top: 2.5rem;
    }

    .painel .tab-content {
        height: 85vh;
    }
}

@media (max-width: 991px) {
    .painel-component {
        margin-top: 5%;
    }
}
</style>
