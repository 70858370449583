export default {
    CHANGE_NAME_PAGE(state, name) {
        state.namePage = name
    },

    IS_CLIENT(state, boolean){
        state.isClient = boolean
    },

    ID_CLIENT(state, item){
        state.idClient = item
    },

    LOAD_PERMISSIONS_PAGE(state, name) {
        state.permissionsPage = name
    },

    LOAD_GROUP_LIST(state, name){
        state.selectGroupList = name
    },

    LOAD_CLIENT_LIST(state, name){
        state.selectClientList = name
    },

    LOAD_NOTICE_LIST(state, name){
        state.selectNoticeList = name
    },

    LOAD_COMPANY_LIST(state, name){
        state.selectCompanyList = name
    },

    LOAD_CLIMATE_CITIES_LIST(state, name){
        state.selectClimateListCities = name
    },

    LOAD_FONTS_LIST(state, name){
        state.selectFontsList = name
    },

    LOAD_STATE_LIST(state, name){
        state.selectStateList = name
    },

    LOAD_CITY_LIST(state, name){
        state.selectCityList = name
    },

    LOAD_LAYOUT_LIST(state, name){
        state.selectLayoutList = name
    },

    LOAD_SPOT_LIST(state, name){
        state.selectSpotList = name
    },

    LOAD_TYPE_MEDIA_LIST(state, name){
        state.selectTypeMediaPlayerList = name
    },

    LOAD_SEGMENTE_LIST(state, name){
        state.selectSegmentPlayerList = name
    },

    LOAD_RESOLUTION_LIST(state, name){
        state.selectResolutionList = name
    },

    LOAD_VERSION_APP_LIST(state, name){
        state.selectVersionAppList = name
    },

    LOAD_VERSION_ANDROID_LIST(state, name){
        state.selectVersionAndroidList = name
    },

    LOAD_CONTENT_FOLDER_SPOT_LIST(state, name){
        state.selectContentFolderSpotList = name
    },

    LOAD_BANK_ACCOUNT_LIST(state, name){
        state.selectBankAccountList = name
    },

    LOAD_ACCOUNT_TYPE_LIST(state, name){
        state.selectAccountTypeList = name
    },

    LOAD_SUB_ACCOUNT_TYPE_LIST(state, name){
        state.selectSubAccountTypeList = name
    },

    LOAD_EMPLOYEE_LIST(state, name){
        state.selectEmployeeList = name
    },

    LOAD_EMPLOYEE_BANK_LIST(state, name){
        state.selectEmployeeBankList = name
    },

    LOAD_LOCAL_LIST(state, name){
        state.selectLocalList = name
    },

    LOAD_PRODUCT_LIST(state, name){
        state.selectProductList = name
    },

    LOAD_PRODUCT_BATCH_LIST(state, name){
        state.selectProductBatchList = name
    },

    LOAD_USER_LIST(state, name){
        state.selectUserList = name
    },

    LOAD_CLIENT_EMAIL_LIST(state, name){
        state.selectClientEmailList = name
    },

    LOAD_CLIENT_PHONE_LIST(state, name){
        state.selectClientPhoneList = name
    },

    LOAD_CONTRACT_MODEL_LIST(state, name){
        state.selectContractModelList = name
    },

    LOAD_CONTRACT_MODEL_GET_LIST(state, name){
        state.selectContractModelGETList = name
    },
}