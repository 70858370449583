import MainComponent from "@/components/dashboard/MainComponent";

import AppSearch from "@/views/search/AppSearch";

export default {
    path: "/app",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "app-search", component: AppSearch},
    ]
}