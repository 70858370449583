<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix mb-3">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome do APP:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.url}">
                    <label>URL:</label>
                    <input-component v-model="formData.url" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time}">
                    <label>Tempo de exibição em segundos:</label>
                    <input-component v-model="formData.time" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" label="name"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Salvar"
                                   icon="save"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "WebEdit",
    components: {
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Web")
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/web/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/web/create"
            },

            buttonCheck: {
                create: false
            },

            formData: {
                client_id: "",
                name: "",
                url: "",
                time: "",
                status: "",
                company_id: ""
            },

            params: {
                id: this.id,
                client_id: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateWebApp', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Aplicativo atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadItems() {
            this.$store.dispatch('loadWebApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 17)
                .then((r) => {
                    if (r.data.indexOf('app-web-url-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('app-web-url-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectNoticeList() {
            return this.$store.state.var.selectNoticeList
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },
    }
}
</script>