import MainComponent from "@/components/dashboard/MainComponent";

import ClockSearch from "@/views/search/ClockSearch";
import ClockCreate from "@/views/create/ClockCreate";
import ClockUpdate from "@/views/update/ClockUpdate";
import ClockShow from "@/views/show/ClockShow.vue";

export default {
    path: "/clock",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "clock-search", component: ClockSearch},
        {path: "create", name: "clock-create", component: ClockCreate},
        {path: "edit/:id", name: "clock-edit", props: true, component: ClockUpdate},
        {path: "show/:id", name: "clock-show", props: true, component: ClockShow},
    ]
}