export default {
    icon: "box",
    name: "Estoque",
    subLinks: [
        {
            name: 'Local',
            route: '/local/search',
            pagePermissions: [
                "local-search",
                "local-create",
                "local-edit",
                "local-delete"
            ],
        },

        {
            name: 'Entrada/Saída',
            route: '/inventory/search',
            pagePermissions: [
                "product-entrance-and-exit-search",
                "product-entrance-and-exit-create"
            ],
        },

        {
            name: 'Produto',
            route: '/product/search',
            pagePermissions: [
                "product-search",
                "product-create",
                "product-edit",
                "product-delete"
            ],
        },
    ],
    pageLinksNavCheck: [],
}