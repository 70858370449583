<template>
    <div class="modal-window" v-if="modal">
        <SelectClient v-if="modal" @close="modalClient" @load="loadClients"/>
    </div>

    <div class="menu-container" :class="{'open-nav-menu' : active}">
        <div class="logo-container">
            <router-link to="/">
                <img src="@/assets/logo.png" alt="Methoduz">
            </router-link>
        </div>

        <div class="user-container" role="button" @click="dropMenu.menuUser = !dropMenu.menuUser">
            <div class="avatar">
                <img v-if="me.photo" :src="me.photo" alt="">
                <img v-else src="@/assets/avatar.png" alt="">
                <p>
                    {{ me.name }}
                    <i :class="dropMenu.menuUser ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                </p>
            </div>
            <transition name="slide">
                <ul class="links submenu userMenu" v-if="dropMenu.menuUser">
                    <li>
                        <router-link :to="{ name: 'user-photo' }">
                            <i class="icon image"></i> Alterar Foto
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{ name: 'user-password' }">
                            <i class="icon unlock alternate"></i>Alterar Senha
                        </router-link>
                    </li>
                    <li>
                        <a href="#" @click="logout"><i class="icon sign-out"></i> Sair</a>
                    </li>
                </ul>
            </transition>
        </div>

        <div class="link-container" role="button">
            <ul>
                <li @click="dropMenu.menuCadastro = !dropMenu.menuCadastro">
                    <a href="#">
                        <i :class="'icon ' + linksMenu.menuCadastro.icon"></i>
                        {{ linksMenu.menuCadastro.name }}
                        <i :class="dropMenu.menuCadastro ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                    </a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.menuCadastro">
                        <div v-for="(link, index) in linksMenu.menuCadastro.subLinks" :key="index"
                             @click="this.$emit('menu-resp')">
                            <router-link v-if="checkPermission(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'active-bg': (activeLink(link.route)) }, 'link-route']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="link-container" role="button">
            <ul>
                <li @click="dropMenu.menuContrato = !dropMenu.menuContrato">
                    <a href="#">
                        <i :class="'icon ' + linksMenu.menuContrato.icon"></i>
                        {{ linksMenu.menuContrato.name }}
                        <i :class="dropMenu.menuContrato ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                    </a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.menuContrato">
                        <div v-for="(link, index) in linksMenu.menuContrato.subLinks" :key="index"
                             @click="this.$emit('menu-resp')">
                            <router-link v-if="checkPermission(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'active-bg': (activeLink(link.route)) }, 'link-route']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="link-container" role="button">
            <ul>
                <li @click="dropMenu.menuEstoque = !dropMenu.menuEstoque">
                    <a href="#">
                        <i :class="'icon ' + linksMenu.menuEstoque.icon"></i>
                        {{ linksMenu.menuEstoque.name }}
                        <i :class="dropMenu.menuEstoque ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                    </a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.menuEstoque">
                        <div v-for="(link, index) in linksMenu.menuEstoque.subLinks" :key="index"
                             @click="this.$emit('menu-resp')">
                            <router-link v-if="checkPermission(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'active-bg': (activeLink(link.route)) }, 'link-route']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="link-container" role="button">
            <ul>
                <li @click="dropMenu.menuFinanceiro = !dropMenu.menuFinanceiro">
                    <a href="#">
                        <i :class="'icon ' + linksMenu.menuFinanceiro.icon"></i>
                        {{ linksMenu.menuFinanceiro.name }}
                        <i :class="dropMenu.menuFinanceiro ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                    </a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.menuFinanceiro">
                        <div v-for="(link, index) in linksMenu.menuFinanceiro.subLinks" :key="index"
                             @click="this.$emit('menu-resp')">
                            <router-link v-if="checkPermission(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'active-bg': (activeLink(link.route)) }, 'link-route']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="link-container" role="button">
            <ul>
                <li @click="dropMenu.menuSistema = !dropMenu.menuSistema">
                    <a href="#">
                        <i :class="'icon ' + linksMenu.menuSistema.icon"></i>
                        {{ linksMenu.menuSistema.name }}
                        <i :class="dropMenu.menuSistema ? 'icon caret down icon-menu' : 'icon caret right icon-menu'"></i>
                    </a>
                </li>
                <transition name="dropMenu">
                    <div class="submenu" v-if="dropMenu.menuSistema">
                        <div v-for="(link, index) in linksMenu.menuSistema.subLinks" :key="index"
                             @click="this.$emit('menu-resp')">
                            <router-link v-if="checkPermission(link.pagePermissions)" :to="link.route"
                                         :class="[{ 'active-bg': (activeLink(link.route)) }, 'link-route']">
                                {{ link.name }}
                            </router-link>
                        </div>
                    </div>
                </transition>
            </ul>
        </div>

        <div class="user-container select-client" role="button" @click="modalClient">
            <div class="avatar">
                <img v-if="client.photo" :src="client.photo" alt="">
                <img v-else src="@/assets/avatar.png" alt="">
                <p v-if="client.name" class="text-truncate" style="width: 160px">
                    {{ client.name }}
                    <i class="icon caret right icon-menu"></i>
                </p>
                <p v-else>
                    Selecione o cliente
                    <i class="icon caret right icon-menu"></i>
                </p>
            </div>
        </div>
    </div>

</template>

<script>
import linksMenu from "@/configs/links-menu";
import swal from "sweetalert";
import {useToast} from "vue-toastification";
import SelectClient from "@/views/search/SelectClient";

const toast = useToast()
export default {
    name: "NavMenu",
    components: {
        SelectClient
    },

    emits: ['menu-resp'],

    props: {
        active: {
            default: false
        }
    },

    created() {
        this.loadClients()
        this.checkACL()
    },

    data() {
        return {
            linksMenu,
            permissionsPage: [],

            dropMenu: {
                menuUser: false,
                menuCadastro: false,
                menuContrato: false,
                menuEstoque: false,
                menuFinanceiro: false,
                menuSistema: false
            },

            showItems: {
                menuCadastro: false,
                menuContrato: false,
                menuSistema: false
            },

            client: {},
            modal: false
        }
    },

    methods: {
        logout() {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente sair do sistema ${this.me.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('logout')
                    toast.success("Logout efetuado com sucesso!", {timeout: 2000})
                    setTimeout(() => this.$router.push({name: 'login'}), 2200)
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLItemsNav')
                .then((r) => {
                    this.permissionsPage = r.data;
                    this.permissionsPage.forEach(item => {
                        if (linksMenu.menuCadastro.pageLinksNavCheck.includes(item)) {
                            this.showItems.menuCadastro = true
                        }

                        if (linksMenu.menuContrato.pageLinksNavCheck.includes(item)) {
                            this.showItems.menuContrato = true
                        }

                        if (linksMenu.menuFinanceiro.pageLinksNavCheck.includes(item)) {
                            this.showItems.menuContrato = true
                        }

                        if (linksMenu.menuSistema.pageLinksNavCheck.includes(item)) {
                            this.showItems.menuSistema = true
                        }
                    })
                })
        },

        checkPermission(page) {
            let shouldSkip = false;
            this.permissionsPage.forEach(value => {
                if (shouldSkip) {
                    return;
                }

                if (page.includes(value)) {
                    shouldSkip = true;
                }
            });
            return shouldSkip;
        },

        activeLink(nameLink) {
            let x1 = nameLink.split("/");
            return this.$route.matched[0].path === "/" + x1[1];
        },

        loadClients() {
            if (localStorage.getItem("client") !== null)
                this.client = JSON.parse(localStorage.getItem("client"))
        },

        modalClient() {
            this.$emit('menu-resp')
            this.modal = !this.modal
        }
    },

    computed: {
        me() {
            return this.$store.state.auth.me
        }
    }
}
</script>

<style scoped>
.userMenu {
    margin-top: 15px;
}

.menu-container {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    width: 260px;
    padding: 0 10px;
    overflow-x: auto;
    min-height: 100vh;
    box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);
}

.menu-container .logo-container {
    padding: 22px 0;
    border-bottom: 1px inset #c3c3c3;
}

.menu-container .logo-container img {
    display: block;
    margin: 0 auto;
    max-width: 220px;
}

.menu-container .user-container {
    padding: 5px 0;
    min-height: 48px;
    border-bottom: 1px solid #c3c3c3;
}

.menu-container .user-container .avatar img {
    float: left;
    max-width: 35px;
    margin-right: 10px;
    border-radius: 50%;
}

.menu-container .user-container p {
    margin: 0;
    color: #000;
    padding-top: 5px;
}

.menu-container .links a {
    color: #000;
    height: 30px;
    display: block;
    line-height: 1.5em;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
}

.menu-container .user-container .submenu {
    clear: both;
    width: 100%;
    padding: 0 5px;
    overflow: hidden;
    transform-origin: top;
    list-style-type: none;
    background-color: #e3e3e3;
    transition: transform 0.4s ease-in-out;
}

.menu-container .user-container .submenu li {
    padding: 5px 0;
    font-size: 14px;
}

.menu-container .user-container .links a i {
    color: #686666;
    font-size: 15px;
}

.link-container ul {
    padding: 0;
    list-style: none;
    margin: 10px 0 0;
}

.link-container ul li {
    height: 45px;
    padding: 10px;
    background-color: #ebebeb;
}

.link-container ul li a {
    color: #000000;
    font-size: 16px;
    text-decoration: none;
    font-family: 'Lato', sans-serif;
}

.link-container .submenu {
    width: 100%;
    overflow: hidden;
    list-style-type: none;
    transform-origin: top;
    transition: transform 0.4s ease-in-out;
}

.link-container .icon-menu,
.menu-container .icon-menu {
    right: 15px;
    position: absolute;
}

.link-route {
    height: 45px;
    padding: 10px;
    color: #000000;
    display: block;
    text-decoration: none;
    background-color: #ebebeb;
    font-family: 'Lato', sans-serif;
}

.link-route:hover {
    color: #ffffff;
    transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    -webkit-transition: background-color 1000ms linear;
    background-color: rgba(242, 92, 5, 1) !important;
}

.select-client {
    width: 240px;
    bottom: 10px;
    position: absolute;
    border-top: 1px solid #c3c3c3;
}

.modal-window {
    top: 0;
    left: 0;
    z-index: 5;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    overflow: hidden;
    place-items: center;
}

.active-bg {
    color: white !important;
    background-color: rgba(242, 92, 5, 1) !important;
}

.dropMenu-enter,
.dropMenu-leave-to,
.dropMenu-enter-from {
    transform: scaleY(0);
}

.slide-enter,
.slide-leave-to,
.slide-enter-from {
    transform: scaleY(0);
}

@media (max-width: 991px) {
    .menu-container {
        left: -260px;
        transition: left 0.4s linear;
    }

    .open-nav-menu {
        left: 0;
        transition: left 0.4s linear;
    }

    .select-client {
        bottom: unset;
        margin-top: 10px;
    }
}

@media (max-width: 321px) {
    .modal-window {
        place-items: unset;
    }
}
</style>


