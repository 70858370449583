<template>
    <painel-component :items="itemsOne">
        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="money bill alternate icon"></i>
                <span>Sub Tipo de Conta</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component disabled v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Observação:</label>
                                <text-area disabled v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button-route class="w-100" :items="itemsTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.account_type_subs.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div v-else>
                        <div v-for="(data, index) in formData.account_type_subs" :key="index"
                             class="bg-body-secondary p-4">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Status:</label>
                                    <Multiselect disabled v-model="data.status" :options="selectActiveInative"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "AccountTypeShow",
    components: {
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Tipo de conta")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/account-type/search"
            },

            formData: {
                name: "",
                status: "",
                obs: "",
                account_type_subs: []
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadAccountType', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 26)
                .then((r) => {
                    if (r.data.indexOf('account-type-search') !== -1) {
                        this.showLoader = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>