import MainComponent from "@/components/dashboard/MainComponent";

import UserSystemSearch from "@/views/search/UserSystemSearch";
import UserSystemCreate from "@/views/create/UserSystemCreate";
import UserSystemUpdate from "@/views/update/UserSystemUpdate";
import UserSystemShow from "@/views/show/UserSystemShow";

export default {
    path: "/user-system",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "user-system-search", component: UserSystemSearch},
        {path: "create", name: "user-system-create", component: UserSystemCreate},
        {path: "edit/:id", name: "user-system-edit", props: true, component: UserSystemUpdate},
        {path: "show/:id", name: "user-system-show", props: true, component: UserSystemShow}
    ]
}