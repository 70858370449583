import axios from "axios";
import {URL_BASE_ADMIN} from "@/configs/constants";
import {URL_BASE_SYSTEM} from "@/configs/constants";

export default {
    changeNamePage(context, namePage) {
        context.commit("CHANGE_NAME_PAGE", namePage)
    },

    loadPermissionsPage(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}page`)
                .then((response) => {
                    context.commit("LOAD_PERMISSIONS_PAGE", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadGroupList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}group-list`)
                .then((response) => {
                    context.commit("LOAD_GROUP_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadClientList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}client-list`)
                .then((response) => {
                    context.commit("LOAD_CLIENT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadNoticeList(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/notice-list-urls`, {params})
                .then((response) => {
                    context.commit("LOAD_NOTICE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadCompanyList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}company-list?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_COMPANY_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadClimateListCities(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/climate-list-cities?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_CLIMATE_CITIES_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadFontsList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}app/menu-table-fonts?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_FONTS_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadStateList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}city-get-state`)
                .then((response) => {
                    context.commit("LOAD_STATE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadCityList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}city-get/${id}`)
                .then((response) => {
                    context.commit("LOAD_CITY_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadLayoutList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}campaing-list-layout?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_LAYOUT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadSpotList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}spot-list?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_SPOT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadTypeMediaPlayerList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-type-media?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_TYPE_MEDIA_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadSegmentList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-segment?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_SEGMENTE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadResolutionList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-resolution?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_RESOLUTION_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadVersionAppList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-list-version-app?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_VERSION_APP_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadVersionAndroidList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}player-list-version-android?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_VERSION_ANDROID_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadContentFolderSpotList(context, formData) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}content-folder-list-spot?client_id=${formData.client_id}&id=${formData.id}`)
                .then((response) => {
                    context.commit("LOAD_CONTENT_FOLDER_SPOT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadBankAccountList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}account-bank-list`)
                .then((response) => {
                    context.commit("LOAD_BANK_ACCOUNT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadAccountTypeList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}account-type-list`)
                .then((response) => {
                    context.commit("LOAD_ACCOUNT_TYPE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadSubAccountTypeList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}account-type-sub-list/${id}`)
                .then((response) => {
                    context.commit("LOAD_SUB_ACCOUNT_TYPE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadEmployeeList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}employee-list`)
                .then((response) => {
                    context.commit("LOAD_EMPLOYEE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadEmployeeBankList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}employee-bank/${id}`)
                .then((response) => {
                    context.commit("LOAD_EMPLOYEE_BANK_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadLocalList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}local-list`)
                .then((response) => {
                    context.commit("LOAD_LOCAL_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadProductList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}product-list`)
                .then((response) => {
                    context.commit("LOAD_PRODUCT_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadProductBatchList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}product-list-batch/${id}`)
                .then((response) => {
                    context.commit("LOAD_PRODUCT_BATCH_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadUserList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}user-list`)
                .then((response) => {
                    context.commit("LOAD_USER_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadClientEmailList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}client-list-email/${id}`)
                .then((response) => {
                    context.commit("LOAD_CLIENT_EMAIL_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadClientPhoneList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}client-list-phone/${id}`)
                .then((response) => {
                    context.commit("LOAD_CLIENT_PHONE_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadContractModelList(context) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}contract-model-list`)
                .then((response) => {
                    context.commit("LOAD_CONTRACT_MODEL_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },

    loadContractModelGetList(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_ADMIN}contract-model-get/${id}`)
                .then((response) => {
                    context.commit("LOAD_CONTRACT_MODEL_GET_LIST", response.data)
                    return resolve(response.data)
                }).catch((error) => {
                return reject(error)
            })
        })
    },
}