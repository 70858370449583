<template>
    <div class="modal-window" v-if="modal_player || modal_info || modal_hours">
        <PlayerCampaign v-if="modal_player" @close="campaignClose" :id="player_id"/>

        <PlayerInfo v-if="modal_info" @close="modal_info = false" :id="player_id"/>

        <PlayerHours v-if="modal_hours" @close="modal_hours = false" :id="player_id"/>
    </div>

    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Token:</label>
                    <input-component v-model="formData.token" class="text-uppercase" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Nome do player:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :searchable="true" :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.online" :options="selectOnOffTwo" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Localização:</label>
                    <Multiselect label="name" v-model="formData.spot_id" :options="selectSpotList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Tipo de mídia:</label>
                    <Multiselect v-model="formData.type_midia_id" :options="selectTypeMediaPlayerList"
                                 :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Segmento:</label>
                    <Multiselect v-model="formData.segmento_id" :options="selectSegmentPlayerList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Versão do aplicativo:</label>
                    <Multiselect v-model="formData.version_app" :options="selectVersionAppList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <label>Android:</label>
                    <Multiselect v-model="formData.version_app" :options="selectVersionAndroidList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component
                        v-if="isClient"
                        class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" icon="search"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="alert alert-warning" role="alert" v-if="!isClient">
            <i class="exclamation triangle icon"></i>
            Selecione um cliente
        </div>

        <row-component v-if="showLoader && isClient">
            <row-col-component :class-row-col="'col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'"
                               v-for="(data, index) in player.data" :key="index">
                <div class="card_body">
                    <div class="card_title"
                         :class="[{ 'player_bg_online': parseInt(data.online) === 2 },
                         { 'player_bg_offline': parseInt(data.online) === 1 }]">
                        {{ data.name }}
                    </div>

                    <div class="content">
                        <span class="c_left">Última Comunicação<br>{{ data.ultima_comunicacao }}</span>
                        <span class="c_right">Token<br>{{ data.token }}</span>
                    </div>

                    <div class="content flex-column hh-40">
                        <span class="c_left mb-2"><i class="map marker alternate icon"></i> {{ data.spot_name }}</span>
                        <span class="c_right"><i class="briefcase icon"></i> {{ data.company_name }}</span>
                    </div>

                    <div style="height: 40px" class="content">
                        <div class="c_left">
                            <i class="mobile alternate icon"></i>
                            Versão do App {{ data.versao_aplicativo }}
                        </div>
                        <div class="c_right">
                            <i class="android icon"></i>
                            Android {{ data.versao_android }}
                        </div>
                    </div>

                    <div class="content">
                        <div class="c_left">
                            <div class="container_campaign">
                                <button @click="playerCampaign(data.id)" class="btn">
                                    <i class="tv icon"></i> Campanhas
                                </button>
                                <div class="campaign">{{ data.total_campaing_add }}</div>
                            </div>
                        </div>
                        <span class="c_right">
                            <label :for="'default' + index" class="switches"
                                   :class="[{ 'switches_on': parseInt(data.tela) === 2 }, { 'switches_off': parseInt(data.tela) === 1 }]">
                                <input @click="onOffPlayer(data)" type="checkbox"
                                       :checked="parseInt(data.tela) === 1" :id="'default' + index">
                                <span class="slider"></span>
                            </label>
                        </span>
                    </div>

                    <div class="footer_icon">
                        <button @click="this.$router.push({name: 'player-edit', params:{id: data.id}})"
                                title="Editar player"
                                v-if="buttonCheck.edit">
                            <i class="edit icon"></i>
                        </button>

                        <button @click="playerInfo(data.id)" v-if="buttonCheck.info"
                                title="Informações do player">
                            <i class="info circle icon"></i>
                        </button>

                        <button @click="destroy(data)" v-if="buttonCheck.delete"
                                title="Excluir player">
                            <i class="trash icon"></i>
                        </button>

                        <button @click="synchronizePlayer(data.id)" v-if="buttonCheck.synchronize"
                                title="Sincronizar player com campanha">
                            <i class="cloud upload icon"></i>
                        </button>

                        <button @click="restartApp(data.id)" v-if="buttonCheck.restart_app"
                                title="Reiniciar aplicativo do player">
                            <i class="sync icon"></i>
                        </button>

                        <button @click="resetPlayer(data)" v-if="buttonCheck.reset_device"
                                title="Reset player">
                            <i class="redo icon"></i>
                        </button>

                        <button @click="playerHour(data.id)" v-if="buttonCheck.opening_hours"
                                title="Horário de funcionamento">
                            <i class="calendar icon"></i>
                        </button>

                        <button @click="restartPlayer(data.id)" v-if="buttonCheck.restart_device"
                                title="Reiniciar player">
                            <i class="power off icon"></i>
                        </button>
                    </div>
                </div>
            </row-col-component>
        </row-component>

        <button-pagination :pagination="player" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import PlayerCampaign from "@/views/search/PlayerCampaign";
import PlayerInfo from "@/views/search/PlayerInfo";
import PlayerHours from "@/views/search/PlayerHours";
import {useToast} from "vue-toastification";
import swal from "sweetalert";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: "PlayerSearch",
    components: {
        ButtonPagination,
        PlayerHours,
        PlayerInfo,
        PlayerCampaign,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Player")
        this.checkACL()
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/player/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false,
                info: false,
                synchronize: false,
                restart_app: false,
                restart_device: false,
                opening_hours: false,
                add_campaign: false,
                reset_device: false
            },

            formData: {
                token: "",
                name: "",
                online: "",
                type_midia_id: "",
                segmento_id: "",
                spot_id: "",
                company_id: "",
                version_app: "",
                client_id: ""
            },

            player: {},

            modal_player: false,
            modal_info: false,
            modal_hours: false,
            player_id: "",

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if(this.isClient){
                if (!this.searchSubmit) {
                    this.$store.dispatch("loadPlayers", {...this.params, page})
                        .then((r) => {
                            this.player = r.itens
                            if (this.searchSubmit) {
                                this.searchSubmit = false;
                                if (r.itens.data.length === 0) {
                                    toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                                }
                            }
                        }).catch(() => {
                        this.searchSubmit = false;
                    });
                }
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 24)
                .then((r) => {
                    if (r.data.indexOf('player-search') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('player-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                        if (r.data.indexOf('player-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                        if (r.data.indexOf('player-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                        if (r.data.indexOf('player-info') !== -1) {
                            this.buttonCheck.info = true
                        }
                        if (r.data.indexOf('player-synchronize') !== -1) {
                            this.buttonCheck.synchronize = true
                        }
                        if (r.data.indexOf('player-restart-app') !== -1) {
                            this.buttonCheck.restart_app = true
                        }
                        if (r.data.indexOf('player-restart-device') !== -1) {
                            this.buttonCheck.restart_device = true
                        }
                        if (r.data.indexOf('player-opening-hours') !== -1) {
                            this.buttonCheck.opening_hours = true
                        }
                        if (r.data.indexOf('player-add-campaign') !== -1) {
                            this.buttonCheck.add_campaign = true
                        }
                        if (r.data.indexOf('player-reset-app') !== -1) {
                            this.buttonCheck.reset_device = true
                        }
                        // player-add-off-on
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadStorage() {
            this.$store.commit("IS_CLIENT", this.convertBool(localStorage.getItem("isClient")))
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))

            this.$store.dispatch("loadSpotList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadSegmentList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadVersionAppList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadVersionAndroidList", localStorage.getItem("id_client"))
            this.$store.dispatch("loadTypeMediaPlayerList", localStorage.getItem("id_client"))
        },

        convertBool(string) {
            return string === "true";
        },

        playerCampaign(id) {
            this.player_id = id
            this.modal_player = true
        },

        campaignClose() {
            this.modal_player = false
            this.loadItems(1)
        },

        playerInfo(id) {
            this.player_id = id
            this.modal_info = true
        },

        playerHour(id) {
            this.player_id = id
            this.modal_hours = true
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }

                    this.$store.dispatch('destroyPlayer', params).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        synchronizePlayer(id) {
            let formData = {
                id: id,
                client_id: this.idClient
            }

            this.$store.dispatch("synchronizePlayer", formData)
                .then(() => {
                    this.loadItems(1)
                    toast.success("Player sincronizado com sucesso", {timeout: 1200})
                }).catch(() => {
                toast.error("Erro ao sincronizar o player", {timeout: 1200})
            })
        },

        restartApp(id) {
            let formData = {
                id: id,
                client_id: this.idClient
            }

            this.$store.dispatch("restartApp", formData)
                .then(() => {
                    this.loadItems(1)
                    toast.success("Aplicativo reiniciado com sucesso", {timeout: 1200})
                }).catch(() => {
                toast.error("Erro ao reiniciar o aplicativo", {timeout: 1200})
            })
        },

        resetPlayer(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente resetar o app ${item.name} ?, isso ira deletar todas as campanhas vinculadas do app`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }

                    this.$store.dispatch('resetPlayer', params).then(() => {
                        this.loadItems(1)
                        toast.success("Player resetado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Erro ao resetar o player`, {timeout: 1200});
                    })
                }
            });
        },

        restartPlayer(id) {
            let formData = {
                id: id,
                client_id: this.idClient
            }

            this.$store.dispatch("restartPlayer", formData)
                .then(() => {
                    this.loadItems(1)
                    toast.success("Player reiniciado com sucesso", {timeout: 1200})
                }).catch(() => {
                toast.error("Erro ao reiniciar o player", {timeout: 1200})
            })
        },

        onOffPlayer(data) {
            let formData = {
                id: data.id,
                tela: "",
                client_id: this.idClient
            }

            if (parseInt(data.tela) === 1)
                formData.tela = 2
            else
                formData.tela = 1

            this.$store.dispatch("onOffPlayer", formData)
                .then(() => {
                    this.loadItems(1)

                    if (formData.tela === 1)
                        toast.success("Player desligado", {timeout: 1200})

                    if (formData.tela === 2)
                        toast.success("Player ligado", {timeout: 1200})

                }).catch(() => {
                this.loadItems(1)
                toast.error("Erro desconhecido", {timeout: 1200})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        isClient() {
            return this.$store.state.var.isClient
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectSpotList() {
            return this.$store.state.var.selectSpotList
        },

        selectTypeMediaPlayerList() {
            return this.$store.state.var.selectTypeMediaPlayerList
        },

        selectSegmentPlayerList() {
            return this.$store.state.var.selectSegmentPlayerList
        },

        selectVersionAppList() {
            return this.$store.state.var.selectVersionAppList
        },

        selectVersionAndroidList() {
            return this.$store.state.var.selectVersionAndroidList
        },

        selectOnOffTwo() {
            return this.$store.state.var.selectOnOffTwo
        },

        object() {
            return this.$store.state.player.items
        },

        params() {
            return {
                token: this.formData.token,
                name: this.formData.name,
                online: this.formData.online,
                type_midia_id: this.formData.type_midia_id,
                segmento_id: this.formData.segmento_id,
                spot_id: this.formData.spot_id,
                company_id: this.formData.company_id,
                version_app: this.formData.version_app,
                client_id: this.idClient
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>

<style src="@/assets/css/player.css">

</style>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

</style>