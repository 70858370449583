export default {
    LOAD_MENU_TABLE_APP(state, menu_table) {
        state.items = menu_table
    },

    LOAD_MENU_TABLE_APP_CONTENTS(state, menu_table) {
        state.items_folder = menu_table
    },

    LOAD_MENU_TABLE_APP_FILES(state, menu_table) {
        state.items_file = menu_table
    },

    LOAD_MENU_TABLE_APP_ITEMS(state, menu_table) {
        state.items_table = menu_table
    }
}