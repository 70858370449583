<template>
    <div class="modal-w position-fixed d-grid" v-if="modal">

        <div class="modal-p p-4 shadow-lg position-relative" v-if="modal">
            <div @click="closeSub" role="button" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="sub_account_type.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="sub_account_type.status" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="!edit" @click="submitSub" name="Adicionar" icon="plus"/>
                    <button-submit v-else @click="submitSubEdit" name="Salvar" icon="save"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="money bill alternate icon"></i>
                <span>Sub Tipo de Conta</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Observação:</label>
                                <text-area v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-submit :disables="sendForm" @click="submit" class="w-100" name="Salvar"
                                               icon="save"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-route class="w-100" :items="itemsTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <button-submit @click="modal = true" class="mb-3" icon="plus"/>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.account_type_subs.length === 0">
                        <p>Não há nada cadastrado</p>
                    </div>

                    <div v-else>
                        <div v-for="(data, index) in formData.account_type_subs" :key="index"
                             class="bg-body-secondary p-4">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <label>Status:</label>
                                    <Multiselect disabled v-model="data.status" :options="selectActiveInative"
                                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-xxl-4 mt-0">
                                    <div class="btn-group" role="group" aria-label="Basic example">
                                        <button-submit @click="editSub(data)" icon="edit"/>
                                        <button-submit @click="destroySub(data)" icon="trash"/>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "AccountTypeUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        ErrorComponent,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Tipo de conta")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/account-type/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/account-type/create"
            },

            create: false,

            formData: {
                name: "",
                status: "",
                obs: "",
                account_type_subs: []
            },

            sub_account_type: {
                account_type_id: this.id,
                name: "",
                status: ""
            },

            edit: false,
            modal: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadAccountType', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateAccountType', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Tipo de conta atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        submitSub() {
            this.$store.dispatch('storeSubAccountType', this.sub_account_type)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.modal = false
                    this.loadItems()
                    toast.success("Tipo de conta atualizado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.loadItems()
                this.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitSubEdit() {
            this.$store.dispatch('updateSubAccountType', this.sub_account_type)
                .then(() => {
                    this.showError = false
                    this.sendForm = false
                    this.errors = []
                    this.modal = false
                    this.loadItems()
                    toast.success("Tipo de conta atualizado com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.showError = true;
                this.sendForm = false
                this.loadItems()
                this.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        destroySub(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroySubAccountType', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        editSub(data) {
            this.sub_account_type.id = data.id
            this.sub_account_type.name = data.name
            this.sub_account_type.status = data.status

            this.modal = true
            this.edit = true
        },

        closeSub() {
            this.sub_account_type = {
                account_type_id: this.id,
                name: "",
                status: ""
            }

            this.modal = false
            this.edit = false
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 26)
                .then((r) => {
                    if (r.data.indexOf('account-type-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('account-type-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.modal-w {
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100vh;
    place-items: center;
}

.modal-w .modal-p {
    width: 50%;
    height: auto;
    background-color: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}
</style>