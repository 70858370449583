import MainComponent from "@/components/dashboard/MainComponent";

import UserSearch from "@/views/search/UserSearch";
import UserCreate from "@/views/create/UserCreate";
import UserUpdate from "@/views/update/UserUpdate";
import UserShow from "@/views/show/UserShow";
import UserUpdatePhoto from "@/views/update/UserUpdatePhoto";
import UserUpdatePassword from "@/views/update/UserUpdatePassword";

export default {
    path: "/user",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "user-search", component: UserSearch},
        {path: "create", name: "user-create", component: UserCreate},
        {path: "edit/:id", name: "user-edit", props: true, component: UserUpdate},
        {path: "show/:id", name: "user-show", props: true, component: UserShow},
        {path: "photo", name: "user-photo", component: UserUpdatePhoto},
        {path: "password", name: "user-password", component: UserUpdatePassword}
    ]
}