export default {
    icon: 'list alternate',
    name: 'Cadastro',
    subLinks: [
        {
            name: 'Cliente',
            route: '/client/search',
            pagePermissions: [
                "client-search",
                "client-create",
                "client-edit",
            ],
        },

        {
            name: 'Funcionário',
            route: '/employee/search',
            pagePermissions: [
                "employee-search",
                "employee-create",
                "employee-edit",
            ],
        },

        {
            name: 'Grupo de Usuário',
            route: '/user-group/search',
            pagePermissions: [
                "group-user-search",
                "group-user-create",
                "group-user-edit",
                "group-user-delete",
            ],
        },

        {
            name: 'Usuário',
            route: '/user/search',
            pagePermissions: [
                "user-search",
                "user-create",
                "user-edit",
                "user-delete",
            ],
        },

        {
            name: 'Tutorial',
            route: '/tutorial/search',
            pagePermissions: [
                "video-tutorial-search",
                "video-tutorial-create",
                "video-tutorial-edit",
                "video-tutorial-delete"
            ],
        },
    ],
    pageLinksNavCheck: [],
}