<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Carousel :settings="settings" :items-to-show="3" :wrap-around="false" :breakpoints="breakpoints">
                        <Slide v-for="(data, index) in thumbs" :key="index">
                            <div class="carousel__item">
                                <input v-model="formData.layout" :value="index" type="radio" name="thumbs"
                                       :id="'thumbs' + index">
                                <label :for="'thumbs' + index">
                                    <img :src="data.thumb" alt=""/>
                                </label>
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation/>
                        </template>
                    </Carousel>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome do APP:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo de notícias:</label>
                    <Multiselect disabled v-model="formData.app_url_id" :options="selectNoticeList"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tempo de exibição em segundos:</label>
                    <input-component disabled v-model="formData.time" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect disabled label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {Carousel, Navigation, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "NewsShow",
    components: {
        Slide,
        Navigation,
        Carousel,
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Notícias")
        this.$store.dispatch("loadNoticeList", this.params)
        this.loadStorage()
        this.loadItems()

    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/news/search"
            },

            thumbs: [
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_noticias.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_two_noticias.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/noticias/thumbsnail/g1_three_noticias.jpg'},
            ],

            breakpoints: {
                100: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },

            settings: {
                itemsToShow: 0,
                snapAlign: 'center',
            },


            formData: {
                id: this.id,
                client_id: "",
                layout: "",
                name: "",
                app_url_id: "",
                time: "",
                status: "",
                company_id: "",
                data_source: 1
            },

            params: {
                id: this.id,
                client_id: ""
            },

            showLoader: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadNewsApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 12)
                .then((r) => {
                    if (r.data.indexOf('app-news-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectNoticeList() {
            return this.$store.state.var.selectNoticeList
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },
    }
}
</script>

<style scoped>
.carousel__item {
    width: 100%;
    display: flex;
    font-size: 20px;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

input[type="radio"][id^="cb"] {
    display: none;
}

.carousel__item label {
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.carousel__item label img {
    width: 100%;
    height: auto;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.carousel__item label:before {
    content: " ";
    top: 0;
    left: 5%;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid grey;
    background-color: white;
    transition-duration: 0.4s;
}

.carousel__item input:checked + label:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.carousel__item input[type="radio"] + label::after {
    display: none;
}

.carousel__item input:checked + label img {
    z-index: -1;
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
}
</style>