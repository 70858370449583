<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <div class="tabs effect-3" v-if="showLoader">
                <input type="radio" id="tab-1" name="tab-effect-3" checked>
                <span>
                <i class="cogs icon"></i>
                <span>Sistema</span>
            </span>

                <input type="radio" id="tab-2" name="tab-effect-3">
                <span>
                <i class="user circle icon"></i>
                <span>Methoduz API</span>
            </span>

                <div class="line ease"></div>
                <div class="tab-content">
                    <section id="tab-item-1">
                        <form>
                            <row-component>
                                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                                   :class="{ 'formError': errors.permissions }"
                                                   v-for="(permission, index) in permissionsPage.sistema" :key="index">
                                    <div class="form-check check-box-header">
                                        <input class="form-check-input" type="checkbox" @click="checkAllSystem(index)" :value="index"
                                               :id="'checkBoxMaster-system' + index" :name="index">
                                        <label class="form-check-label">
                                            {{ permission.label }}
                                        </label>
                                    </div>
                                    <div class="form-check" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                                        <input type="checkbox" :value="option.value" :id="'checkOne' + option.value"
                                               :class="'form-check-input checkBoxAll-system' + index" @click="checkOneSystem(index, permission)">
                                        <label class="form-check-label">
                                            {{ option.label }}
                                        </label>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </form>
                    </section>

                    <section id="tab-item-2">
                        <form>
                            <row-component>
                                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                                   :class="{ 'formError': errors.permissions }"
                                                   v-for="(permission, index) in permissionsPage['methoduz-api']" :key="index">
                                    <div class="form-check check-box-header">
                                        <input class="form-check-input" type="checkbox" @click="checkAllMethoduz(index)" :value="index"
                                               :id="'checkBoxMaster-methoduz' + index" :name="index">
                                        <label class="form-check-label">
                                            {{ permission.label }}
                                        </label>
                                    </div>
                                    <div class="form-check" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                                        <input type="checkbox" :value="option.value" :id="'checkOne' + option.value"
                                               :class="'form-check-input checkBoxAll-methoduz' + index" @click="checkOneMethoduz(index, permission)">
                                        <label class="form-check-label">
                                            {{ option.label }}
                                        </label>
                                    </div>
                                </row-col-component>
                            </row-component>
                        </form>
                    </section>
                </div>
            </div>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="submit" class="w-100" icon="plus circle"
                                   name="Adicionar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit.vue";
import ButtonRoute from "@/components/buttons/ButtonRoute.vue";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent.vue";

const toast = useToast()
export default {
    name: "UserGroupCreate",
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Grupo de Usuários")
        this.$store.dispatch("loadPermissionsPage")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user-group/search"
            },

            formData: {
                name: "",
                status: "",
                permissions: []
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeGroup', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Grupo de usuário criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'user-group-edit', params: {id: r.id}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        checkOneMethoduz(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll-methoduz" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster-methoduz" + index).checked = cont >= permission.options.length;
        },

        checkOneSystem(index, permission) {
            let listPermission = document.getElementsByClassName("checkBoxAll-system" + index);
            let cont = 0;
            for (let i = 0; i < listPermission.length; ++i) {
                if (listPermission[i].checked) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                    cont = cont + 1;
                } else {
                    listPermission[i].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[i].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
            document.getElementById("checkBoxMaster-system" + index).checked = cont >= permission.options.length;
        },

        checkAllSystem(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll-system" + index);
            if (document.getElementById("checkBoxMaster-system" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
        },

        checkAllMethoduz(index) {
            let listPermission = document.getElementsByClassName("checkBoxAll-methoduz" + index);
            if (document.getElementById("checkBoxMaster-methoduz" + index).checked) {
                for (let i = 0; i < listPermission.length; ++i) {
                    listPermission[i].checked = true;
                    if (!this.formData.permissions.includes(parseInt(listPermission[i].value))) {
                        this.formData.permissions.push(parseInt(listPermission[i].value))
                    }
                }
            } else {
                for (let cont = 0; cont < listPermission.length; ++cont) {
                    listPermission[cont].checked = false;
                    let indexArray = this.formData.permissions.indexOf(parseInt(listPermission[cont].value));
                    if (indexArray !== -1) {
                        this.formData.permissions.splice(indexArray, 1);
                    }
                }
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        permissionsPage() {
            return this.$store.state.var.permissionsPage
        }
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>