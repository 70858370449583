<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                    <label>Sexo:</label>

                    <input disabled type="radio" id="nao-phone" name="teste" v-model="formData.sexo" value="1">
                    <label for="nao-phone">Feminino</label>

                    <input disabled type="radio" id="sim-phone" name="teste" v-model="formData.sexo" value="2">
                    <label for="sim-phone">Masculino</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Email:</label>
                    <input-component disabled type-input="email" v-model="formData.email"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Grupo:</label>
                    <Multiselect disabled v-model="formData.group_id" :searchable="true" :options="selectGroupList"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "UserUpdate",
    components: {
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadGroupList")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user/search"
            },

            formData: {
                name: "",
                sexo: "",
                status: "",
                email: "",
                group_id: "",
                photo: '',
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 2)
                .then((r) => {
                    if (r.data.indexOf('user-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectGroupList() {
            return this.$store.state.var.selectGroupList
        },
    }
}
</script>