<template>
    <div class="modal-w d-grid position-fixed" v-if="modal">
        <div class="modal-p shadow-lg" v-if="modal">
            <div class="tag"
                 :class="[{'entrance-color': parseInt(put.type) === 1, 'exit-color': parseInt(put.type) === 2}]">

                <i style="line-height: 18px"
                   :class="[{'arrow alternate circle up outline': parseInt(put.type) === 1,
                'arrow alternate circle down outline': parseInt(put.type) === 2}, 'icon']"></i>

                {{ stringEntranceExit(put.type) }}

                <div @click="closeModal" role="button" class="btn-close"></div>
            </div>

            <form class="p-3">
                <row-component>
                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Data de Criação:</label>
                        <p>
                            <i class="calendar icon"></i>
                            {{ put.date_create }}
                        </p>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Horário de criação:</label>
                        <p>
                            <i class="clock icon"></i>
                            {{ put.hour_create }}
                        </p>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                        <label>Valor:</label>
                        <p>
                            <i class="money icon"></i>
                            {{ put.price }}
                        </p>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <label>Conta Bancária:</label>
                        <Multiselect label="name" v-model="put.bank_accounts_id" :options="selectBankAccountList"
                                     :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <label>Vencimento:</label>
                        <input-component v-mask="'##/##/####'" v-model="put.due_date" type-input="text"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <label>Tipo de Conta:</label>
                        <Multiselect v-model="put.account_types_id" :options="selectAccountTypeList"
                                     @select="selectAccountTypePut(put.account_types_id)"
                                     :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <label>Tipo de Sub Conta:</label>
                        <Multiselect v-model="put.account_type_sub_id" :options="selectSubAccountTypeList"
                                     :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Valor Pago:</label>
                        <input-component v-money="MONEY" v-model="put.price_paid" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Data de Efetivação:</label>
                        <input-component v-mask="'##/##/####'" v-model="put.effective_date" type-input="text"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                        <label>Antecipado:</label>
                        <Multiselect v-model="put.attended" :options="selectYesNo"
                                     :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Observação:</label>
                        <text-area v-model="put.obs" :rows="5"/>
                    </row-col-component>
                </row-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-submit v-if="!low" name="Salvar" icon="save" @click="submitEdit"/>
                        <button-submit v-else name="Baixar" icon="arrow down" @click="submitLow"/>
                    </row-col-component>
                </row-component>
            </form>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Mês:</label>
                    <Multiselect v-model="formData.month" :options="selectMonth" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Ano:</label>
                    <Multiselect v-model="formData.year" :options="selectYear()" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Conta Bancária:</label>
                    <Multiselect label="name" v-model="formData.bank_accounts_id" :options="selectBankAccountList"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Entrada/Saída:</label>
                    <Multiselect v-model="formData.type" :options="selectEntranceExit" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_types_id" :options="selectAccountTypeList" :searchable="true"
                                 @select="selectAccountType(formData.account_types_id)" @clear="clearAccountType"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Sub Tipo de Conta:</label>
                    <Multiselect v-model="formData.account_type_sub_id" :options="selectSubAccountTypeList"
                                 :searchable="true" :disabled="disabled"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Antecipado:</label>
                    <Multiselect v-model="formData.attended" :options="selectYesNo" :searchable="true"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit class="me-2" icon="search" @click="submit"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <row-component>
            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                    <p>Total antecipado: {{ object.totalQuantAnticipated }}</p>
                    <p>Valor: <span>R${{ object.totalSumAnticipated }}</span></p>
                    <i class="icon-card-exit  arrow alternate circle down outline icon"></i>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                    <p>Total de entrada: {{ object.totalQuantEntrance }}</p>
                    <p>Valor: <span>R${{ object.totalValueEntrance }}</span></p>
                    <i class="icon-card-entrance  arrow alternate circle up outline icon"></i>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                    <p>Total de saída: {{ object.totalQuantExit }}</p>
                    <p>Valor: <span>R${{ object.totalValueExit }}</span></p>
                    <i class="icon-card-exit arrow alternate circle down outline icon"></i>
                </div>
            </row-col-component>

            <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                <div class="bg-card-alert alert alert-secondary alert-dismissible fade show" role="alert">
                    <p>Subtotal</p>
                    <p>Valor: <span>R${{ object.subtotal }}</span></p>
                    <i class="icon-card-entrance money outline icon"></i>
                </div>
            </row-col-component>
        </row-component>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 12.5%">Código</th>
                <th scope="col" style="width: 12.5%">Conta Bancária</th>
                <th scope="col" style="width: 12.5%">Tipo de Conta <br> Tipo de Sub Conta</th>
                <th scope="col" style="width: 12.5%">Preço</th>
                <th scope="col" style="width: 12.5%">Entrada / Saída</th>
                <th scope="col" style="width: 12.5%">Data de Vencimento</th>
                <th scope="col" style="width: 12.5%">Atrasado <br> Antecipado</th>
                <th scope="col" style="width: 12.5%" v-if="buttonCheck.delete ||buttonCheck.edit || buttonCheck.low">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Conta bancária">{{ data.bank_accounts_name }}</td>
                <td data-label="Tipo de Conta / Tipo de Sub Conta">
                    {{ data.account_types_name }} <br> {{ data.account_types_sub_name }}
                </td>
                <td data-label="Preço">{{ data.price }}</td>
                <td data-label="Entrada/Saída">{{ stringEntranceExit(data.type) }}</td>
                <td data-label="Vencimento">{{ data.due_date }}</td>
                <td data-label="Atrasado/Antecipado">
                    <div class="tag-vanquished">
                        <div v-if="parseInt(data.vanquished) !== 3">
                            {{ stringAttended(data.vanquished) }}
                        </div>
                    </div>
                    <span v-if="parseInt(data.attended) === 2">Antecipado!</span>
                </td>
                <td data-label="Ações" v-if="buttonCheck.delete ||buttonCheck.edit || buttonCheck.low">
                    <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                    <button v-if="buttonCheck.edit" class="btn btn-success btn-edit" @click="loadItem(data.id)">
                        <i class="edit icon"></i>
                    </button>
                    <button v-if="buttonCheck.low" class="btn btn-secondary btn-edit m-0" @click="loadItemLow(data.id)">
                        <i class="arrow down icon"></i>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object.itens" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import TextArea from "@/components/form/TextArea";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import swal from "sweetalert";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "EntranceExitPreviewSearch",
    components: {
        ButtonPagination,
        TextArea,
        ButtonDelete,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("changeNamePage", "Entrada/Saída (Previsão)")
        this.$store.dispatch("loadBankAccountList")
        this.$store.dispatch("loadAccountTypeList")
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/entrance-exit-preview/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                low: false,
                delete: false
            },

            formData: {
                code: "",
                bank_accounts_id: "",
                account_types_id: "",
                account_type_sub_id: "",
                type: "",
                month: this.currentMonth(),
                year: this.currentYear(),
                business: "",
                attended: 2
            },

            put: {
                price: "",
                price_paid: "",
                bank_accounts_id: "",
                due_date: "",
                effective_date: "",
                attended: "",
                obs: "",
                business: "",
                account_types_id: "",
                account_type_sub_id: ""
            },

            MONEY,

            disabled: true,

            low: false,
            modal: false,

            data: {},

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadEntranceExitPreviews", {...this.params, page})
                    .then((r) => {
                        this.data = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        loadItem(id) {
            this.$store.dispatch('loadEntranceExitPreview', id)
                .then((r) => {
                    this.put = r;
                    this.modal = true
                    this.$store.dispatch("loadSubAccountTypeList", this.put.account_types_id)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadItemLow(id) {
            this.$store.dispatch('loadEntranceExitPreview', id)
                .then((r) => {
                    this.put = r;
                    this.modal = true
                    this.low = true
                    this.$store.dispatch("loadSubAccountTypeList", this.put.account_types_id)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        closeModal() {
            this.modal = false
            this.low = false
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.account_types_name}?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyEntranceExitPreview', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        submitEdit() {
            this.$store.dispatch('updateEntranceExitPreview', this.put)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.modal = false
                    this.loadItems(1)
                    toast.success("Entrada e saída atualizada com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        submitLow() {
            this.$store.dispatch('updateLowEntranceExitPreview', this.put)
                .then(() => {
                    this.showError = false
                    this.errors = []
                    this.modal = false
                    this.loadItems(1)
                    toast.success("Baixa realizada com sucesso", {timeout: 1200});
                }).catch((r) => {
                this.showError = true;
                this.errors = r.response.data.errors;
                window.scrollTo(0, 0)
                toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 28)
                .then((r) => {
                    if (r.data.indexOf('entrance-exit-preview-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('entrance-exit-preview-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-low') !== -1) {
                            this.buttonCheck.low = true
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('entrance-exit-preview-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        selectYear() {
            let dataActual = new Date();
            let year = dataActual.getFullYear() + 2;
            let years = [];
            for (let i = 2021; i <= year; i++) {
                years.push(i);
            }
            return years
        },

        currentYear() {
            let date = new Date()
            return date.getFullYear()
        },

        currentMonth() {
            let date = new Date()
            return date.getMonth() + 1
        },

        stringEntranceExit(value) {
            if (parseInt(value) === 1)
                return "Entrada"
            return "Saída"
        },

        stringAttended(value) {
            if (parseInt(value) === 1)
                return "Vence Hoje!"
            else if (parseInt(value) === 2)
                return "Atrasado!"
            return ""
        },

        selectAccountType(id) {
            if (this.formData.account_types_id !== "") {
                this.disabled = false
                this.$store.dispatch("loadSubAccountTypeList", id)
            }
        },

        clearAccountType() {
            this.disabled = true
            this.formData.account_type_sub_id = ""
        },

        selectAccountTypePut(id) {
            if (this.put.account_types_id !== "") {
                this.disabled = false
                this.$store.dispatch("loadSubAccountTypeList", id)
                this.put.account_type_sub_id = ""
            }
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectMonth() {
            return this.$store.state.var.selectMonth
        },

        selectBankAccountList() {
            return this.$store.state.var.selectBankAccountList
        },

        selectEntranceExit() {
            return this.$store.state.var.selectEntranceExit
        },

        selectAccountTypeList() {
            return this.$store.state.var.selectAccountTypeList
        },

        selectSubAccountTypeList() {
            return this.$store.state.var.selectSubAccountTypeList
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },

        object() {
            return this.$store.state.entrance_exit_preview.items
        },

        params() {
            return {
                page: this.object.current_page,
                code: this.formData.code,
                bank_accounts_id: this.formData.bank_accounts_id,
                account_types_id: this.formData.account_types_id,
                account_type_sub_id: this.formData.account_type_sub_id,
                type: this.formData.type,
                month: this.formData.month,
                year: this.formData.year,
                business: this.formData.business,
                attended: this.formData.attended
            }
        }
    }
}
</script>

<style scoped>
.modal-w {
    top: 0;
    left: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    place-items: center;
}

.modal-p {
    width: 60%;
    height: auto;
    background: #FFFFFF;
}

.modal-p .tag {
    width: 100%;
    height: 40px;
    display: flex;
    color: #FFFFFF;
    padding: 0 15px;
    font-weight: bold;
    position: relative;
    align-items: center;
}

.modal-p .tag .btn-close {
    top: 7px;
    right: 7px;
    position: absolute;
}

.entrance-color {
    background-color: #16ab39;
}

.exit-color {
    background-color: #db2828;
}

.tag-vanquished {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tag-vanquished > div {
    color: #fff;
    padding: 0 1rem;
    font-size: 13px;
    font-weight: bold;
    width: fit-content;
    border-radius: 25px;
    background-color: #d67c1c;
}

.btn-edit {
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    font-size: 15px;
    line-height: 36px;
    border-radius: 0;
}

.btn-edit i {
    margin: 0 auto;
}

.bg-card-alert p {
    margin: 0;
}

.bg-card-alert span {
    font-weight: bold;
}

.bg-card-alert {
    background-color: #fdfdfd;
}

@media (max-width: 991px) {
    .modal-w .modal-p {
        width: 95%;
        height: 800px;
        overflow: auto;
    }
}

@media (max-width: 500px) {
    .modal-w .modal-p {
        width: 98%;
        height: 850px;
    }
}
</style>