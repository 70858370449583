import MainComponent from "@/components/dashboard/MainComponent";

import PlayerSearch from "@/views/search/PlayerSearch";
import PlayerCreate from "@/views/create/PlayerCreate";
import PlayerUpdate from "@/views/update/PlayerUpdate";

export default {
    path: "/player",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "player-search", component: PlayerSearch},
        {path: "create", name: "player-create", component: PlayerCreate},
        {path: "edit/:id", name: "player-edit", props: true, component: PlayerUpdate},
    ]
}