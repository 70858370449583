import MainComponent from "@/components/dashboard/MainComponent";

import ContractModelSearch from "@/views/search/ContractModelSearch";
import ContractModelCreate from "@/views/create/ContractModelCreate";
import ContractModelUpdate from "@/views/update/ContractModelUpdate";
import ContractModelShow from "@/views/show/ContractModelShow";

export default {
    path: "/contract-model",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "contract-model-search", component: ContractModelSearch},
        {path: "create", name: "contract-model-create", component: ContractModelCreate},
        {path: "edit/:id", name: "contract-model-edit", component: ContractModelUpdate, props: true},
        {path: "show/:id", name: "contract-model-show", component: ContractModelShow, props: true},
    ]
}