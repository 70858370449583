<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="clearfix mb-3">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="box icon"></i>
                <span>Lotes</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="aux mb-4 mt-3">
                        <div class="avatar-form">
                            <img v-if="formData.product_photo" :src="formData.product_photo" class="avatar__image"
                                 alt="">
                            <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                        </div>
                        <p>{{ formData.name }}</p>
                        <router-link :to="{ name: 'product-photo', id: this.id }" class="figure">
                            <i class="camera icon"></i>
                        </router-link>
                    </div>

                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.minimum_stock}">
                                <label>Estoque Mínimo:</label>
                                <input-component v-model="formData.minimum_stock" type-input="number"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.classification}">
                                <label>Classificação:</label>
                                <Multiselect v-model="formData.classification" :options="selectClassification"
                                             :searchable="true" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.obs}">
                                <label>Observações:</label>
                                <text-area v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-submit class="w-100" @click="submit" :disabled="sendForm" name="Salvar"
                                               icon="save"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-route class="w-100" :items="itemsTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="aux mb-4 mt-3">
                        <div class="avatar-form">
                            <img v-if="formData.product_photo" :src="formData.product_photo" class="avatar__image" alt="">
                            <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                        </div>
                        <p>{{ formData.name }}</p>
                    </div>

                    <template v-for="(data, index) in formData.batch" :key="index">
                        <div class="bg-body-secondary p-3 mb-3">
                            <div class="clearfix mb-3">
                                <button-submit class="float-end" icon="print"/>
                            </div>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Local:</label>
                                    <input-component disabled v-model="data.name_local" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Nº do Lote:</label>
                                    <input-component disabled v-model="data.number_batch" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Quantidade:</label>
                                    <input-component disabled v-model="data.amount" type-input="text"/>
                                </row-col-component>
                            </row-component>

                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Setor:</label>
                                    <input-component disabled v-model="data.sector" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Coluna:</label>
                                    <input-component disabled v-model="data.column" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Prateleira:</label>
                                    <input-component disabled v-model="data.shelf" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ProductUpdate",
    components: {
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Produto")
        this.$store.dispatch("loadLocalList")
        this.checkACL()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/product/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/product/create"
            },

            formData: {
                name: "",
                status: "",
                price: "",
                minimum_stock: "",
                classification: "",
                obs: "",
                product_photo: "",
                batch: [],
            },

            MONEY,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadProduct', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateProduct', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Produto atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 32)
                .then((r) => {
                    if (r.data.indexOf('product-edit') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectLocalList() {
            return this.$store.state.var.selectLocalList
        },

        selectClassification() {
            return this.$store.state.var.selectClassification
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>