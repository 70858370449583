<template>
    <painel-component :items="itemsOne">

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome do APP:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>URL:</label>
                    <input-component disabled v-model="formData.url" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tempo de exibição em segundos:</label>
                    <input-component disabled v-model="formData.time" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                 :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect disabled v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" label="name" :noOptionsText="noOptionsText"
                                 :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "WebShow",
    components: {
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Web")
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/web/search"
            },

            formData: {
                client_id: "",
                name: "",
                url: "",
                time: "",
                status: "",
                company_id: ""
            },

            params: {
                id: this.id,
                client_id: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadWebApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 17)
                .then((r) => {
                    if (r.data.indexOf('app-web-url-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectNoticeList() {
            return this.$store.state.var.selectNoticeList
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },
    }
}
</script>