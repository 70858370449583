import MainComponent from "@/components/dashboard/MainComponent";

import FileSearch from "@/views/search/FileSearch";

export default {
    path: "/file",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search/:id", name: "file-search", props: true, component: FileSearch}
    ]
}