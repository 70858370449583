import MainComponent from "@/components/dashboard/MainComponent";

import CompanySearch from "@/views/search/CompanySearch";
import CompanyCreate from "@/views/create/CompanyCreate";
import CompanyUpdate from "@/views/update/CompanyUpdate";
import CompanyShow from "@/views/show/CompanyShow";

export default {
    path: "/company",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "company-search", component: CompanySearch},
        {path: "create", name: "company-create", component: CompanyCreate},
        {path: "edit/:id", name: "company-edit", props: true, component: CompanyUpdate},
        {path: "show/:id", name: "company-show", props: true, component: CompanyShow},
    ]
}