import MainComponent from "@/components/dashboard/MainComponent";
import HomeView from "@/views/HomeView";
import LoginView from "@/views/LoginView";

import AccountType from "@/router/modules/account-type";
import App from "@/router/modules/app"
import BankAccount from "@/router/modules/bank-account";
import Campaign from "@/router/modules/campaign";
import Chat from "@/router/modules/chat";
import Client from "@/router/modules/client";
import Clock from "@/router/modules/clock";
import Company from "@/router/modules/company";
import Content from "@/router/modules/content";
import Contract from "@/router/modules/contract";
import ContractModel from "@/router/modules/contract-model";
import Employee from "@/router/modules/employee";
import EntranceExit from "@/router/modules/entrance-exit";
import EntranceExitPreview from "@/router/modules/entrance-exit-preview";
import File from "@/router/modules/file";
import Inventory from "@/router/modules/inventory";
import Local from "@/router/modules/local";
import Lottery from "@/router/modules/lottery";
import MenuTable from "@/router/modules/menu-table";
import News from "@/router/modules/news";
import Player from "@/router/modules/player";
import Product from "@/router/modules/product";
import Receipt from "@/router/modules/receipt";
import ReleaseAccess from "@/router/modules/release-access";
import Spot from "@/router/modules/spot";
import Tutorial from "@/router/modules/tutorial";
import User from "@/router/modules/user";
import UserGroup from "@/router/modules/user-group";
import UserSystem from "@/router/modules/user-system";
import Weather from "@/router/modules/weather";
import Web from "@/router/modules/web";
import Youtube from "@/router/modules/youtube";

const routes = [
    {
        path: '/',
        component: MainComponent,
        children: [
            {path: '/', name: 'home', meta: {auth: true}, component: HomeView},
            {path: '/login', name: 'login', component: LoginView},
        ]
    },

    AccountType,
    App,
    BankAccount,
    Campaign,
    Chat,
    Client,
    Clock,
    Company,
    Content,
    Contract,
    ContractModel,
    Employee,
    EntranceExit,
    EntranceExitPreview,
    File,
    Inventory,
    Local,
    Lottery,
    MenuTable,
    News,
    Player,
    Product,
    Receipt,
    ReleaseAccess,
    Spot,
    Tutorial,
    User,
    UserGroup,
    UserSystem,
    Weather,
    Web,
    Youtube
]

export default routes