<template>
    <painel-component class="shadow-none painel">
        <div class="btn-close" @click="$emit('close')"></div>

        <div class="tabs effect-3 mb-0">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
               <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="cog icon"></i>
                <span>Logs</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1" class="h-100 p-3">
                    <form>
                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Nome:</label>
                                <p class="field-text">{{ formData.name }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Tipo de Mídia:</label>
                                <p class="field-text">{{ formData.type_midia_name }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Segmento:</label>
                                <p class="field-text">{{ formData.segment_name }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Localização:</label>
                                <p class="field-text">{{ formData.spot_name }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Empresa:</label>
                                <p class="field-text">{{ formData.company_name }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Token:</label>
                                <p class="field-text">{{ formData.token }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Criado por:</label>
                                <p class="field-text">{{ formData.creator_user_name }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Ultima Comunicação:</label>
                                <p class="field-text">{{ formData.ultima_comunicacao }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Status Online:</label>
                                <p class="field-text">{{ statusOnline(formData.online) }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Status TV:</label>
                                <p class="field-text">{{ statusTV(formData.tela) }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Modelo:</label>
                                <p class="field-text">{{ formData.modelo }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Versão do Android:</label>
                                <p class="field-text">{{ formData.versao_android }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Versão do Aplicativo:</label>
                                <p class="field-text">{{ formData.versao_aplicativo }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>IP Externo:</label>
                                <p class="field-text">{{ formData.ultimo_ip_externo }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>IP Interno:</label>
                                <p class="field-text">{{ formData.ultimo_ip_interno }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Resolução:</label>
                                <p class="field-text">{{ formData.resolucao_tela }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>MAC WIFI:</label>
                                <p class="field-text">{{ formData.mac_wifi }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>MAC ETHERNET:</label>
                                <p class="field-text">{{ formData.mac_ethernet }}</p>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Root:</label>
                                <p class="field-text">{{ statusRoot(formData.root) }}</p>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                                <label>Memória Usada:</label>
                                <p class="field-text">{{ formData.memoria }}</p>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2" class="h-100 p-3">

                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';

import {useToast} from "vue-toastification";

const toast = useToast()

export default {
    name: "PlayerInfo",
    components: {
        PainelComponent,
        RowColComponent,
        RowComponent,
    },

    created() {
        this.loadItems()
    },

    props: {
        id: {
            required: true
        }
    },

    data() {
        return {
            formData: {
                name: "",
                token: "",
                creator_user_name: "",
                online: "",
                tela: "",
                modelo: "",
                versao_android: "",
                versao_aplicativo: "",
                ultimo_ip_externo: "",
                ultimo_ip_interno: "",
                resolucao_tela: "",
                mac_wifi: "",
                root: "",
                memoria: "",
                type_midia_name: "",
                segment_name: "",
                spot_name: "",
                company_name: "",
                ultima_comunicacao: ""
            },
        }
    },

    methods: {
        loadItems() {
            let params = {
                id: this.id,
                client_id: localStorage.getItem("id_client")
            }

            this.$store.dispatch("loadPlayerInfo", params).then((response) => {
                this.formData = response
            }).catch(() => {
                toast.error("Erro desconhecido", {timeout: 1200})
            })
        },

        statusOnline(value) {
            if (parseInt(value) === 1)
                return "Offline"
            return "Online"
        },

        statusTV(value) {
            if (parseInt(value) === 1)
                return "Desligada"
            return 'Ligada'
        },

        statusRoot(value) {
            if (parseInt(value) === 1)
                return "Não"
            return "Sim"
        },
    },

}

</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: calc(100% / 2);
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.painel {
    width: 80%;
    height: 85%;
    margin-top: 1%;
}

.field-text {
    margin: 0;
    height: 42px;
    color: black;
    padding: 0.5rem;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    background-color: #f0f0f0;
    text-overflow: ellipsis;
}

label {
    left: 10px;
    color: #1d1d1b;
    margin-bottom: 1%;
    position: relative;
    text-transform: uppercase;
}

label::before {
    content: '';
    position: absolute;
    width: 3px;
    left: -10px;
    height: 100%;
    background-color: #F97F2C;
}

.btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

@media (max-width: 1024px) {
    .painel {
        width: 95%;
        height: 95%;
        overflow-y: scroll;
        padding-top: 2.5rem;
    }

    .painel .tab-content {
        height: 85vh;
    }
}

@media (max-width: 991px) {
    .painel-component {
        margin-top: 5%;
    }
}
</style>
