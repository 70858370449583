import MainComponent from "@/components/dashboard/MainComponent";

import EmployeeSearch from "@/views/search/EmployeeSearch";
import EmployeeCreate from "@/views/create/EmployeeCreate";
import EmployeeUpdate from "@/views/update/EmployeeUpdate";
import EmployeeShow from "@/views/show/EmployeeShow";
import EmployeeUpdatePhoto from "@/views/update/EmployeeUpdatePhoto";

export default {
    path: "/employee",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "employee-search", component: EmployeeSearch},
        {path: "create", name: "employee-create", component: EmployeeCreate},
        {path: "edit/:id", name: "employee-edit", props: true, component: EmployeeUpdate},
        {path: "show/:id", name: "employee-show", props: true, component: EmployeeShow},
        {path: "photo/:id", name: "employee-photo", props: true, component: EmployeeUpdatePhoto},
    ]
}