<template>
    <painel-component :items="itemsOne">
        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="cog icon"></i>
                <span>Equipamento</span>
            </span>

            <input type="radio" id="tab-3" name="tab-effect-3">
            <span>
                <i class="clock icon"></i>
                <span>Horário de funcionamento</span>
            </span>

            <input type="radio" id="tab-4" name="tab-effect-3">
            <span>
                <i class="wifi icon"></i>
                <span>Senhas WIFI</span>
            </span>

            <input type="radio" id="tab-5" name="tab-effect-3">
            <span>
                <i class="users icon"></i>
                <span>Responsáveis</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component disabled v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <Multiselect disabled v-model="formData.status" :searchable="true"
                                             :options="selectActiveInative"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Empresa:</label>
                                <Multiselect disabled label="name" v-model="formData.company_id" :searchable="true"
                                             :options="selectCompanyList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Estado:</label>
                                <Multiselect disabled v-model="formData.state" :searchable="true"
                                             :options="selectStateList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                                <label>Cidade:</label>
                                <Multiselect disabled v-model="formData.city" :searchable="true"
                                             :options="selectCityList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>CEP:</label>
                                <input-component disabled v-mask="'#####-###'" v-model="formData.cep"
                                                 type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                <label>Bairro:</label>
                                <input-component disabled v-model="formData.district" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Endereço:</label>
                                <input-component disabled v-model="formData.address" type-input="text"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Referência:</label>
                                <text-area rows="5" disabled v-model="formData.complement"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>
                <section id="tab-item-2">
                    <row-component v-if="formData.equipments.length === 0">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                            <div class="alert alert-secondary" role="alert">
                                Não há equipamento cadastrado
                            </div>
                        </row-col-component>
                    </row-component>

                    <template v-for="(data, index) in formData.equipments" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>
                <section id="tab-item-3">
                    <row-component v-if="formData.openingHours.length === 0">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                            <div class="alert alert-secondary" role="alert">
                                <i class="clock icon"></i>
                                Não há horário cadastrado
                            </div>
                        </row-col-component>
                    </row-component>

                    <template v-for="(data, index) in formData.openingHours" :key="index">
                        <div class="bg-body-secondary p-3 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <input disabled type="radio" id="hour" v-model="data.date_close_check" value="1">
                                    <label for="hour">Horário</label>

                                    <input disabled type="radio" id="holiday" v-model="data.date_close_check" value="2">
                                    <label for="holiday">Desligar Feriado</label>
                                </row-col-component>
                            </row-component>

                            <div v-if="data.date_close_check === '1'">
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Nome:</label>
                                        <input-component disabled v-model="data.name" type-input="text"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Horário:</label>
                                        <input-component disabled v-model="data.hour" type-input="time"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Ligar / Desligar:</label>
                                        <Multiselect disabled v-model="data.type" :options="selectOnOff"
                                                     :searchable="true"
                                                     :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                                    </row-col-component>
                                </row-component>

                                <row-component>
                                    <row-col-component
                                            :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                        <label>Dias da Semana:</label>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="1">
                                            <label class="form-check-label">Segunda-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="2">
                                            <label class="form-check-label">Terça-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="3">
                                            <label class="form-check-label">Quarta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="4">
                                            <label class="form-check-label">Quinta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="5">
                                            <label class="form-check-label">Sexta-Feira</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="6">
                                            <label class="form-check-label">Sábado</label>
                                        </div>

                                        <div class="form-check form-check-inline">
                                            <input disabled v-model="data.days_weeks" class="form-check-input"
                                                   type="checkbox" value="7">
                                            <label class="form-check-label">Domingo</label>
                                        </div>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <div v-else>
                                <row-component>
                                    <row-col-component
                                            class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                        <label>Nome:</label>
                                        <input-component disabled v-model="data.name" type-input="text"/>
                                    </row-col-component>

                                    <row-col-component
                                            class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                        <label>Data:</label>
                                        <input-component disabled v-model="data.date_close" v-mask="'##/##/####'"
                                                         type-input="text"/>
                                    </row-col-component>
                                </row-component>
                            </div>

                            <row-component>
                                <row-col-component
                                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'">
                                    <label>Player:</label>
                                    <div v-if="data.players.length > 0">
                                        <div class="form-check" v-for="(dataTwo, indexTwo) in data.players"
                                             :key="indexTwo">
                                            <input class="form-check-input" type="checkbox" :value="dataTwo.value">
                                            <label class="form-check-label">{{ dataTwo.name }}</label>
                                        </div>
                                    </div>
                                    <p class="mt-2" v-else>Nenhum player cadastrado nessa localização</p>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>
                <section id="tab-item-4">
                    <row-component v-if="formData.passwordswifi.length === 0">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                            <div class="alert alert-secondary" role="alert">
                                Não há senha cadastrada
                            </div>
                        </row-col-component>
                    </row-component>

                    <template v-for="(data, index) in formData.passwordswifi" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome Rede:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Senha:</label>
                                    <input-component disabled v-model="data.password" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>
                <section id="tab-item-5">
                    <row-component v-if="formData.equipments.length === 0">
                        <row-col-component
                                :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2'">
                            <div class="alert alert-secondary" role="alert">
                                Não há responsáveis cadastrado
                            </div>
                        </row-col-component>
                    </row-component>

                    <template v-for="(data, index) in formData.responsibles" :key="index">
                        <div class="bg-body-secondary p-2 mb-3">
                            <row-component>
                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Nome:</label>
                                    <input-component disabled v-model="data.name" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Função</label>
                                    <input-component disabled v-model="data.function" type-input="text"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                                    <label>Email:</label>
                                    <input-component disabled v-model="data.email" type-input="email"/>
                                </row-col-component>

                                <row-col-component
                                        class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                                    <label>Telefone:</label>
                                    <input-component disabled v-mask="['(##) ####-####', '(##) #####-####']"
                                                     v-model="data.phone" type-input="text"/>
                                </row-col-component>
                            </row-component>
                        </div>
                    </template>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import {mask} from "vue-the-mask";

const toast = useToast()

export default {
    name: "SpotShow",
    components: {
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent,
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Local")
        this.checkACL()
        this.loadStorage()
        this.loadItems()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/spot/search"
            },

            formData: {
                client_id: "",
                company_id: "",
                name: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                status: "",
                equipments: [],
                passwordswifi: [],
                responsibles: [],
                openingHours: []
            },

            params: {
                id: this.id,
                client_id: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.params.client_id = this.idClient
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.$store.dispatch("loadStateList")
            this.$store.dispatch("loadSpotPlayers", this.params)
                .then((r) => {
                    this.listPlayers = r
                })
        },

        loadItems() {
            this.$store.dispatch('loadSpot', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                    this.$store.dispatch("loadCityList", r.state)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 20)
                .then((r) => {
                    if (r.data.indexOf('spot-search') !== -1) {
                        this.showLoader = true

                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectOnOff() {
            return this.$store.state.var.selectOnOff
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectStateList() {
            return this.$store.state.var.selectStateList
        },

        selectCityList() {
            return this.$store.state.var.selectCityList
        },
    }
}
</script>