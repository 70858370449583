import MainComponent from "@/components/dashboard/MainComponent";

import LotterySearch from "@/views/search/LotterySearch";
import LotteryCreate from "@/views/create/LotteryCreate";
import LotteryUpdate from "@/views/update/LotteryUpdate";
import LotteryShow from "@/views/show/LotteryShow";

export default {
    path: "/lottery",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "lottery-search", component: LotterySearch},
        {path: "create", name: "lottery-create", component: LotteryCreate},
        {path: "edit/:id", name: "lottery-edit", props: true, component: LotteryUpdate},
        {path: "show/:id", name: "lottery-show", props: true, component: LotteryShow},
    ]
}