<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-route :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 10%">Código</th>
                <th scope="col" style="width: 15%">Criador</th>
                <th scope="col" style="width: 15%">Produto</th>
                <th scope="col" style="width: 10%">Quantidade</th>
                <th scope="col" style="width: 15%">Data/Hora de Criação</th>
                <th scope="col" style="width: 10%">Status</th>
                <th scope="col" style="width: 10%" v-if="buttonCheck.edit || buttonCheck.show">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Criador">{{ data.creator_user_name }}</td>
                <td data-label="Produto">{{ data.name }}</td>
                <td data-label="Quantidade">{{ data.amount }}</td>
                <td data-label="Data/Hora de Criação">{{ data.date_create }} / {{ data.hour_create }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="product-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="product-show"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object.itens" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ProductSearch",
    components: {
        ButtonPagination,
        ButtonShow,
        ButtonEdit,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Produto")
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/product/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false,
            },

            formData: {
                name: "",
                code: "",
                status: ""
            },

            data: {},

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadProducts", {...this.params, page})
                    .then((r) => {
                        this.data = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 32)
                .then((r) => {
                    if (r.data.indexOf('product-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('product-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('product-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('product-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        object() {
            return this.$store.state.product.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                code: this.formData.code,
                status: this.formData.status,
            }
        }
    }
}
</script>