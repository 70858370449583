<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix mb-3" v-if="create">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="user circle icon"></i>
                <span>Permissões</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.client_id}">
                                <label>Cliente:</label>
                                <Multiselect disabled label="name" v-model="formData.client_id" :searchable="true"
                                             @select="loadNameClient(formData.client_id)"
                                             :options="selectClientList" :noOptionsText="noOptionsText"
                                             :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.status}">
                                <label>Status:</label>
                                <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.logs}">
                                <label>Logs:</label>
                                <Multiselect v-model="formData.logs" :searchable="true" :options="selectYesNo"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component
                                    :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">
                                <label>Pasta:</label>
                                <div class="position-relative w-100" style="height: 43px">
                                    <i class="ms-1 folder icon h-auto position-absolute top-50 start-0 translate-middle-y"></i>
                                    <input-component disabled class="ps-4" type-input="text" v-model="formData.uuid"/>
                                </div>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.quant_user}">
                                <label>Quantidade de Usuários:</label>
                                <input-component type-input="number" v-model="formData.quant_user"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.quant_storage}">
                                <label>Quantidade GB:</label>
                                <input-component type-input="number" v-model="formData.quant_storage"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.quant_player}">
                                <label>Quantidade Player:</label>
                                <input-component type-input="number" v-model="formData.quant_player"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.compress_img}">
                                <label>Compactar Imagem:</label>
                                <Multiselect v-model="formData.compress_img" :searchable="true" :options="selectYesNo"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.compress_video}">
                                <label>Compactar Video:</label>
                                <Multiselect v-model="formData.compress_video" :searchable="true" :options="selectYesNo"
                                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.time_img}">
                                <label>Tempo de imagem (Segundos):</label>
                                <input-component type-input="number" v-model="formData.time_img"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.time_img_trustee}">
                                <label>Tempo de imagem (Sindico):</label>
                                <input-component type-input="number" v-model="formData.time_img_trustee"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError': errors.time_html_trustee}">
                                <label>Tempo HTML (Sindico):</label>
                                <input-component type-input="number" v-model="formData.time_html_trustee"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Salvar"
                                               icon="save"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <button-route class="w-100" :items="itemsTwo"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <row-component>
                        <row-col-component v-for="(data, index) in permissions" :key="index"
                                           :class-row-col="'col-xxl-2 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'">

                            <switch-component :id="'switch-input' + data.value" :text-check-box="data.name"
                                              :input-checked="data.checked" @check-click="submitPermissions(data)"/>
                        </row-col-component>
                    </row-component>
                </section>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import SwitchComponent from "@/components/form/SwitchComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ReleaseAccessUpdate",
    components: {
        SwitchComponent,
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        InputComponent,
        Multiselect,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Liberar Acesso")
        this.$store.dispatch("loadClientList")
        this.loadPermissions()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/release-access/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/release-access/create"
            },

            create: false,

            formData: {
                client_id: "",
                uuid: "",
                permissions: [],
                name: "",
                time_img: "",
                time_img_trustee: "",
                time_html_trustee: "",
                quant_user: "",
                quant_player: "",
                quant_storage: "",
                compress_img: "",
                compress_video: "",
                status: "",
                logs: ""
            },

            permissions: [],

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadReleaseAccess', this.id)
                .then((r) => {
                    this.formData = r;
                    setTimeout(() => {
                        this.switchStart()
                    }, 1000)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateReleaseAccess', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Cadastro atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 8)
                .then((r) => {
                    if (r.data.indexOf('release-access-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('release-access-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadNameClient(id) {
            this.$store.dispatch("loadClient", id).then(r => {
                this.formData.name = r.name
            })
        },

        loadPermissions() {
            this.$store.dispatch("loadReleaseAccessPermissions")
                .then((r) => {
                    this.permissions = r
                }).catch(() => {
                toast.error("Erro desconhecido!", {timeout: 1200});
            })
        },

        switchStart() {
            let permission = this.formData.permissions
            this.permissions.forEach((element) => {
                permission.forEach((value) => {
                    if (value.includes(element.value))
                        document.querySelector(`#switch-input${value} label input`).checked = true
                })
            })
        },

        submitPermissions(data) {
            let params = {
                tenant_id: this.id,
                type: data.value,
                group: data.group,
                checked: document.querySelector(`#switch-input${data.value} label input`).checked
            }

            this.$store.dispatch('storeReleaseAccessPermissions', params).then(() => {
                toast.success("Sucesso ao atualizar permissões", {timeout: 1200});
            }).catch(() => {
                toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectYesNo() {
            return this.$store.state.var.selectYesNo
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}
</style>