<template>
    <input class="form-control" :name="nameInput" :type="typeInput" :value="modelValue"
           @input="$emit('update:modelValue', $event.target.value)">
</template>

<script>
export default {
    name: "InputComponent",

    props: {
        modelValue: {
            default: '',
            required: false
        },

        nameInput: {
            "required": false,
            "type": String,
            "default": ''
        },

        typeInput: {
            "required": true,
            "type": String,
            "default": ''
        },
    },
}
</script>

<style scoped>
.form-control {
    height: 42px;
    border-radius: 0;
    border-color: #282828;
}

.form-control:focus {
    outline: 0;
    color: #212529;
    box-shadow: none;
    border-color: #282828;
    background-color: #fff;
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}
</style>