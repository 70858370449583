<template>
    <section>
        <div class="images">
            <img src="@/assets/login-equipe.jpeg" alt="">
        </div>

        <div class="painel">
            <form @submit.prevent="login">
                <div class="logo">
                    <img src="@/assets/logo.png" alt="">
                </div>

                <div class="container-input">
                    <div class="group-input ">
                        <input class="inputMaterial" type="text" required v-model="formDate.email" autocomplete="on">
                        <label>
                            <i class="envelope icon"></i>
                            E-mail
                        </label>
                    </div>

                    <div class="group-input ">
                        <input class="inputMaterial" type="password" required v-model="formDate.password"
                               autocomplete="on">
                        <label>
                            <i class="lock icon"></i>
                            Senha
                        </label>
                    </div>

                    <button-submit type="submit" @click="login" class="w-100" icon="sign-out" name="ENTRAR"/>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "LoginView",
    components: {
        ButtonSubmit
    },

    data() {
        return {
            formDate: {
                email: '',
                password: ''
            },

            sendForm: false,
        }
    },

    methods: {
        login() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('login', this.formDate)
                    .then(() => {
                        this.sendForm = false;
                        this.$router.push({name: 'home'})
                        toast.success("Login efetuado com sucesso!", {timeout: 1200});
                    }).catch(() => {
                    this.sendForm = false;
                    toast.error("E-mail ou senha inválida", {timeout: 1200});
                })
            }
        }
    },
}
</script>

<style scoped>

section {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #f7f7f7;
}

form {
    height: 75%;
    width: 400px;
    display: flex;
    padding: 20px;
    margin: 0 auto;
    align-items: center;
    border-radius: 40px;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.images {
    width: 60vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.images > img {
    height: 60%;
    margin-left: 15%;
}

.painel {
    width: 40vw;
    display: flex;
    height: 100vh;
    align-items: center;
}

.logo > img {
    margin-top: 2px;
    max-width: 320px;
}

.container-input {
    width: 82%;
    display: flex;
    margin-top: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}

.group-input {
    font-size: 14px;
    position: relative;
    margin-bottom: 35px;
    font-family: 'Comfortaa', cursive;
}

.inputMaterial {
    width: 300px;
    border: none;
    display: block;
    padding: 10px 10px 10px 5px;
    border-bottom: 1px solid #757575;
}

.inputMaterial:focus {
    outline: none;
}

label {
    left: 5px;
    top: 10px;
    color: #999;
    font-size: 14px;
    position: absolute;
    font-weight: normal;
    pointer-events: none;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
}

.inputMaterial:focus ~ label,
.inputMaterial:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #F25C05;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset;
}

input:-webkit-autofill {
    -webkit-text-fill-color: black !important;
}

@media (max-width: 1090px) {
    body {
        background: white;
    }

    section {
        display: block;
    }

    .images {
        display: none;
    }

    form {
        box-shadow: none;
        justify-content: center;
    }

    .painel{
        width: 100%;
    }
}

@media (max-width: 1440px) {
    form {
        height: 70%;
    }

    .images > img {
        height: 50%;
        margin-left: 10%;
    }
}

@media (max-width: 1259px) {
    form {
        height: 70%;
    }

    .images > img {
        height: 45%;
        margin-left: 7%;
    }
}

@media (max-width: 991px) {
    .group-input,
    form .inputMaterial {
        width: 100%;
    }
}

@media (max-width: 761px) {
    form {
        height: 80%;
        width: 90%;
    }
}
</style>