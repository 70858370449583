<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="isClient" class="me-2" @click="submit" icon="search"/>
                    <button-route v-if="buttonCheck.create && isClient" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <div class="alert alert-warning" role="alert" v-if="!isClient">
            <i class="exclamation triangle icon"></i>
            Selecione um cliente
        </div>

        <table class="table table-striped" v-if="showLoader && isClient">
            <thead>
            <tr>
                <th scope="col" style="width: 30%">Nome</th>
                <th scope="col" style="width: 30%">Status</th>
                <th scope="col" style="width: 15%" v-if="buttonCheck.edit || buttonCheck.show">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in object.data" :key="index">
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Status">{{ statusString(data.status) }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="company-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="company-show"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination v-if="isClient" :pagination="object" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent.vue";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import Multiselect from "@vueform/multiselect";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import {useToast} from "vue-toastification";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonPagination from "@/components/buttons/ButtonPagination.vue";

const toast = useToast()
export default {
    name: "CompanySearch",
    components: {
        ButtonPagination,
        ButtonShow,
        ButtonRoute,
        ButtonEdit,
        Multiselect,
        ButtonSubmit,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Empresa")
        this.$store.dispatch("loadReleasesAccessList")
        this.loadStorage()
        this.loadItems(1)
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/company/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false,
            },

            formData: {
                name: "",
                status: "",
                client_id: "",
            },

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if(this.isClient){
                if (!this.searchSubmit) {
                    this.$store.dispatch("loadCompanies", {...this.params, page})
                        .then((r) => {
                            if (this.searchSubmit) {
                                this.searchSubmit = false;
                                if (r.data.length === 0) {
                                    toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                                }
                            }
                        }).catch(() => {
                        this.searchSubmit = false;
                    });
                }
            }
        },

        loadStorage() {
            this.$store.commit("IS_CLIENT", this.convertBool(localStorage.getItem("isClient")))
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        convertBool(string) {
            return string === "true";
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 9)
                .then((r) => {
                    if (r.data.indexOf('company-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('company-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('company-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('company-create') !== -1) {
                            this.buttonCheck.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        statusString(value) {
            if (parseInt(value) === 1)
                return "Ativo"
            return "Inativo"
        }
    },

    computed: {
        isClient() {
            return this.$store.state.var.isClient
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClient() {
            return this.$store.state.release_access.list
        },

        object() {
            return this.$store.state.company.items
        },

        params() {
            return {
                page: this.object.current_page,
                name: this.formData.name,
                status: this.formData.status,
                client_id: this.idClient,
            }
        },
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>