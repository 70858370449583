<template>
    <painel-component>
        <Bar style="max-height: 650px" :options="chartOptions" :data="chartData"/>
    </painel-component>

</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
    name: "HomeView",
    components: {
        PainelComponent,
        Bar
    },

    created() {
        this.$store.dispatch("changeNamePage", "Home")
        this.loadItems()
    },

    data() {
        return {
            chartData: {
                labels: [],
                datasets: [
                    {
                        label: '',
                        data: [],
                        backgroundColor: '#04589b'
                    },
                ]
            },

            chartOptions: {
                responsive: true
            },
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch("loadHome")
                .then((r) => {
                    let dataTwo = r.registeredPlayerState
                    this.loadCharts(dataTwo)
                })
        },

        loadCharts(data) {
            this.chartData = {
                labels: data.labels,
                datasets: [
                    {
                        label: `Player cadastrados por estados`,
                        data: data.result,
                        backgroundColor: '#F25C05'
                    },
                ]
            }
        },
    }
}
</script>