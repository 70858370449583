<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Produto:</label>
                    <Multiselect v-model="formData.product" :options="selectBusiness" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit class="me-2" @click="submit" icon="search"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 10%">Código</th>
                <th scope="col" style="width: 16.6%">Criador</th>
                <th scope="col" style="width: 20%">Nome</th>
                <th scope="col" style="width: 16.6%">Produto</th>
                <th scope="col" style="width: 16.6%">Data/Hora de criação</th>
                <th scope="col" style="width: 16.6%" v-if="buttonCheck.edit">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Criador">{{ data.creator_user_name }}</td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Produto">{{ stringProduct(data.product) }}</td>
                <td data-label="Data/Hora de criação">{{ data.date_create }}/{{ data.hour_create }}</td>
                <td data-label="Ações" v-if="buttonCheck.edit || buttonCheck.show">
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="contract-edit"/>
                </td>
            </tr>
            </tbody>
        </table>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonEdit from "@/components/buttons/ButtonEdit";

import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ContractSearch",
    components: {
        ButtonRoute,
        ButtonSubmit,
        ButtonEdit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("changeNamePage", "Contrato")
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/contract/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
            },

            formData: {
                code: "",
                name: "",
                status: "",
                product: ""
            },

            data: {},

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadContracts", {...this.params, page})
                    .then((r) => {
                        this.data = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 35)
                .then((r) => {
                    if (r.data.indexOf('contract-search') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('contract-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('contract-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        stringProduct(value) {
            if (parseInt(value) === 1)
                return "Methoduz"
            return "Tag Gold"
        }
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },

        object() {
            return this.$store.state.contract.items
        },

        params() {
            return {
                code: this.formData.code,
                name: this.formData.name,
                status: this.formData.status,
                product: this.formData.product
            }
        }
    }
}
</script>