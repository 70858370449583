import MainComponent from "@/components/dashboard/MainComponent";

import LocalSearch from "@/views/search/LocalSearch";
import LocalCreate from "@/views/create/LocalCreate";
import LocalUpdate from "@/views/update/LocalUpdate";
import LocalShow from "@/views/show/LocalShow";

export default {
    path: "/local",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "local-search", component: LocalSearch},
        {path: "create", name: "local-create", component: LocalCreate},
        {path: "edit/:id", name: "local-edit", component: LocalUpdate, props: true},
        {path: "show/:id", name: "local-show", component: LocalShow, props: true},
    ]
}