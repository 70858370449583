import MainComponent from "@/components/dashboard/MainComponent";

import WebSearch from "@/views/search/WebSearch";
import WebCreate from "@/views/create/WebCreate";
import WebUpdate from "@/views/update/WebUpdate";
import WebShow from "@/views/show/WebShow";


export default {
    path: "/web",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "web-search", component: WebSearch},
        {path: "create", name: "web-create", component: WebCreate},
        {path: "edit/:id", name: "web-edit", props: true, component: WebUpdate},
        {path: "show/:id", name: "web-show", props: true, component: WebShow},
    ]
}