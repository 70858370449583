<template>
    <loader-component/>
    <nav-menu v-if="!currentPage" :active="active" @menu-resp="openMenu"/>

    <div class="main-component" v-if="!currentPage" :class="{'open-menu' : active}">
        <div class="menu-top-bar">
            <h1> {{ namePage }} </h1>
            <div class="menu-toggle" @click="openMenu">
                <div class="hamburger-menu-button">
                    <div class="hamburger-menu" :class="{'open': active}"></div>
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
                <div :key="route.name">
                    <component :is="Component"/>
                </div>
            </transition>
        </router-view>
    </div>

    <div v-else>
        <router-view></router-view>
    </div>
</template>

<script>
import NavMenu from "@/components/nav/NavMenu";
import LoaderComponent from "@/components/alerts/LoaderComponent.vue";

export default {
    name: "MainComponent",
    components: {
        LoaderComponent,
        NavMenu
    },

    data() {
        return {
            active: false
        }
    },

    methods: {
        openMenu() {
            this.active = !this.active
        }
    },

    computed: {
        namePage() {
            return this.$store.state.var.namePage
        },

        currentPage() {
            return this.$route.name === 'login';
        },
    }
}
</script>

<style scoped>
.main-component {
    float: right;
    width: calc(100% - 260px);
    transition: all 0.4s linear;
}

.menu-top-bar {
    height: 75px;
    padding: 20px 2%;
    position: relative;
    background-color: #ebebeb;
    box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
    -moz-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
    -webkit-box-shadow: -3px 44px 38px -25px rgba(184, 184, 184, 1);
}

.menu-top-bar h1 {
    color: #1b1c1d;
    font-size: 30px;
    font-weight: 100;
    padding-left: 60px;
    text-transform: capitalize;
    font-family: 'Comfortaa', cursive;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.open {
    transform: translateY(0px) rotate(45deg);
}

.open::after {
    width: calc(0.75 * 32px);
    transform: translateY(0px) rotate(-90deg);
}

@media (max-width: 991px) {
    .main-component {
        width: 100%;
        float: none;
    }

    .open-menu {
        transform: translate3d(260px, 0, 0);
    }
}
</style>