<template>
  <painel-component :items="itemsOne">
    <error-component :errors="errors" v-if="showError"/>
    <div class="clearfix mb-3" v-if="create">
      <button-route class="float-end" :items="itemsCreate"/>
    </div>

    <div class="tabs effect-3" v-if="showLoader">
      <input type="radio" id="tab-1" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }" checked>
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="info circle icon"></i>
                <span>Informações</span>
            </span>

      <input type="radio" id="tab-2" name="tab-effect-3" v-if="picked === 2">
      <span v-if="picked === 2">
                <i class="user circle icon"></i>
                <span>Sócio</span>
            </span>

      <input type="radio" id="tab-3" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="envelope icon"></i>
                <span>Email</span>
            </span>

      <input type="radio" id="tab-4" name="tab-effect-3" :class="{ 'mobileTab-client': tabMobile }">
      <span :class="{ 'mobileTab-client': tabMobile }">
                <i class="phone square icon"></i>
                <span>Telefone</span>
            </span>

      <input type="radio" id="tab-5" name="tab-effect-3" v-if="picked === 2">
      <span v-if="picked === 2">
                <i class="tasks icon"></i>
                <span>Atividades econômicas secundárias</span>
            </span>

      <div class="line ease" :class="{ 'line-3': tabMobile }"></div>
      <div class="tab-content">
        <section id="tab-item-1">
          <form>
            <div class="aux mb-4 mt-3">
              <div class="avatar-form">
                <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
              </div>
              <p>{{ formData.name }}</p>
              <router-link :to="{ name: 'client-photo', id: this.id }" class="figure">
                <i class="camera icon"></i>
              </router-link>
            </div>

            <row-component v-if="picked === 2">
              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                 :class="{'formError' : errors.cnpj}">
                <label>CNPJ:</label>
                <div class="position-relative">
                  <input-component disabled type-input="text" v-model="formData.cnpj"
                                   v-mask="'##.###.###/####-##'"/>
                  <button @click="updateCNPJ(formData.surname)" type="button"
                          class="btn border-0 position-absolute top-50 end-0 translate-middle-y">
                    <i class="sync icon"></i>
                  </button>
                </div>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>Porte:</label>
                <input-component type-input="text" v-model="formData.size"/>
              </row-col-component>

              <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                 :class="{'formError' : errors.status}">
                <label>Status:</label>
                <Multiselect :searchable="true" :options="selectActiveInative" v-model="formData.status"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  :class="{'formError' : errors.name}">
                <label>Nome:</label>
                <input-component type-input="text" v-model="formData.name"/>
              </row-col-component>
            </row-component>

            <row-component v-if="picked === 2">
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Nome Fantasia:</label>
                <input-component type-input="text" v-model="formData.name_f"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                  :class="{'formError' : errors.surname}">
                <label>Apelido:</label>
                <input-component type-input="text" v-model="formData.surname"/>
              </row-col-component>
            </row-component>

            <row-component v-if="picked === 1">
              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12"
                  :class="{'formError' : errors.cpf}">
                <label>CPF:</label>
                <input-component v-mask="'###.###.###-##'" type-input="text" v-model="formData.cpf"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>RG:</label>
                <input-component v-mask="['##.###.###-X','#.###.###']" type-input="text"
                                 v-model="formData.rg"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Data de Nascimento:</label>
                <input-component type-input="text" v-model="formData.birth_date"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12"
                  :class="{'formError' : errors.status}">
                <label>Status:</label>
                <Multiselect :searchable="true" :options="selectActiveInative" v-model="formData.status"
                             :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
              </row-col-component>
            </row-component>

            <div v-if="picked === 2">
              <row-component>
                <row-col-component
                    class-row-col="col-xxl-6 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Natureza Jurídica:</label>
                  <input-component type-input="text" v-model="formData.legal_nature"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Status da Empresa:</label>
                  <input-component type-input="text" v-model="formData.situation"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Capital Social:</label>
                  <input-component v-money="money" type-input="text"
                                   v-model="formData.share_capital"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    class-row-col="col-xxl-6 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Atividade Econômica Principal:</label>
                  <input-component type-input="text" v-model="formData.main_activity"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Data de Abertura:</label>
                  <input-component v-mask="'##/##/####'" type-input="text"
                                   v-model="formData.date_open"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Matriz:</label>
                  <input-component type-input="text" v-model="formData.headquarters"/>
                </row-col-component>
              </row-component>
            </div>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>CEP:</label>
                <input-component v-mask="'#####-###'" @change="searchAPICEP" type-input="text"
                                 v-model="formData.cep"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Cidade:</label>
                <input-component type-input="text" v-model="formData.city"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Estado:</label>
                <input-component type-input="text" v-model="formData.state"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-3 col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
                <label>Bairro:</label>
                <input-component type-input="text" v-model="formData.district"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <label>Endereço:</label>
                <input-component type-input="text" v-model="formData.address"/>
              </row-col-component>

              <row-col-component
                  class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <label>País:</label>
                <input-component type-input="text" v-model="formData.country"/>
              </row-col-component>
            </row-component>

            <row-component>
              <row-col-component
                  class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label>Refêrencia:</label>
                <text-area rows="5" v-model="formData.complement"/>
              </row-col-component>
            </row-component>
          </form>
        </section>

        <section id="tab-item-2">
          <div class="clearfix mb-3">
            <button-submit @click="addMembers" icon="plus" class="rounded-0 float-end"/>
          </div>

          <form>
            <div class="bg-body-secondary p-3 mb-3"
                 v-for="(data, index) in membersAdd" :key="index">
              <row-component>
                <row-col-component
                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label>Nome:</label>
                  <input-component v-model="data.name" type-input="text"/>
                </row-col-component>
              </row-component>

              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>CPF / CNPJ:</label>
                  <input-component v-model="data.cpf_cnpj" type-input="text"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Idade:</label>
                  <input-component v-model="data.age" type-input="text"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                  <label>Qualificação:</label>
                  <input-component v-model="data.qualification" type-input="text"/>
                </row-col-component>
              </row-component>

              <div class="clearfix" v-if="membersAdd.length > 1">
                <button-delete @click="removeMembers(index)" class="rounded-0 float-end"/>
              </div>
            </div>
          </form>
        </section>

        <section id="tab-item-3">
          <div class="clearfix mb-3">
            <button-submit @click="addEmails" icon="plus" class="rounded-0 float-end"/>
          </div>

          <form>
            <div class="bg-body-secondary p-3 mb-3"
                 v-for="(data, index) in emailsAdd" :key="index">
              <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Receber notificação?</label>

                <input type="radio" :id="'no-email' + index" v-model="data.notification" value="1">
                <label :for="'no-email' + index">Não</label>

                <input type="radio" :id="'yes-email'+ index" v-model="data.notification" value="2">
                <label :for="'yes-email'+ index">Sim</label>
              </row-col-component>

              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Nome:</label>
                  <input-component v-model="data.name" type-input="text"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Email:</label>
                  <input-component v-model="data.email" type-input="email"/>
                </row-col-component>
              </row-component>

              <div class="clearfix" v-if="emailsAdd.length > 1">
                <button-delete @click="removeEmails(index)" class="rounded-0 float-end"/>
              </div>
            </div>
          </form>
        </section>

        <section id="tab-item-4">
          <div class="clearfix mb-3">
            <button-submit @click="addPhones" icon="plus" class="rounded-0 float-end"/>
          </div>

          <form>
            <div class="bg-body-secondary p-3 mb-3"
                 v-for="(data, index) in phonesAdd" :key="index">
              <row-col-component
                  :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'">
                <label>Receber notificação?</label>

                <input type="radio" :id="'no-phone' + index" v-model="data.notification" value="1">
                <label :for="'no-phone' + index">Não</label>

                <input type="radio" :id="'yes-phone'+ index" v-model="data.notification" value="2">
                <label :for="'yes-phone'+ index">Sim</label>
              </row-col-component>

              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Nome:</label>
                  <input-component v-model="data.name" type-input="text"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Telefone:</label>
                  <input-component v-mask="['(##) ####-####','(##) #####-####']" v-model="data.phone"
                                   type-input="email"/>
                </row-col-component>
              </row-component>

              <div class="clearfix" v-if="phonesAdd.length > 1">
                <button-delete @click="removePhone(index)" class="rounded-0 float-end"/>
              </div>
            </div>
          </form>
        </section>

        <section id="tab-item-5">
          <div class="clearfix mb-3">
            <button-submit @click="addSecondaryActivity" icon="plus" class="rounded-0 float-end"/>
          </div>

          <form>
            <div class="bg-body-secondary p-3 mb-3"
                 v-for="(data, index) in secondaryActivityAdd" :key="index">
              <row-component>
                <row-col-component
                    class-row-col="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Código:</label>
                  <input-component v-model="data.code" type-input="text"/>
                </row-col-component>

                <row-col-component
                    class-row-col="col-xxl-8 col-xl-8 col-lg-6 col-md-12 col-sm-12 col-12">
                  <label>Descrição:</label>
                  <input-component v-model="data.desc" type-input="text"/>
                </row-col-component>
              </row-component>

              <div class="clearfix" v-if="secondaryActivityAdd.length > 1">
                <button-delete @click="removeSecondaryActivity(index)" class="rounded-0 float-end"/>
              </div>
            </div>
          </form>
        </section>

        <row-component>
          <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <button-submit @click="submit" :disabled="sendForm" class="w-100" name="Salvar"
                           icon="save"/>
          </row-col-component>

          <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <button-route class="w-100" :items="itemsTwo"/>
          </row-col-component>
        </row-component>
      </div>
    </div>
  </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import swal from "sweetalert";
import {mask} from "vue-the-mask";
import {VMoney} from 'v-money'
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";

const toast = useToast()
export default {
  name: "ClientUpdate",
  components: {
    ErrorComponent,
    ButtonDelete,
    ButtonRoute,
    ButtonSubmit,
    TextArea,
    Multiselect,
    InputComponent,
    RowColComponent,
    RowComponent,
    PainelComponent
  },

  directives: {
    mask,
    money: VMoney
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch("changeNamePage", "Cliente")
    this.loadItems()
    this.nullInput()
    this.checkACL()
  },

  data() {
    return {
      itemsOne: {
        icon: "edit",
        title: "Editar"
      },

      itemsTwo: {
        icon: "reply all",
        name: "Voltar",
        route: "/client/search"
      },

      itemsCreate: {
        icon: "plus",
        route: "/user/create"
      },

      create: false,

      formData: {
        cpf: "",
        cnpj: "",
        size: "",
        name: "",
        name_f: "",
        surname: "",
        legal_nature: "",
        share_capital: "",
        federative_entity: "",
        main_activity: "",
        date_open: "",
        birth_date: "",
        headquarters: "",
        date_situation: "",
        situation: "",
        reason_situation: "",
        special_date: "",
        special_situation: "",
        members: [],
        emails: [],
        phones: [],
        secondary_activitys: [],
        rg: "",
        photo: "",
        type: "",
        status: "",
        cep: "",
        city: "",
        state: "",
        district: "",
        address: "",
        complement: "",
        country: "",
      },

      picked: 2,
      money: MONEY,

      errors: [],
      sendForm: false,
      showError: false,
      showLoader: false
    }
  },

  methods: {
    loadItems() {
      this.$store.dispatch('loadClient', this.id)
          .then((r) => {
            this.formData = r;
          }).catch(() => {
        toast.error("Erro desconhecido !", {timeout: 1200});
      })
    },

    submit() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store.dispatch('updateClient', this.formData)
            .then(() => {
              this.showError = false
              this.sendForm = false
              this.errors = []
              toast.success("Cliente atualizado com sucesso", {timeout: 1200});
            }).catch((r) => {
          this.showError = true;
          this.sendForm = false
          this.errors = r.response.data.errors;
          window.scrollTo(0, 0)
          toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
        })
      }
    },

    async checkACL() {
      this.$store.dispatch('ACLItems', 1)
          .then((r) => {
            if (r.data.indexOf('client-edit') !== -1) {
              this.showLoader = true

              if (r.data.indexOf('client-create') !== -1) {
                this.create = true
              }
            } else {
              this.$router.push({name: 'home'})
            }
          }).catch(() => {
        this.$router.push({name: 'home'})
      })
    },

    formatDateBR(label) {
      let arr = label.split("-");
      return `${arr[2]}/${arr[1]}/${arr[0]}`;
    },

    searchAPICEP() {
      let cep = this.formData.cep.replace(/\D/g, '');
      const params = {'cep': cep}
      this.$store.dispatch('searchApiCEP', params)
          .then((r) => {
            this.formData.city = r.data.localidade
            this.formData.state = r.data.uf
            this.formData.stateView = r.data.uf
            this.formData.district = r.data.bairro
            this.formData.address = r.data.logradouro
            this.formData.complement = r.data.complemento
          })
    },

    searchAPICNPJ() {
      let cnpj = this.formData.cnpj.replace(/\D/g, '');
      const params = {'cnpj': cnpj}
      this.$store.dispatch('searchApiCNPJ', params)
          .then((r) => {
            this.completeInfos(r.data)
          })
    },

    updateCNPJ(item) {
      swal({
        title: 'Atenção!',
        text: `Deseja realmente atualizar as informações da empresa ${item.surname} ?`,
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,
          },
          confirm: "Sim"
        }
      }).then((result) => {
        if (result) {
          this.searchAPICNPJ
        }
      });
    },

    completeInfos(data) {
      this.formData.name = data.company.name

      if (data.alias != null) {
        this.formData.name_f = data.alias
      } else {
        this.formData.name_f = data.company.name
      }


      this.formData.surname = this.formData.name_f
      this.formData.legal_nature = `${data.company.nature.id} - ${data.company.nature.text}`

      if (data.company.equity != null) {
        this.formData.share_capital = data.company.equity.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      } else {
        this.formData.share_capital = 0
      }

      this.formData.main_activity = `${data.mainActivity.id} - ${data.mainActivity.text}`
      this.formData.date_open = this.formatDateBR(data.founded)
      this.formData.headquarters = data.head ? 'Sim' : 'Não'
      this.formData.company_status = `${data.status.id} - ${data.status.text}`
      this.formData.cep = data.address.zip
      this.formData.city = data.address.city
      this.formData.state = data.address.state
      this.formData.district = data.address.district
      this.formData.address = `${data.address.street}, ${data.address.number}`
      this.formData.country = data.address.country.name
      this.formData.complement = data.address.details
      this.formData.size = `${data.company.size.id} -  ${data.company.size.acronym} - ${data.company.size.text}`

      if (data.company.members.length > 0) {
        this.formData.members = []
        data.company.members.forEach(element => {
          this.formData.members.push({
            name: element.person.name,
            cpf_cnpj: element.person.taxId,
            age: element.person.age,
            qualification: `${element.role.id} - ${element.role.text}`
          })
        });
      }

      if (data.emails.length > 0) {
        this.formData.emails = []
        data.emails.forEach(element => {
          this.formData.emails.push({
            name: element.domain,
            email: element.address,
            notification: '1'
          })
        });
      }

      if (data.phones.length > 0) {
        this.formData.phones = []
        data.phones.forEach(element => {
          this.formData.phones.push({
            name: '',
            phone: `${element.area}${element.number}`,
            notification: '1'
          })
        });
      }

      if (data.sideActivities.length > 0) {
        this.formData.secondary_activitys = []
        data.sideActivities.forEach(element => {
          this.formData.secondary_activitys.push({
            code: `${element.id}`,
            desc: `${element.text}`
          })
        });
      }
    },

    resetForm() {
      this.formData = {
        cpf: "",
        cnpj: "",
        size: "",
        name: "",
        name_f: "",
        surname: "",
        legal_nature: "",
        share_capital: "",
        federative_entity: "",
        main_activity: "",
        date_open: "",
        birth_date: "",
        headquarters: "",
        date_situation: "",
        situation: "",
        reason_situation: "",
        special_date: "",
        special_situation: "",
        members: [],
        emails: [],
        phones: [],
        secondary_activitys: [],
        rg: "",
        status: "",
        cep: "",
        city: "",
        state: "",
        district: "",
        address: "",
        complement: "",
        country: "Brasil",
      }
    },

    checkPeople() {
      this.resetForm()
      if (parseInt(this.formData.type) === 1)
        this.picked = 1
      else
        this.picked = 2
    },

    nullInput() {
      if (this.formData.members.length === 0)
        this.addMembers()
      if (this.formData.emails.length === 0)
        this.addEmails()
      if (this.formData.phones.length === 0)
        this.addPhones()
      if (this.formData.secondary_activitys.length === 0)
        this.addSecondaryActivity()
    },

    addMembers() {
      this.formData.members.push({
        name: "",
        date_entry: "",
        cpf_cnpj: "",
        age: "",
        country: "",
        qualification: ""
      })
    },

    addEmails() {
      this.formData.emails.push({
        name: "",
        email: "",
        notification: 1
      })
    },

    addPhones() {
      this.formData.phones.push({
        name: "",
        phone: "",
        notification: 1
      })
    },

    addSecondaryActivity() {
      this.formData.secondary_activitys.push({
        code: "",
        desc: "",
      })
    },

    removeMembers(index) {
      this.formData.members.splice(index, 1)
    },

    removeEmails(index) {
      this.formData.emails.splice(index, 1)
    },

    removePhone(index) {
      this.formData.phones.splice(index, 1)
    },

    removeSecondaryActivity(index) {
      this.formData.secondary_activitys.splice(index, 1)
    }
  },

  computed: {
    noResultsText() {
      return this.$store.state.var.noResultsText
    },

    noOptionsText() {
      return this.$store.state.var.noOptionsText
    },

    selectActiveInative() {
      return this.$store.state.var.selectActiveInative
    },

    tabMobile() {
      return parseInt(this.picked) !== 2;
    },

    membersAdd() {
      return this.formData.members
    },

    emailsAdd() {
      return this.formData.emails
    },

    phonesAdd() {
      return this.formData.phones
    },

    secondaryActivityAdd() {
      return this.formData.secondary_activitys
    }
  }
}
</script>