<template>
    <div class="modal-window" v-if="modal || info || file_up || preview">
        <painel-component class="shadow-none folder" v-if="modal" :items="itemsEdit">
            <div @click="editFile" role="button" class="btn-close"></div>

            <error-component v-if="showError" :errors="errors"/>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="file_edit.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit :disabled="sendForm" @click="updateFile" icon="save" name="Salvar"/>
                </row-col-component>
            </row-component>
        </painel-component>

        <painel-component class="shadow-none info" v-if="info" :items="itemsInfo">
            <div @click="modalInfo" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome do arquivo:</label>
                    <input-component disabled v-model="fileInfo.name_original" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tipo do arquivo:</label>
                    <input-component disabled v-model="fileInfo.type" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Criador:</label>
                    <input-component disabled v-model="fileInfo.creator_user_name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tamanho:</label>
                    <input-component disabled v-model="fileInfo.size" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Data de envio:</label>
                    <input-component disabled v-model="fileInfo.date_send" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Hora de envio:</label>
                    <input-component disabled v-model="fileInfo.hour_send" type-input="text"/>
                </row-col-component>
            </row-component>
        </painel-component>

        <painel-component class="shadow-none files" v-if="file_up" :items="itemsFile">
            <div @click="modalFile" role="button" class="btn-close"></div>

            <form>
                <div>
                    <label for="upload" class="file-input" role="button" @change="selectFiles">
                        <i class="plus icon"></i>
                        <input id="upload" type="file" multiple accept="image/*,video/*">
                    </label>
                </div>

                <ul>
                    <li class="bg-body-secondary w-100 p-1 d-flex align-items-center justify-content-between mb-2"
                        v-for="(data, index) in files_computed" :key="index">
                        <p class="m-0 w-25 text-truncate">{{ data.file.name }}</p>
                        <span class="ms-2 text-secondary">{{ convertBytes(data.file.size) }}</span>

                        <div>
                            <progress :id="'progress-bar' + index" :value="data.progress" max="100"></progress>
                            {{ data.progress }}%
                        </div>

                        <div>
                            <button id="remove-file" type="button" @click="remove(index)"
                                    class="btn btn-outline-secondary">
                                <i class="trash icon m-0"></i>
                            </button>

                            <div class="file-success" :id="'file-success' + index">
                                Sucesso ao enviar
                                <i class="check icon text-success"></i>
                            </div>

                            <div class="file-erro" :id="'file-erro' + index">
                                Erro ao enviar
                                <i class="close icon text-danger"></i>
                            </div>
                        </div>
                    </li>
                </ul>

                <div v-if="files_computed.length > 0">
                    <button-submit :disabled="sendForm" @click="uploadFiles" icon="plus" name="Adicionar"/>
                </div>
            </form>
        </painel-component>

        <div class="preview" v-if="preview">
            <div @click="preview = false" role="button" class="p-3 bg-light btn-close"></div>

            <img v-if="parseInt(preview_url.type) === 1" :src="preview_url.url" alt="">
            <video v-else :src="preview_url.url" autoplay loop controls></video>
        </div>
    </div>

    <div class="painel" v-if="showLoader">
        <div class="content-header">
            <button class="button-exc d-none d-lg-block" @click="this.$router.push({name: 'content-search'})">
                <i class="reply all icon"></i>
                Voltar
            </button>

            <div class="inputs">
                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </div>

                <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-md-4">
                    <button-submit @click="submit" icon="search"/>
                </div>

                <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-md-4 mx-md-1 mx-3"
                     v-if="buttonCheck.create">
                    <label @click="file_up = true" role="button" class="file-input" for="upload">
                        <i class="upload icon"></i>
                    </label>
                </div>

                <div class="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1 mt-0 mt-md-4 d-lg-none">
                    <button-submit @click="this.$router.push({name: 'content-search'})" icon="reply all"/>
                </div>
            </div>
        </div>

        <row-component class="margin-top">
            <template v-for="(data, index) in object.data" :key="index">
                <row-col-component class-row-col="col-xxl-1 col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                    <div class="box-file">

                        <div class="box-info" @click="previewFile(data)">
                            <i v-if="parseInt(data.type) === 1" class="file image icon"></i>
                            <i v-else class="video icon"></i>
                            <p>{{ data.name_original }}</p>
                        </div>

                        <div class="actions">
                            <button v-if="buttonCheck.edit" @click="editFile(data)">
                                <i class="edit icon"></i>
                            </button>

                            <button v-if="buttonCheck.delete" @click="destroy(data)">
                                <i class="trash icon"></i>
                            </button>

                            <button @click="loadInfo(data)">
                                <i class="info icon"></i>
                            </button>

                            <button @click="previewFile(data)">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </template>
        </row-component>

        <button-pagination :pagination="object" :offset="3" @paginate="loadItems"/>
    </div>
</template>

<script>
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import swal from "sweetalert";
import axios from "axios";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "FileSearch",
    components: {
        ButtonPagination,
        ErrorComponent,
        ButtonSubmit,
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Conteúdo")
        this.checkACL()
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsFile: {
                title: "Selecionar arquivos",
                icon: "pen square"
            },

            itemsEdit: {
                title: "Editar",
                icon: "edit"
            },

            itemsInfo: {
                title: "Informações",
                icon: "info"
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            },

            formData: {
                name: "",
            },

            files: [],

            file_edit: {
                id: "",
                name: "",
                client_id: ""
            },

            fileInfo: {
                creator_user_name: "",
                name_original: "",
                type: "",
                size: "",
                date_send: "",
                hour_send: ""
            },

            preview_url: {
                type: "",
                url: ""
            },

            info: false,
            modal: false,
            file_up: false,
            preview: false,

            contUpload: 0,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadFiles", {...this.params, page})
                    .then((r) => {
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        loadInfo(data) {
            let params = {
                id: data.id,
                client_id: this.idClient
            }

            this.$store.dispatch('loadInfo', params)
                .then((r) => {
                    this.fileInfo = r;
                    this.info = true
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 22)
                .then((r) => {
                    if (r.data.indexOf('content-file-search') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('content-file-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('content-file-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('content-file-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        selectFiles(event) {
            this.files = Array.from(event.target.files).map((file) => ({file, content_folder_id: this.id,}));
        },

        remove(index) {
            this.files = Array.from(this.files)
            this.files.splice(index, 1)
        },

        convertBytes(size) {
            if (size >= 1024 && size < 1024000) {
                size = Math.round((size / 1024) * 100) / 100;
                return size + ' KB'
            } else if (size >= 1024000) {
                size = Math.round((size / 1024000) * 100) / 100;
                return size + " MB"
            }
        },

        uploadFiles() {
            this.sendForm = true

            let removeFile = document.querySelectorAll("#remove-file")
            removeFile.forEach((element) => {
                element.style.display = 'none'
            });

            this.files.forEach((fileObject, index) => {
                const params = {
                    file: fileObject.file,
                    client_id: this.idClient,
                    content_folder_id: this.id
                }

                axios.post("api/v1/system/content-file", params, {
                    headers: {'Content-Type': 'multipart/form-data'},
                    onUploadProgress: (progressEvent) => {
                        fileObject.progress = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                    },
                }).then(() => {
                    document.getElementById('file-success' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        this.loadItems(1)
                        this.sendForm = false
                    }
                }).catch((error) => {
                    document.getElementById('file-erro' + index).style.display = 'block'
                    document.getElementById('progress-bar' + index).style.display = 'none'
                    fileObject.progress = 0;
                    this.contUpload += 1
                    if (this.contUpload === this.files.length) {
                        let err = error.response.data.errors[0].toString()
                        this.loadItems(1)
                        toast.error(err, {timeout: 1200})
                        this.sendForm = false
                    }
                });
            });
        },

        updateFile() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateFile', this.file_edit)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadItems()
                        this.clearFile()
                        this.modal = false
                        toast.success("Arquivo atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroyFile', params).then(() => {
                        this.loadItems(1)
                        toast.success("Arquivo deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        modalInfo() {
            this.info = !this.info
            this.loadItems(1)
        },

        editFile(data) {
            this.modal = !this.modal
            this.file_edit.id = data.id
            this.file_edit.name = data.name_original
            this.file_edit.client_id = this.idClient
        },

        clearFile() {
            this.file_edit = {
                id: "",
                name: "",
                client_id: ""
            }
        },

        modalFile() {
            this.file_up = !this.file_up
            this.files = []
            this.loadItems(1)
        },

        previewFile(data) {
            this.preview_url.url = data.urlShow
            this.preview_url.type = data.type
            this.preview = true
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        files_computed() {
            return this.files
        },

        object() {
            return this.$store.state.file.items
        },

        params() {
            return {
                name: this.formData.name,
                content_folder_id: this.id,
                client_id: this.idClient
            }
        }
    }
}
</script>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-window .folder {
    width: 40%;
    height: auto;
    position: relative;
}

.modal-window .info {
    width: 80%;
    height: auto;
    position: relative;
}

.modal-window > .files {
    width: 40%;
    height: auto;
    min-height: 35%;
}

.modal-window .preview img,
.modal-window .preview video {
    display: block;
    max-width: 80vw;
    max-height: 80vh;
    position: relative;
    background-color: rgba(0, 0, 0, .7);
    box-shadow: 0 5px 20px 2px rgba(0, 0, 0, .7);
    -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, .7);
    transition: transform .3s ease-in-out;
    transition: -webkit-transform .3s ease-in-out;
    -webkit-transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
}

.modal-window .btn-close {
    top: 2%;
    right: 1%;
    position: absolute;
}

.painel {
    padding: 2%;
    position: relative;
    margin: 5% 2% 5% 2%;
    background-color: white;
    box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -moz-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -webkit-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
}

.content-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
}

.content-header .button-exc {
    border: 0;
    width: 110px;
    height: 100%;
    display: grid;
    padding: 0.8rem 0;
    place-items: center;
    background: transparent;
}

input[type="file"] {
    display: none;
}

label.file-input {
    width: 42px;
    height: 42px;
    color: #ffffff;
    padding: 0.1rem;
    font-size: 1.5rem;
    text-align: center;
    background: #F25C05;
}

label.file-input i {
    margin: 0;
}

.modal-window > .files ul {
    list-style: none;
    margin-top: 1rem;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0 0.5rem 0 0;
}

.modal-window > .files ul .file-success,
.modal-window > .files ul .file-erro {
    display: none;
}

progress {
    border-radius: 0;
    border: 1px solid #fbfbfb;
}

progress::-webkit-progress-bar {
    background-color: #fbfbfb;
}

progress::-webkit-progress-value {
    background-color: #FF7700;
}

.content-header .button-exc:hover {
    background: #dddddd;
}

.content-header .inputs {
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.painel .box-file {
    width: 100%;
    height: 150px;
    cursor: pointer;
    text-align: center;
    position: relative;
    background: #dddddd;
    margin-bottom: 40px;
}

.box-info  i {
    font-size: 4rem;
}

.box-file p {
    margin: 0 auto;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.box-file .actions {
    left: 0;
    width: 100%;
    display: flex;
    bottom: -30px;
    position: absolute;
    text-align: center;
}

.box-file .actions button {
    border: 0;
    padding: 0;
    width: 100%;
}

.box-file .actions button > i {
    margin: 0;
}

.box-file .actions button:hover {
    background: #cecece;
}

.margin-top {
    margin-top: 3rem;
}

@media (max-width: 1440px ) {
    .margin-top {
        margin-top: 5rem;
    }

    .content-header .inputs {
        width: 65%;
    }
}

@media (max-width: 1024px) {
    .content-header .inputs {
        width: 85%;
    }

    .painel .box-file {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .content-header .inputs {
        width: 100%;
        justify-content: space-around;
    }
}

@media (max-width: 430px) {
    .modal-window > .files,
    .modal-window .info,
    .modal-window .folder {
        width: 95%;
    }

    .painel .box-file {
        width: calc(100% / 1);
    }

    .content-header {
        height: 140px;
        padding: 0 0.5rem;
    }

    .content-header .inputs {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .margin-top {
        margin-top: 10rem;
    }
}
</style>
