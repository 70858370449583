<template>
    <router-link :to="{name:route, params:{id: id}}" class="btn btn-success button-edit">
        <i class="edit outline icon"></i>
    </router-link>
</template>

<script>
export default {
    name: "ButtonEdit",

    props: {
        route: {
            'type': String,
            "required": true,
        },

        id: {
            'type': Number,
            "required": true,
        }
    },
}
</script>

<style scoped>
.button-edit {
    padding: 0;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    font-size: 15px;
    line-height: 36px;
    border-radius: 0;
}

.button-edit i {
    margin: 0 auto;
}
</style>