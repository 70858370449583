<template>
    <div class="modal-w position-fixed d-grid" v-if="modal">
        <div class="modal-p p-4 shadow-lg position-relative" v-if="modal">
            <div role="button" @click="modal = false" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Pago:</label>
                    <Multiselect v-model="formData.paid" :options="selectEmployeeBankList" :searchable="true"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit name="Confirmar" icon="circle plus" @click="modal = false"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.client_employee_provider_type}">
                    <input type="radio" id="client" v-model="formData.client_employee_provider_type" value="1">
                    <label for="client">Cliente</label>

                    <input type="radio" id="employee" v-model="formData.client_employee_provider_type" value="2">
                    <label for="employee">Funcionário</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.type}">
                    <label>Tipo:</label>
                    <Multiselect v-model="formData.type" :options="selectTypeReceipt" :searchable="true"
                                 @select="suggestionTitle(formData.type)" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.client_employee_provider_id}"
                                   v-if="parseInt(formData.client_employee_provider_type) === 1">
                    <label>Cliente:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id" :options="selectClientList"
                                 :searchable="true" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.client_employee_provider_id}"
                                   v-if="parseInt(formData.client_employee_provider_type) === 2">
                    <label>Funcionário:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 @select="employeeBank(formData.client_employee_provider_id)"
                                 :options="selectEmployeeList" :searchable="true" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.title}">
                    <label>Título:</label>
                    <input-component v-model="formData.title" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.date}">
                    <label>Data:</label>
                    <input-component v-model="formData.date" v-mask="'##/##/####'" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.price}">
                    <label>Valor:</label>
                    <input-component v-model="formData.price" v-money="MONEY" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.paid}">
                    <label>Pago:</label>
                    <div class="input-group">
                        <input v-model="formData.paid" type="text" class="form-control">
                        <span role="button" class="input-group-text" id="basic-addon1" @click="modal = true"
                              v-if="parseInt(formData.client_employee_provider_type) === 2">
                            <i class="search icon"></i>
                        </span>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError' : errors.referent}">
                    <label>Referente:</label>
                    <text-area v-model="formData.referent" style="height: 70px;"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit class="w-100" :disabled="sendForm" @click="submit" name="Adicionar"
                                   icon="circle plus"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ErrorComponent from "@/components/alerts/ErrorComponent";

import {mask} from "vue-the-mask"
import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ReceiptCreate",
    components: {
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("loadClientList")
        this.$store.dispatch("loadEmployeeList")
        this.$store.dispatch("changeNamePage", "Recibo")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/receipt/search"
            },

            MONEY,

            formData: {
                type: "",
                title: "",
                client_employee_provider_type: "2",
                client_employee_provider_id: "",
                date: "",
                price: "",
                paid: "",
                referent: ""
            },

            modal: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeReceipt', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Recibo criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'receipt-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 30)
                .then((r) => {
                    if (r.data.indexOf('receipt-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        employeeBank(id) {
            this.$store.dispatch("loadEmployeeBankList", id)
        },

        suggestionTitle(type) {
            if (parseInt(type) === 1)
                this.formData.title = "Recibo de pagamento"

            if (parseInt(type) === 2)
                this.formData.title = "Recibo de recebimento"

            if (parseInt(type) === 3)
                this.formData.title = "Recibo de pagamento salarial"

            if (parseInt(type) === 4)
                this.formData.title = "Recibo adiantamento salarial"

            if (parseInt(type) === 5)
                this.formData.title = "Recibo de pagamento salarial (Prestador de serviço)"

            if (parseInt(type) === 6)
                this.formData.title = "Recibo adiantamento salarial (Prestador de serviço)"
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployeeList() {
            return this.$store.state.var.selectEmployeeList
        },

        selectEmployeeBankList() {
            return this.$store.state.var.selectEmployeeBankList
        },
    }
}
</script>

<style scoped>
.modal-w {
    top: 0;
    left: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    place-items: center;
}

.modal-w .modal-p {
    width: 40%;
    height: auto;
    background-color: #FFFFFF;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.form-control {
    height: 42px;
    border-radius: 0;
    border-color: #282828;
}

.form-control:focus {
    outline: 0;
    color: #212529;
    box-shadow: none;
    border-color: #282828;
    background-color: #fff;
}

.input-group-text {
    height: 42px;
    color: #FFFFFF;
    line-height: 17px;
    text-align: center;
    border: 1px solid #F25C05;
    background-color: #F25C05;
}
</style>