<template>
    <painel-component :items="itemsOne">

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>CEP:</label>
                    <input-component disabled v-mask="'#####-###'" v-model="formData.cep"
                                     type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Cidade:</label>
                    <input-component disabled v-model="formData.city" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Estado:</label>
                    <input-component disabled v-model="formData.state" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Bairro:</label>
                    <input-component disabled v-model="formData.district" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-9 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                    <label>Endereço:</label>
                    <input-component disabled v-model="formData.address" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Pais:</label>
                    <input-component disabled v-model="formData.country" type-input="text"/>
                </row-col-component>

                <row-component>
                    <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <label>Referência:</label>
                        <text-area disabled v-model="formData.complement" rows="5"/>
                    </row-col-component>
                </row-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import Multiselect from "@vueform/multiselect";
import {mask} from "vue-the-mask";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CompanyUpdate",
    components: {
        Multiselect,
        ButtonRoute,
        TextArea,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        mask
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Empresa")
        this.$store.dispatch("loadReleasesAccessList")
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/company/search"
            },

            formData: {
                client_name: "",
                name: "",
                status: "",
                cep: "",
                city: "",
                state: "",
                district: "",
                address: "",
                complement: "",
                country: ""
            },

            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadCompany', {...this.params})
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 9)
                .then((r) => {
                    if (r.data.indexOf('company-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClient() {
            return this.$store.state.release_access.list
        },

        params() {
            return {
                id: this.id,
                client_id: this.idClient
            }
        }
    }
}
</script>