export default {
    state: {
        loading: false
    },

    mutations: {
        LOADER(state, status) {
            state.loading = status
        }
    }
}