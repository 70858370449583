import MainComponent from "@/components/dashboard/MainComponent.vue";

import ContentSearch from "@/views/search/ContentSearch.vue";

export default {
    path: "/content",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "content-search", component: ContentSearch}
    ]
}