<template>
    <div class="painel">
        <error-component v-if="showError" :errors="errors"/>

        <div @click="$emit('close')" role="button" class="btn-close"></div>

        <row-component>
            <row-col-component class-row-col="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                <label>Nome:</label>
                <input-component v-model="formData.name" type-input="text"/>
            </row-col-component>

            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-0 mt-md-4">
                <button-submit @click="submit" icon="search"/>
            </row-col-component>
        </row-component>

        <row-component>
            <template v-for="(data, index) in object.data" :key="index">
                <row-col-component class-row-col="col-xxl-1 col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                    <input v-model="apps" :value="data.id" type="checkbox" name="thumbs"
                           :id="'thumbs' + index">
                    <label :for="'thumbs' + index">
                        <div class="box-file">
                            <i class="cogs icon mb-4"></i>
                            <p>{{ data.name }}</p>
                        </div>
                    </label>
                </row-col-component>
            </template>
        </row-component>

        <row-component class="pagination submit">
            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <button-submit @click="storeApps(apps)" icon="plus" name="Adicionar"/>
            </row-col-component>
        </row-component>

        <button-pagination class="pagination" :pagination="object" :offset="3" @paginate="loadItems"/>
    </div>
</template>

<script>
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonPagination from "@/components/buttons/ButtonPagination";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "CampaignApp",
    components: {
        ErrorComponent,
        ButtonPagination,
        ButtonSubmit,
        RowColComponent,
        RowComponent,
        InputComponent,
    },

    props: {
        id: {
            required: true
        },
    },

    created() {
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            object: {},

            formData: {
                name: "",
            },

            apps: [],

            errors: [],
            sendForm: false,
            showError: false,
            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadCampaignApps", {...this.params, page})
                    .then((r) => {
                        this.object = r
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
        },

        storeApps(data) {
            let formData = {
                campaign_id: this.id,
                client_id: this.idClient,
                apps: []
            }

            data.forEach(element => {
                formData.apps.push({
                    app_id: element
                })
            });

            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeCampaignApps', formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Aplicativos adicionados com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        }
    },

    computed: {
        idClient() {
            return this.$store.state.var.idClient
        },

        params() {
            return {
                id: this.id,
                name: this.formData.name,
                client_id: this.idClient
            }
        }
    }
}
</script>

<style scoped>

.painel {
    width: 75%;
    height: 60%;
    padding: 2%;
    position: relative;
    margin: 5% 2% 5% 2%;
    background-color: white;
}

.painel .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.painel .box-file {
    width: 100%;
    height: 150px;
    cursor: pointer;
    text-align: center;
    position: relative;
    background: #dddddd;
    margin-bottom: 40px;
}

.box-file > i {
    font-size: 3rem;
}

.box-file p {
    margin: 0 auto;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

input[type="checkbox"] {
    display: none;
}

.painel label {
    padding: 0;
    width: 100%;
    cursor: pointer;
    line-height: unset;
    position: relative;
}

.painel label:before {
    content: " ";
    top: 0;
    left: 5%;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid grey;
    background-color: white;
    transition-duration: 0.4s;
}

.painel input:checked + label:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.painel input[type="checkbox"] + label::after {
    display: none;
}

.pagination,
.pagination.submit {
    right: 2%;
    bottom: 0;
    position: absolute;
}

.pagination.submit {
    left: 2%;
}

@media (max-width: 1024px) {
    .painel {
        width: 95%;
        height: 75%;
    }
}

@media (max-width: 991px) {
    .painel {
        width: 95%;
        height: 90%;
        padding-top: 2rem;
        overflow-y: scroll;
    }

    .pagination,
    .pagination.submit {
        position: unset;
    }
}

</style>