<template>
    <painel-component :items="itemsOne">
        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file outline icon"></i>
                <span>Cláusulas</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <label>Nome:</label>
                                <input-component disabled v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>Valor total:</label>
                                <input-component disabled v-money="MONEY" v-model="formData.price" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>Status:</label>
                                <Multiselect disabled v-model="formData.status" :options="selectActiveInative"
                                             :searchable="true" :noResultsText="noResultsText"
                                             :noOptionsText="noOptionsText"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-2 col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
                                <label>Produto:</label>
                                <Multiselect disabled v-model="formData.product" :options="selectBusiness"
                                             :searchable="true" :noResultsText="noResultsText"
                                             :noOptionsText="noOptionsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <label>Observações:</label>
                                <text-area disabled v-model="formData.obs" :rows="6"/>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <div v-else>
                        <template v-for="(element, index) in formData.itens" :key="index">
                            <div class="accordion rounded-0 mb-4" :id="'accordionExample' + index">
                                <div class="accordion-item rounded-0">
                                    <h2 class="accordion-header rounded-0 position-relative">
                                        <button class="accordion-button rounded-0" type="button"
                                                :class="{'collapsed' : parseInt(element.important) === 2}"
                                                data-bs-toggle="collapse"
                                                :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                :aria-controls="'collapseOne' + index">
                                            Cláusula {{ index + 1 }}
                                        </button>
                                    </h2>

                                    <div :id="'collapseOne' + index" class="accordion-collapse collapse"
                                         :class="{'show' : parseInt(element.important) === 2}"
                                         :data-bs-parent="'#accordionExample' + index">
                                        <div class="accordion-body">
                                            <row-component>
                                                <row-col-component
                                                        class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                    <label>Importante:</label>
                                                    <switch-component class="mt-2" :disabled="true"
                                                                      :input-checked="parseInt(element.important) === 2"/>
                                                </row-col-component>
                                            </row-component>

                                            <QuillEditor style="min-height: 200px; border: 1px solid #d1d5db"
                                                         :contentType="'html'" read-only="read-only" theme="bubble"
                                                         v-model:content="element.item"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </section>

                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </div>
        </div>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import TextArea from "@/components/form/TextArea";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import SwitchComponent from "@/components/form/SwitchComponent";

import {VMoney} from "v-money";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";
import {QuillEditor} from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const toast = useToast()
export default {
    name: "ContractModelShow",
    components: {
        QuillEditor,
        SwitchComponent,
        ButtonRoute,
        TextArea,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    directives: {
        money: VMoney
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Modelo de Contrato")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/contract-model/search"
            },

            MONEY,

            formData: {
                name: "",
                price: "",
                obs: "",
                status: "",
                product: "",
                itens: []
            },

            drag: false,
            important: 1,
            isEdit: false,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean']
            ],

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadContractModel', this.id)
                .then((r) => {
                    this.formData = r;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 34)
                .then((r) => {
                    if (r.data.indexOf('contract-model-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },
    }
}
</script>

<style scoped>
.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}
</style>