import axios from "axios";
import {URL_BASE_SYSTEM} from "@/configs/constants";

const RESOURCE = "user"

export default {

    loadUsersSystem(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_USER_SYSTEM", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeUserSystem(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}${RESOURCE}?client_id=${formData.tenant_id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadUserSystem(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}/${params.id}`, {params})
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateUserSystem(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}${RESOURCE}/${formData.id}?client_id=${formData.tenant_id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    destroyUserSystem(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.delete(`${URL_BASE_SYSTEM}${RESOURCE}/${formData.id}?client_id=${formData.tenant_id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadUserSystemGroup(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}user-group?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_USER_SYSTEM_GROUP", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadUserSystemCompany(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}user-company?client_id=${id}`)
                .then((response) => {
                    context.commit("LOAD_USER_SYSTEM_COMPANY", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    userSystemResetPassword(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}user-reset-password`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    }
}