<template>
    <div class="modal-window" v-if="modal || info">
        <painel-component class="shadow-none folder" v-if="modal" :items="isEdit ? itemsEdit : itemsAdd">
            <div @click="modalFolder" role="button" class="btn-close"></div>

            <error-component v-if="showError" :errors="errors"/>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome:</label>
                    <input-component v-model="folder.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="folder.company_id" :options="selectCompanyList"
                                 :disabled="isEdit"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="isEdit" :disabled="sendForm" @click="updateContent" icon="save" name="Salvar"/>
                    <button-submit v-else :disabled="sendForm" @click="storeContent" icon="circle plus"
                                   name="Adicionar"/>
                </row-col-component>
            </row-component>
        </painel-component>

        <painel-component class="shadow-none info" v-if="info" :items="itemsInfo">
            <div @click="modalInfo" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome da pasta:</label>
                    <input-component disabled v-model="folderInfo.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Quantidade de arquivos:</label>
                    <input-component disabled v-model="folderInfo.total_file" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Criador:</label>
                    <input-component disabled v-model="folderInfo.creator_user_name" type-input="text"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Tamanho:</label>
                    <input-component disabled v-model="folderInfo.total_gb" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <input-component disabled v-model="folderInfo.compay_name" type-input="text"/>
                </row-col-component>
            </row-component>
        </painel-component>
    </div>

    <div class="painel" v-if="showLoader">
        <div class="content-header">
            <button v-if="buttonCheck.create" class="button-exc d-none d-lg-grid" @click="modalFolder">
                <i class="folder outline icon"></i>
                Nova pasta
            </button>

            <div class="inputs">
                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text"/>
                </div>

                <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </div>

                <div v-if="isClient"
                     class="col-xxl-1 col-xl-1 col-lg-1 col-md-12 col-sm-12 col-12 mt-2 mt-md-4">
                    <button-submit v-if="buttonCheck.create" @click="modalFolder" class="d-lg-none" icon="folder"/>
                    <button-submit @click="submit" icon="search"/>
                </div>
            </div>
        </div>

        <row-component class="margin-top">
            <template v-for="(data, index) in object.data" :key="index">
                <row-col-component class-row-col="col-xxl-1 col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                    <div class="box-folder" :data-file="data.totalFiles">
                        <div class="h-100" @click="this.$router.push({name: 'file-search', params:{id: data.id}})">
                            <i class="folder icon"></i>
                            <p>{{ data.name }}</p>
                        </div>

                        <div class="actions">
                            <button v-if="buttonCheck.edit" @click="editFolder(data)">
                                <i class="edit icon"></i>
                            </button>

                            <button v-if="buttonCheck.delete" @click="destroy(data)">
                                <i class="trash icon"></i>
                            </button>

                            <button @click="loadInfos(data)">
                                <i class="info icon"></i>
                            </button>

                            <button @click="this.$router.push({name: 'file-search', params:{id: data.id}})">
                                <i class="eye icon"></i>
                            </button>
                        </div>
                    </div>
                </row-col-component>
            </template>
        </row-component>

        <div class="alert alert-warning" role="alert" v-if="!isClient">
            <i class="exclamation triangle icon"></i>
            Selecione um cliente
        </div>
    </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import InputComponent from "@/components/form/InputComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import PainelComponent from "@/components/dashboard/PainelComponent";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "ContentSearch",
    components: {
        ErrorComponent,
        ButtonSubmit,
        PainelComponent,
        RowColComponent,
        RowComponent,
        InputComponent,
        Multiselect
    },

    created() {
        this.$store.dispatch("changeNamePage", "Conteúdo")
        this.checkACL()
        this.loadStorage()
        this.loadItems(1)
    },

    data() {
        return {
            itemsAdd: {
                title: "Adicionar",
                icon: "pen square"
            },

            itemsEdit: {
                title: "Editar",
                icon: "edit"
            },

            itemsInfo: {
                title: "Informações",
                icon: "info"
            },

            buttonCheck: {
                create: false,
                edit: false,
                delete: false
            },

            formData: {
                name: "",
                company_id: "",
                client_id: ""
            },

            folder: {
                name: "",
                company_id: "",
                client_id: ""
            },

            folderInfo: {
                id: "",
                company_id: "",
                creator_user_id: "",
                creator_user_name: "",
                name: "",
                compay_name: "",
                total_file: "",
                total_gb: "",
                photo_user: ""
            },

            info: false,
            modal: false,
            isEdit: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
            searchSubmit: false
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (this.isClient) {
                if (!this.searchSubmit) {
                    this.$store.dispatch("loadContents", {...this.params, page})
                        .then((r) => {
                            if (this.searchSubmit) {
                                this.searchSubmit = false;
                                if (r.data.length === 0) {
                                    toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                                }
                            }
                        }).catch(() => {
                        this.searchSubmit = false;
                    });
                }
            }
        },

        loadStorage() {
            this.$store.commit("IS_CLIENT", this.convertBool(localStorage.getItem("isClient")))
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
        },

        convertBool(string) {
            return string === "true";
        },

        loadInfos(data) {
            let params = {
                id: data.id,
                client_id: this.idClient
            }

            this.$store.dispatch('loadContent', params)
                .then((r) => {
                    this.folderInfo = r;
                    this.info = true
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 21)
                .then((r) => {
                    if (r.data.indexOf('content-folder-search') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('content-folder-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('content-folder-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('content-folder-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        storeContent() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.folder.client_id = this.idClient
                this.$store.dispatch('storeContent', this.folder)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadItems()
                        this.modalFolder()
                        toast.success("Pasta criado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        updateContent() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.folder.client_id = this.idClient
                this.$store.dispatch('updateContent', this.folder)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        this.loadItems()
                        this.modalFolder()
                        toast.success("Pasta atualizada com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    let params = {
                        id: item.id,
                        client_id: this.idClient
                    }
                    this.$store.dispatch('destroyContent', params).then(() => {
                        this.loadItems(1)
                        toast.success("Pasta deletada com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name} `, {timeout: 1200});
                    })
                }
            });
        },

        modalInfo() {
            this.info = !this.info
            this.loadItems(1)
        },

        modalFolder() {
            this.isEdit = false
            this.modal = !this.modal
            this.folder = {
                name: "",
                company_id: "",
                client_id: ""
            }
        },

        editFolder(data) {
            this.isEdit = true
            this.modal = !this.modal
            this.folder.id = data.id
            this.folder.name = data.name
            this.folder.company_id = data.company_id
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        isClient() {
            return this.$store.state.var.isClient
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        object() {
            return this.$store.state.content.items
        },

        params() {
            return {
                name: this.formData.name,
                company_id: this.formData.company_id,
                client_id: this.idClient
            }
        }
    },

    watch: {
        idClient(old, newValue) {
            this.loadItems(1)
            this.formData.client_id = newValue
        }
    }
}
</script>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 15;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

.modal-window .folder {
    width: 40%;
    height: auto;
    position: relative;
}

.modal-window .info {
    width: 80%;
    height: auto;
    position: relative;
}

.modal-window .btn-close {
    top: 2%;
    right: 1%;
    position: absolute;
}

.painel {
    padding: 2%;
    position: relative;
    margin: 5% 2% 5% 2%;
    background-color: white;
    box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -moz-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -webkit-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
}

.content-header {
    top: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    position: absolute;
    justify-content: space-between;
    border-bottom: 1px solid #000000;
}

.content-header .button-exc {
    border: 0;
    width: 110px;
    height: 100%;
    display: grid;
    padding: 0.8rem 0;
    place-items: center;
    background: transparent;
}

.content-header .button-exc:hover {
    background: #dddddd;
}

.content-header .inputs {
    width: 50%;
    display: flex;
    justify-content: space-around;
}

.painel .box-folder {
    width: 100%;
    height: 150px;
    cursor: pointer;
    text-align: center;
    position: relative;
    background: #dddddd;
    margin-bottom: 40px;
}

.painel .box-folder:before {
    content: attr(data-file);
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;
    position: absolute;
    border-radius: 50%;
    background: #F25C05FF;
}

.box-folder > .h-100 i {
    font-size: 4rem;
}

.box-folder p {
    margin: 0 auto;
    font-size: 15px;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
}

.box-folder .actions {
    left: 0;
    width: 100%;
    display: flex;
    bottom: -30px;
    position: absolute;
    text-align: center;
}

.box-folder .actions button {
    border: 0;
    padding: 0;
    width: 100%;
}

.box-folder .actions button > i {
    margin: 0;
}

.box-folder .actions button:hover {
    background: #cecece;
}

.margin-top {
    margin-top: 3rem;
}

@media (max-width: 1440px ) {
    .margin-top {
        margin-top: 5rem;
    }

    .content-header .inputs {
        width: 65%;
        justify-content: space-between;
    }

    .painel .box-folder {
        width: 70%;
    }
}

@media (max-width: 1024px) {
    .content-header .inputs {
        width: 85%;
    }

    .painel .box-folder {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .content-header {
        height: auto;
        padding: 0.5rem;
    }

    .content-header .inputs {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .margin-top {
        margin-top: 15rem;
    }
}

@media (max-width: 430px) {
    .modal-window .info,
    .modal-window .folder {
        width: 95%;
    }

    .painel .box-folder {
        width: calc(100% / 1);
    }

    .content-header .inputs {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}
</style>
