<template>
    <div class="modal-w position-fixed d-grid start-0 top-0" :class="{'items' : clauses.show}"
         v-if="email || phone || contract_model || clauses.show">
        <div class="modal-p items position-relative p-4 pt-3" v-if="clauses.show">
            <div @click="clausesClose" role="button" class="btn-close"></div>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Importante:</label>
                    <switch-component v-if="!isEdit" @check-click="importantChange" class="mt-2"/>
                    <switch-component v-else :input-checked="parseInt(clauses.formData.important) === 2"
                                      @check-click="importantChange" class="mt-2"/>
                </row-col-component>
            </row-component>

            <QuillEditor style="min-height: 200px" :contentType="'html'" :toolbar="toolbar" theme="snow"
                         v-model:content="clauses.formData.item"/>

            <row-component class="mt-4">
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-submit v-if="!isEdit" @click="clausesAdd" name="Adicionar" icon="plus"/>
                    <button-submit v-else @click="clausesEdit(clauses.formData.index)" name="Salvar" icon="save"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="modal-p p-4 shadow-lg" v-if="contract_model">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Modelo de Contrato:</label>
                    <Multiselect v-model="formData.contract_models_id" :options="selectContractModelList"
                                 :searchable="true" @select="loadContractModel(formData.contract_models_id)"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="contract_model = false" name="Confirmar" icon="plus"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="modal-p p-4 shadow-lg" v-if="email">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Email:</label>
                    <Multiselect v-model="formData.email" :options="selectClientEmailList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="email = false" name="Confirmar" icon="plus"/>
                </row-col-component>
            </row-component>
        </div>

        <div class="modal-p p-4 shadow-lg" v-if="phone">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label>Telefone:</label>
                    <Multiselect v-model="formData.phone" :options="selectClientPhoneList" :searchable="true"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <button-submit @click="phone = false" name="Confirmar" icon="plus"/>
                </row-col-component>
            </row-component>
        </div>
    </div>

    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="tabs effect-3" v-if="showLoader">
            <input type="radio" id="tab-1" name="tab-effect-3" checked>
            <span>
                <i class="info icon"></i>
                <span>Informações</span>
            </span>

            <input type="radio" id="tab-2" name="tab-effect-3">
            <span>
                <i class="file outline icon"></i>
                <span>Cláusulas</span>
            </span>

            <div class="line ease"></div>
            <div class="tab-content">
                <section id="tab-item-1">
                    <div class="clearfix mb-3">
                        <button-submit @click="contract_model = true" class="float-end" icon="search plus"/>
                    </div>

                    <form>
                        <row-component>
                            <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.name}">
                                <label>Nome:</label>
                                <input-component v-model="formData.name" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.product}">
                                <label>Valor:</label>
                                <input-component v-money="MONEY" v-model="formData.contract_value" type-input="text"/>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.product}">
                                <label>Produto:</label>
                                <Multiselect v-model="formData.product" :options="selectBusiness" :searchable="true"
                                             :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component
                                    class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    :class="{'formError' : errors.client_id}">
                                <label>Cliente:</label>
                                <Multiselect v-model="formData.client_id" :options="selectClientList" :searchable="true"
                                             label="name"
                                             @select="loadInfos(formData.client_id)"
                                             :noResultsText="noResultsText" :noOptionsText="noOptionsText"/>
                            </row-col-component>
                        </row-component>

                        <row-component>
                            <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.email}">
                                <label>Email:</label>
                                <div class="input-group">
                                    <input v-model="formData.email" type="email" class="form-control">
                                    <div @click="email = true" class="input-group-text">
                                        <i class="search icon"></i>
                                    </div>
                                </div>
                            </row-col-component>

                            <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                               :class="{'formError' : errors.phone}">
                                <label>Telefone:</label>
                                <div class="input-group">
                                    <input v-mask="['(##) ####-####', '(##) #####-####']" v-model="formData.phone"
                                           type="text" class="form-control">
                                    <div @click="phone = true" class="input-group-text">
                                        <i class="search icon"></i>
                                    </div>
                                </div>
                            </row-col-component>
                        </row-component>
                    </form>
                </section>

                <section id="tab-item-2">
                    <button-submit @click="clauses.show = true" class="mb-3" icon="plus"/>

                    <div class="alert alert-secondary alert-dismissible fade show" role="alert"
                         v-if="formData.itens.length === 0">
                        <p>Não há cláusulas cadastradas</p>
                    </div>

                    <div v-else>
                        <draggable v-model="formData.itens" @start="drag=true" @end="updateOrder" item-key="id">
                            <template #item="{element, index}">
                                <div class="accordion rounded-0 mb-4" :id="'accordionExample' + index">
                                    <div class="accordion-item rounded-0">
                                        <h2 class="accordion-header rounded-0 position-relative">
                                            <button class="accordion-button rounded-0" type="button"
                                                    :class="{'collapsed' : parseInt(element.important) === 2}"
                                                    data-bs-toggle="collapse"
                                                    :data-bs-target="'#collapseOne' + index" aria-expanded="true"
                                                    :aria-controls="'collapseOne' + index">
                                                Cláusula {{ index + 1 }}
                                            </button>

                                            <div class="btn-group" role="group">
                                                <button @click="openEdit(element, index)" type="button"
                                                        class="btn btn-main">
                                                    <i class="edit icon"></i>
                                                </button>
                                                <button @click="removeClauses(index)" type="button"
                                                        class="btn btn-main">
                                                    <i class="trash icon"></i>
                                                </button>
                                            </div>
                                        </h2>

                                        <div :id="'collapseOne' + index" class="accordion-collapse collapse"
                                             :class="{'show' : parseInt(element.important) === 2}"
                                             :data-bs-parent="'#accordionExample' + index">
                                            <div class="accordion-body">
                                                <row-component>
                                                    <row-col-component
                                                            class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                                        <label>Importante:</label>
                                                        <switch-component class="mt-2" :disabled="true"
                                                                          :input-checked="parseInt(element.important) === 2"/>
                                                    </row-col-component>
                                                </row-component>

                                                <QuillEditor :contentType="'html'" theme="bubble" read-only
                                                             v-model:content="element.item"
                                                             style="min-height: 200px; border: 1px solid #d1d5db"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </section>

                <row-component>
                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-submit @click="submit" class="w-100" :disabled="sendForm" name="Adicionar"
                                       icon="circle plus"/>
                    </row-col-component>

                    <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                        <button-route class="w-100" :items="itemsTwo"/>
                    </row-col-component>
                </row-component>
            </div>
        </div>


    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import SwitchComponent from "@/components/form/SwitchComponent";
import draggable from "vuedraggable";

import {QuillEditor} from "@vueup/vue-quill";
import {VMoney} from "v-money";
import {mask} from "vue-the-mask";
import {MONEY} from "@/configs/constants";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "ContractCreate",
    components: {
        QuillEditor,
        SwitchComponent,
        draggable,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    directives: {
        mask,
        money: VMoney
    },

    created() {
        this.$store.dispatch("changeNamePage", "Contrato")
        this.$store.dispatch("loadContractModelList")
        this.$store.dispatch("loadClientList")
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "pen square",
                title: "Adicionar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/contract/search"
            },

            formData: {
                name: "",
                proposal_id: "",
                contract_models_id: "",
                valid: "",
                client_id: "",
                contract_value: "",
                email: "",
                phone: "",
                responsible: "",
                product: "",
                obs: "",
                itens: []
            },

            email: false,
            phone: false,
            contract_model: false,

            clauses: {
                show: false,
                formData: {
                    contract_model_id: this.id,
                    item: "",
                    important: "1",
                    order: ""
                }
            },

            MONEY,

            drag: false,
            important: 1,
            isEdit: false,

            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{'header': 1}, {'header': 2}],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}],
                [{'size': ['small', false, 'large', 'huge']}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}, {'background': []}],
                [{'align': []}],
                ['clean']
            ],

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('storeContract', this.formData)
                    .then((r) => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Contrato criado com sucesso", {timeout: 1200});
                        this.$router.push({name: 'contract-edit', params: {id: r}})
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 35)
                .then((r) => {
                    if (r.data.indexOf('contract-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        loadInfos(id) {
            this.$store.dispatch("loadClientEmailList", id)
            this.$store.dispatch("loadClientPhoneList", id)
        },

        loadContractModel(id) {
            this.$store.dispatch("loadContractModelGetList", id)
                .then((r) => {
                    this.formData.name = r.name
                    this.formData.contract_value = r.price
                    this.formData.obs = r.obs
                    this.formData.itens = r.itens
                })
        },

        clausesClose() {
            this.isEdit = false
            this.clauses.show = false
            this.clauses.formData.item = ""
            this.clauses.formData.important = 1
            this.important = 1
        },

        clausesAdd() {
            let order = this.formData.itens.length + 1

            this.formData.itens.push({
                item: this.clauses.formData.item,
                important: this.important,
                order: order
            })
            this.clausesClose()
        },

        updateOrder() {
            this.drag = false
            this.formData.itens.forEach((element, index) => element.order = index)
        },

        openEdit(data, index) {
            this.isEdit = true
            this.clauses.show = true
            this.clauses.formData = {
                index: index,
                item: data.item,
                important: data.important,
            }
        },

        clausesEdit(index) {
            this.formData.itens[index].item = this.clauses.formData.item
            this.formData.itens[index].important = this.important
            this.clausesClose()
        },

        removeClauses(index) {
            this.formData.itens.splice(index, 1)
        },

        importantChange() {
            if (parseInt(this.important) === 1) {
                this.important = 2
            } else {
                this.important = 1
            }
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectBusiness() {
            return this.$store.state.var.selectBusiness
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectClientEmailList() {
            return this.$store.state.var.selectClientEmailList
        },

        selectClientPhoneList() {
            return this.$store.state.var.selectClientPhoneList
        },

        selectContractModelList() {
            return this.$store.state.var.selectContractModelList
        },
    }
}
</script>

<style scoped>
.modal-w {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    place-items: center;
}

.modal-w.items {
    background-color: rgba(0, 0, 0, 0.7);
}

.modal-p {
    width: 30%;
    height: auto;
    background-color: #FFFFFF;
}

.modal-p.items {
    width: 65%;
}

.modal-p .btn-close {
    top: 10px;
    right: 10px;
    position: absolute;
}

.tabs > input,
.tabs > span,
.effect-3 .line {
    width: 50%;
}

#tab-2,
#tab-2 + span,
#tab-2:checked ~ .line {
    left: 50%;
}

.form-control {
    height: 42px;
    border-radius: 0;
    border-color: #282828;
}

.form-control:focus {
    outline: 0;
    color: #212529;
    box-shadow: none;
    border-color: #282828;
    background-color: #fff;
}

.input-group-text {
    color: #FFFFFF;
    line-height: 17px;
    border: 1px solid #F25C05;
    background-color: #F25C05;
}

.btn-group {
    top: 0;
    right: 40px;
    margin: 6px;
    z-index: 20;
    position: absolute;
}

.btn-main {
    color: #F25C05;
    --bs-btn-border-width: 0;
}

.btn-main:active,
.btn-main:focus,
.btn-main:hover,
.btn-main:focus-visible {
    border: 0;
    color: #F25C05;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000000;
    background-color: #f0f0f0;
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button:focus {
    box-shadow: none;
}

@media (max-width: 768px) {
    .modal-p,
    .modal-p.items{
        width: 98%;
    }
}
</style>