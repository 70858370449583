<template>
    <painel-component :items="itemsOne">
        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component disabled type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect disabled v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component :class-row-col="'col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'"
                                   :class="{ 'formError': errors.permissions }"
                                   v-for="(permission, index) in permissionsPage" :key="index">
                    <div class="form-check check-box-header">
                        <input disabled class="form-check-input" type="checkbox" :value="index"
                               :id="'checkBoxMaster' + index" :name="index">
                        <label class="form-check-label">
                            {{ permission.label }}
                        </label>
                    </div>
                    <div class="form-check" v-for="(option, indexTwo) in permission.options" :key="indexTwo">
                        <input disabled type="checkbox" :value="option.value" :id="'checkOne' + option.value"
                               :class="'form-check-input checkBoxAll' + index">
                        <label class="form-check-label">
                            {{ option.label }}
                        </label>
                    </div>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "UserGroupShow",
    components: {
        ButtonRoute,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Grupo de Usuários")
        this.$store.dispatch("loadPermissionsPage")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "eye",
                title: "Ver"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user-group/search"
            },

            formData: {
                id: this.id,
                name: "",
                status: "",
                permissions: []
            },

            showLoader: false
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadGroup', this.id)
                .then((r) => {
                    this.formData = r;
                    setTimeout(() => {
                        this.checkBoxCheckedStart()
                    }, 1000)
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 1)
                .then((r) => {
                    if (r.data.indexOf('group-user-search') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        checkBoxCheckedStart() {
            let totalPage = 0;
            let cont = 0;
            let i = 0;
            let permissionsList = this.formData.permissions;
            this.permissionsPage.forEach(function (element) {
                cont = 0;
                totalPage = element.options.length;
                element.options.forEach(function (item) {
                    if (permissionsList.includes(item.value)) {
                        document.getElementById("checkOne" + item.value).checked = true
                        cont = cont + 1;
                    }
                });
                if (cont >= totalPage) {
                    document.getElementById("checkBoxMaster" + i).checked = true
                }
                i = i + 1;
            });
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        permissionsPage() {
            return this.$store.state.var.permissionsPage
        }
    }
}
</script>