<template>
    <div class="painel-component">
        <div class="icon-painel" v-if="items.icon">
            <i :class="'icon ' + items.icon"></i>
        </div>

        <h2>{{ items.title }}</h2>

        <div class="painel-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PainelComponent",

    props: {
        items: {
            "required": false,
            "type": Object,
            "default": []
        },
    },
}
</script>

<style scoped>
.painel-component {
    padding: 2%;
    position: relative;
    margin: 5% 2% 5% 2%;
    background-color: white;
    box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -moz-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
    -webkit-box-shadow: 10px 10px 23px 0 rgba(204, 204, 204, 1);
}

.painel-component .icon-painel {
    top: -40px;
    width: 80px;
    height: 80px;
    padding: 15px;
    position: absolute;
    border-radius: 0;
    background-color: #F25C05;

}

.painel-component h2 {
    color: #000;
    top: -19px;
    left: 100px;
    font-size: 20px;
    font-weight: 50;
    width: fit-content;
    position: relative;
    font-family: 'Comfortaa', cursive;
}

.painel-component .icon-painel i {
    color: white;
    display: block;
    margin: 0 auto;
    font-size: 35px;
}

.painel-component .painel-content {
    clear: both;
}

@media (max-width: 991px) {
    .painel-component .icon-painel {
        top: -20px;
        width: 50px;
        height: 50px;
        padding: 10px;
    }

    .painel-component h2 {
        top: 0;
        left: 60px;
    }

    .painel-component .icon-painel i {
        font-size: 20px;
    }
}

@media (max-width: 776px) {
    .painel-component {
        margin-top: 10%;
    }
}
</style>