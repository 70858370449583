export const URL_BASE_ADMIN = 'api/v1/admin/'
export const URL_BASE_SYSTEM = 'api/v1/system/'
export const NAME_TOKEN = 'TOKEN_AUTH'

let money = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false /* doesn't work with directive */
}

export let MONEY = money