<template>
    <painel-component :items="itemsOne">
        <form>
            <row-component>
                <row-col-component :class-row-col="'col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12'">
                    <input type="radio" id="client" v-model="type" value="1">
                    <label for="client">Cliente</label>

                    <input type="radio" id="employee" v-model="type" value="2">
                    <label for="employee">Funcionário</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Código:</label>
                    <input-component v-model="formData.code" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Título:</label>
                    <input-component v-model="formData.title" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <label>Tipo:</label>
                    <Multiselect v-model="formData.client_employee_provider_type" :options="selectTypeReceipt"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   v-if="parseInt(type) === 1">
                    <label>Cliente:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id" :options="selectClientList"
                                 :searchable="true" :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12" v-else>
                    <label>Funcionário:</label>
                    <Multiselect label="name" v-model="formData.client_employee_provider_id"
                                 :options="selectEmployeeList" :searchable="true" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="me-2" icon="search"/>
                    <button-route v-if="buttonCheck.create" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>

        <table class="table table-striped" v-if="showLoader">
            <thead>
            <tr>
                <th scope="col" style="width: 16.6%">Código</th>
                <th scope="col" style="width: 16.6%">Criador</th>
                <th scope="col" style="width: 16.6%">Titulo</th>
                <th scope="col" style="width: 16.6%">Preço</th>
                <th scope="col" style="width: 16.6%">Nome</th>
                <th scope="col" style="width: 16.6%" v-if="buttonCheck.delete ||buttonCheck.edit || buttonCheck.show">
                    Ações
                </th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="(data, index) in data" :key="index">
                <td data-label="Código">{{ data.code }}</td>
                <td data-label="Criador">{{ data.creator_user_name }}</td>
                <td data-label="Titulo">{{ data.title }}</td>
                <td data-label="Preço">{{ data.price }}</td>
                <td data-label="Nome">{{ data.name }}</td>
                <td data-label="Ações" v-if="buttonCheck.delete ||buttonCheck.edit || buttonCheck.show">
                    <button-delete v-if="buttonCheck.delete" @click="destroy(data)"/>
                    <button-edit v-if="buttonCheck.edit" :id="data.id" route="receipt-edit"/>
                    <button-show v-if="buttonCheck.show" :id="data.id" route="receipt-show"/>
                </td>
            </tr>
            </tbody>
        </table>

        <button-pagination :pagination="object.itens" :offset="3" @paginate="loadItems"/>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import ButtonShow from "@/components/buttons/ButtonShow";
import ButtonDelete from "@/components/buttons/ButtonDelete";
import ButtonEdit from "@/components/buttons/ButtonEdit";
import ButtonPagination from "@/components/buttons/ButtonPagination";

import {useToast} from "vue-toastification";
import swal from "sweetalert";

const toast = useToast()
export default {
    name: "ReceiptSearch",
    components: {
        ButtonPagination,
        ButtonEdit,
        ButtonDelete,
        ButtonShow,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    created() {
        this.$store.dispatch("loadClientList")
        this.$store.dispatch("loadEmployeeList")
        this.$store.dispatch("changeNamePage", "Recibo")
        this.checkACL()
        this.loadItems(1)
    },

    data() {
        return {
            itemsOne: {
                icon: "list alternative",
                title: "Pesquisar"
            },

            itemsTwo: {
                icon: "plus",
                route: "/receipt/create"
            },

            buttonCheck: {
                create: false,
                edit: false,
                show: false,
                delete: false
            },

            formData: {
                title: "",
                code: "",
                client_employee_provider_type: "",
                client_employee_provider_id: "",
            },

            type: "2",
            data: {},

            showLoader: false,
            searchSubmit: false,
        }
    },

    methods: {
        submit() {
            this.loadItems(1)
            this.searchSubmit = true;
        },

        loadItems(page) {
            if (!this.searchSubmit) {
                this.$store.dispatch("loadReceipts", {...this.params, page})
                    .then((r) => {
                        this.data = r.itens.data
                        if (this.searchSubmit) {
                            this.searchSubmit = false;
                            if (r.itens.data.length === 0) {
                                toast.info("Nada foi encontrado com os parâmetros informados!", {timeout: 1200});
                            }
                        }
                    }).catch(() => {
                    this.searchSubmit = false;
                });
            }
        },

        destroy(item) {
            swal({
                title: 'Atenção!',
                text: `Deseja realmente deletar ${item.name} ?`,
                closeOnClickOutside: false,
                buttons: {
                    cancel: {
                        text: 'Não',
                        visible: true,
                        closeModal: true,
                    },
                    confirm: "Sim"
                }
            }).then((result) => {
                if (result) {
                    this.$store.dispatch('destroyReceipt', item.id).then(() => {
                        this.loadItems(1)
                        toast.success("Cadastro deletado com sucesso", {timeout: 1200});
                    }).catch(() => {
                        toast.error(`Não foi possível deletar  ${item.name}`, {timeout: 1200});
                    })
                }
            });
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 30)
                .then((r) => {
                    if (r.data.indexOf('receipt-search') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('receipt-search') !== -1) {
                            this.buttonCheck.show = true
                        }

                        if (r.data.indexOf('receipt-create') !== -1) {
                            this.buttonCheck.create = true
                        }

                        if (r.data.indexOf('receipt-edit') !== -1) {
                            this.buttonCheck.edit = true
                        }

                        if (r.data.indexOf('receipt-delete') !== -1) {
                            this.buttonCheck.delete = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        selectTypeReceipt() {
            return this.$store.state.var.selectTypeReceipt
        },

        selectClientList() {
            return this.$store.state.var.selectClientList
        },

        selectEmployeeList() {
            return this.$store.state.var.selectEmployeeList
        },

        object() {
            return this.$store.state.receipt.items
        },

        params() {
            return {
                page: this.object.current_page,
                title: this.formData.title,
                code: this.formData.code,
                client_employee_provider_type: this.formData.client_employee_provider_type,
                client_employee_provider_id: this.formData.client_employee_provider_id,
            }
        }
    }
}
</script>