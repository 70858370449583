import MainComponent from "@/components/dashboard/MainComponent";
import NewsSearch from "@/views/search/NewsSearch";
import NewsCreate from "@/views/create/NewsCreate";
import NewsUpdate from "@/views/update/NewsUpdate";
import NewsShow from "@/views/show/NewsShow";

export default {
    path: "/news",
    component: MainComponent,
    meta: {auth: true},
    children: [
        {path: "search", name: "news-search", component: NewsSearch},
        {path: "create", name: "news-create", component: NewsCreate},
        {path: "edit/:id", name: "news-edit", props: true, component: NewsUpdate},
        {path: "show/:id", name: "news-show", props: true, component: NewsShow}
    ]
}