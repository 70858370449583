import axios from "axios";
import {URL_BASE_SYSTEM} from "@/configs/constants";

const RESOURCE = "release-access"

export default {
    loadReleasesAccess(context, params) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}`, {params})
                .then((response) => {
                    context.commit("LOAD_RELEASE_ACCESS", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    storeReleasesAccess(context, formData) {
        context.commit("LOADER", true)
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}${RESOURCE}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadReleaseAccess(context, id) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}${RESOURCE}/${id}`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    updateReleaseAccess(context, formData) {
        context.commit('PRELOADER', true)
        return new Promise((resolve, reject) => {
            axios.put(`${URL_BASE_SYSTEM}${RESOURCE}/${formData.id}`, formData)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => {
                context.commit("LOADER", false)
            })
        })
    },

    loadReleaseAccessPermissions(context) {
        context.commit('LOADER', true)
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}release-access-list-permissions`)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            }).finally(() => context.commit('LOADER', false))
        })
    },

    storeReleaseAccessPermissions(context, formData) {
        return new Promise((resolve, reject) => {
            axios.post(`${URL_BASE_SYSTEM}release-access-add-to-delete-permissions`, formData)
                .then((response) => {
                    return resolve(response)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    loadReleasesAccessList(context, params) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}release-access-list`, {params})
                .then((response) => {
                    context.commit("LOAD_RELEASE_ACCESS_LIST", response.data)
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    },

    releaseAccessGetClientId(context, id) {
        return new Promise((resolve, reject) => {
            axios.get(`${URL_BASE_SYSTEM}release-access-get-client-id/${id}`,)
                .then((response) => {
                    return resolve(response.data)
                }).catch((errors) => {
                return reject(errors)
            })
        })
    }

}