import MainComponent from "@/components/dashboard/MainComponent";

import MenuTableSearch from "@/views/search/MenuTableSearch";
import MenuTableCreate from "@/views/create/MenuTableCreate";
import MenuTableUpdate from "@/views/update/MenuTableUpdate";
import MenuTableShow from "@/views/show/MenuTableShow";

import MenuTableItems from "@/views/search/MenuTableItems";

export default {
    path: "/menu-table",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "menu-table-search", component: MenuTableSearch},
        {path: "create", name: "menu-table-create", component: MenuTableCreate},
        {path: "edit/:id", name: "menu-table-edit", props: true, component: MenuTableUpdate},
        {path: "show/:id", name: "menu-table-show", props: true, component: MenuTableShow},

        {path: "search/:id", name: "menu-table-item", props: true, component: MenuTableItems}
    ]
}