import MainComponent from "@/components/dashboard/MainComponent";

import EntranceExitPreviewSearch from "@/views/search/EntranceExitPreviewSearch";
import EntranceExitPreviewCreate from "@/views/create/EntranceExitPreviewCreate";

export default {
    path: "/entrance-exit-preview",
    meta: {auth: true},
    component: MainComponent,
    children: [
        {path: "search", name: "entrance-exit-preview-search", component: EntranceExitPreviewSearch},
        {path: "create", name: "entrance-exit-preview-create", component: EntranceExitPreviewCreate}
    ]
}