<template>
    <painel-component :items="itemsOne">
        <error-component :errors="errors" v-if="showError"/>
        <div class="clearfix">
            <button-route class="float-end" v-if="create" :items="itemsCreate"/>
        </div>

        <form v-if="showLoader">
            <div class="aux mb-4 mt-3">
                <div class="avatar-form">
                    <img v-if="formData.photo" :src="formData.photo" class="avatar__image" alt="">
                    <img v-else src="@/assets/avatar.png" class="avatar__image" alt="">
                </div>
                <p>{{ formData.name }}</p>
            </div>

            <row-component>
                <row-col-component
                        :class-row-col="'col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-2 mb-2'">
                    <label>Sexo:</label>

                    <input type="radio" id="nao-phone" name="teste" v-model="formData.sexo" value="1">
                    <label for="nao-phone">Feminino</label>

                    <input type="radio" id="sim-phone" name="teste" v-model="formData.sexo" value="2">
                    <label for="sim-phone">Masculino</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Nome:</label>
                    <input-component type-input="text" v-model="formData.name"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Email:</label>
                    <input-component type-input="email" v-model="formData.email"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                    <label>Grupo:</label>
                    <Multiselect v-model="formData.group_id" :searchable="true" :options="selectGroupList"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 pt-2 mt-4">
                    <switch-component text-check-box="Alterar a senha" @check-click="passwordChange"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-if="updatePassword">
                    <label>Senha</label>
                    <input-component type-input="password" v-model="formData.password"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   v-if="updatePassword">
                    <label>Confirmação de Senha:</label>
                    <input-component type-input="password" v-model="formData.passwordconfirm"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="w-100" :disabled="sendForm" icon="save" name="Salvar"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import SwitchComponent from "@/components/form/SwitchComponent";

const toast = useToast()
export default {
    name: "UserUpdate",
    components: {
        SwitchComponent,
        ErrorComponent,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "Usuário")
        this.$store.dispatch("loadGroupList")
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/user/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/user/create"
            },

            create: false,

            formData: {
                id: this.id,
                name: "",
                sexo: "",
                status: "",
                email: "",
                group_id: "",
                password: "",
                passwordconfirm: "",
                updatepassword: "",
                photo: '',
            },

            updatePassword: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        loadItems() {
            this.$store.dispatch('loadUser', this.id)
                .then((r) => {
                    this.formData = r;
                    this.formData.updatepassword = false;
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateUser', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Usuário atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 2000});
                })
            }
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 2)
                .then((r) => {
                    if (r.data.indexOf('user-edit') !== -1) {
                        this.showLoader = true

                        if (r.data.indexOf('user-create') !== -1) {
                            this.create = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        passwordChange() {
            this.updatePassword = !this.updatePassword
            this.formData.updatepassword = this.updatePassword
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectGroupList() {
            return this.$store.state.var.selectGroupList
        },
    }
}
</script>