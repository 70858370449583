<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>
        <div class="clearfix mb-3">
            <button-route class="float-end" :items="itemsCreate"/>
        </div>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Carousel :breakpoints="breakpoints">
                        <Slide v-for="(data, index) in thumbs" :key="index">
                            <div class="carousel__item">
                                <input v-model="formData.layout" :value="index" type="radio" name="thumbs"
                                       :id="'thumbs' + index">
                                <label :for="'thumbs' + index">
                                    <img :src="data.thumb" alt=""/>
                                </label>
                            </div>
                        </Slide>
                        <template #addons>
                            <Navigation/>
                        </template>
                    </Carousel>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome do APP:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.city_id}">
                    <label>Cidade:</label>
                    <Multiselect v-model="formData.city_id" :options="selectClimateListCities"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.time}">
                    <label>Tempo de exibição em segundos:</label>
                    <input-component v-model="formData.time" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :options="selectActiveInative" :noResultsText="noResultsText"
                                 :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect label="name" v-model="formData.company_id" :options="selectCompanyList"
                                 :noResultsText="noResultsText" :noOptionsText="noOptionsText" :searchable="true"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="w-100" name="Salvar" icon="save"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>
<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";

import {Carousel, Navigation, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {useToast} from "vue-toastification";

const toast = useToast()
export default {
    name: "WeatherUpdate",
    components: {
        Slide,
        Navigation,
        Carousel,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.$store.dispatch("changeNamePage", "App Clima")
        this.$store.dispatch("loadClimateListCities", this.idClient)
        this.loadStorage()
        this.loadItems()
        this.checkACL()
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                name: "Voltar",
                route: "/weather/search"
            },

            itemsCreate: {
                icon: "plus",
                route: "/weather/create"
            },

            buttonCheck: {
                create: false,
            },

            thumbs: [
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/clima/thumbsnail/clima_d7m.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/clima/thumbsnail/clima_tempo.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/clima/thumbsnail/clima_methoduz.jpg'},
                {thumb: 'https://methoduz.s3.amazonaws.com/system/app/clima/thumbsnail/clima.jpg'},
            ],

            breakpoints: {
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                },

                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
            },

            formData: {
                id: this.id,
                layout: '',
                name: '',
                city_id: '',
                time: '',
                status: '',
                company_id: ''
            },

            params: {
                id: this.id,
                client_id: ""
            },

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateWeatherApp', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Aplicativo atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadItems() {
            this.$store.dispatch('loadWeatherApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 11)
                .then((r) => {
                    if (r.data.indexOf('app-clima-edit') !== -1) {
                        this.showLoader = true
                        if (r.data.indexOf('app-clima-create') !== -1) {
                            this.buttonCheck.show = true
                        }
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },
    },

    computed: {
        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectClimateListCities() {
            return this.$store.state.var.selectClimateListCities
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },
    }
}
</script>

<style scoped>
.carousel__item {
    width: 100%;
    display: flex;
    font-size: 20px;
    min-height: 200px;
    align-items: center;
    justify-content: center;
}

input[type="radio"][id^="cb"] {
    display: none;
}

.carousel__item label {
    width: 100%;
    padding: 0 10px;
    cursor: pointer;
}

.carousel__item label img {
    width: 100%;
    height: auto;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
}

.carousel__item label:before {
    content: " ";
    top: 0;
    left: 5%;
    z-index: 1;
    width: 25px;
    height: 25px;
    color: white;
    display: block;
    line-height: 22px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    transform: scale(0);
    border: 1px solid grey;
    background-color: white;
    transition-duration: 0.4s;
}

.carousel__item input:checked + label:before {
    content: "✓";
    transform: scale(1);
    background-color: grey;
}

.carousel__item input[type="radio"] + label::after {
    display: none;
}

.carousel__item input:checked + label img {
    z-index: -1;
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
}
</style>