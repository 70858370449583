<template>
    <textarea class="form-control" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"/>
</template>

<script>
export default {
    name: "TextArea",

    props: {
        modelValue: {
            type: String,
            default: '',
            required: false
        },
    },
}
</script>

<style scoped>
textarea {
    resize: none;
}

.form-control {
    border-radius: 0;
    border-color: #282828;
}

.form-control:focus {
    outline: 0;
    color: #212529;
    box-shadow: none;
    border-color: #282828;
    background-color: #fff;
}
</style>