<template>
    <painel-component :items="itemsOne">
        <error-component v-if="showError" :errors="errors"/>

        <div class="modal-window" v-if="modal_bg">
            <MenuTableBackground @close-folder="openModal" :id="this.id"/>
        </div>

        <div class="clearfix mb-3">
            <div class="btn-group float-end" role="group" aria-label="Basic example">
                <button-submit icon="image" @click="openModal"/>
                <button-submit icon="eye" @click="preview"/>
                <button-submit icon="list" @click="this.$router.push({name: 'menu-table-item', params:{id: this.id}})"/>
                <button-submit icon="plus" @click="this.$router.push({name: 'menu-table-create'})"/>
            </div>
        </div>

        <form v-if="showLoader">
            <row-component>
                <row-col-component class-row-col="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.name}">
                    <label>Nome do APP:</label>
                    <input-component v-model="formData.name" type-input="text"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.status}">
                    <label>Status:</label>
                    <Multiselect v-model="formData.status" :searchable="true" :options="selectActiveInative"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.company_id}">
                    <label>Empresa:</label>
                    <Multiselect v-model="formData.company_id" label="name" :searchable="true"
                                 :options="selectCompanyList" :noOptionsText="noOptionsText"
                                 :noResultsText="noResultsText"/>
                </row-col-component>
            </row-component>

            <hr>
            <h5 class="text-uppercase">Configurações do Layout</h5>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_id}">
                    <label>Fonte:</label>
                    <Multiselect v-model="formData.font_id" :searchable="true" :options="selectFontsList"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.text_align}">
                    <label>Alinhamento Texto:</label>
                    <Multiselect v-model="formData.text_align" :searchable="true" :options="selectAlignment"
                                 :noOptionsText="noOptionsText" :noResultsText="noResultsText"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.margin_top}">
                    <label>Margem Topo %:</label>
                    <input-component v-model="formData.margin_top" type-input="number"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.list_transparency}">
                    <label>Transparência %:</label>
                    <input-component v-model="formData.list_transparency" type-input="number"/>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_color_1}">
                    <label>Cor da 1º fonte:</label>
                    <input-component v-model="formData.font_color_1" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.list_color_1}">
                    <label>Cor da 1º listra:</label>
                    <input-component v-model="formData.list_color_1" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_color_2}">
                    <label>Cor da 2º fonte:</label>
                    <input-component v-model="formData.font_color_2" type-input="color"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.list_color_2}">
                    <label>Cor da 2º listra:</label>
                    <input-component v-model="formData.list_color_2" type-input="color"/>
                </row-col-component>
            </row-component>

            <row-component class="my-4">
                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.text_upercase}">
                    <label>Texto em Maiúsculo:</label>
                    <input v-model="formData.text_upercase" type="radio" id="no-uppercase" value="1">
                    <label for="no-uppercase">Não</label>

                    <input v-model="formData.text_upercase" type="radio" id="yes-uppercase" value="2">
                    <label for="yes-uppercase">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_bold}">
                    <label>Fonte em Negrito:</label>
                    <input v-model="formData.font_bold" type="radio" id="no-bold" value="1">
                    <label for="no-bold">Não</label>

                    <input v-model="formData.font_bold" type="radio" id="yes-bold" value="2">
                    <label for="yes-bold">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_italic}">
                    <label>Fonte em Itálico:</label>
                    <input v-model="formData.font_italic" type="radio" id="no-italic" value="1">
                    <label for="no-italic">Não</label>

                    <input v-model="formData.font_italic" type="radio" id="yes-italic" value="2">
                    <label for="yes-italic">Sim</label>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12"
                                   :class="{'formError': errors.font_underline}">
                    <label>Fonte em Sublinhado:</label>
                    <input v-model="formData.font_underline" type="radio" id="no-underlined" value="1">
                    <label for="no-underlined">Não</label>

                    <input v-model="formData.font_underline" type="radio" id="yes-underlined" value="2">
                    <label for="yes-underlined">Sim</label>
                </row-col-component>
            </row-component>

            <row-component>
                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-submit @click="submit" class="w-100" :disabled="sendForm" name="Salvar"
                                   icon="save"/>
                </row-col-component>

                <row-col-component class-row-col="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <button-route class="w-100" :items="itemsTwo"/>
                </row-col-component>
            </row-component>
        </form>
    </painel-component>
</template>

<script>
import PainelComponent from "@/components/dashboard/PainelComponent";
import ErrorComponent from "@/components/alerts/ErrorComponent";
import RowComponent from "@/components/row/RowComponent";
import RowColComponent from "@/components/row/RowColComponent";
import InputComponent from "@/components/form/InputComponent";
import Multiselect from "@vueform/multiselect";
import ButtonSubmit from "@/components/buttons/ButtonSubmit";
import ButtonRoute from "@/components/buttons/ButtonRoute";
import {useToast} from "vue-toastification";
import MenuTableBackground from "@/views/search/MenuTableBackground";

const toast = useToast()

export default {
    name: "MenuTableUpdate",
    components: {
        MenuTableBackground,
        ButtonRoute,
        ButtonSubmit,
        Multiselect,
        InputComponent,
        RowColComponent,
        RowComponent,
        ErrorComponent,
        PainelComponent
    },

    props: {
        id: {
            required: true
        }
    },

    created() {
        this.checkACL()
        this.loadStorage()
        this.loadItems()
        this.$store.dispatch("loadFontsList", this.idClient)
        this.$store.dispatch("changeNamePage", "App Menu Tabela")
    },

    data() {
        return {
            itemsOne: {
                icon: "edit",
                title: "Editar"
            },

            itemsTwo: {
                icon: "reply all",
                route: "/menu-table/search",
                name: "Voltar",
            },

            formData: {
                client_id: "",
                name: "",
                status: "",
                company_id: "",
                font_id: "",
                margin_top: "",
                list_transparency: "",
                text_align: "",
                font_color_1: "#000000",
                font_color_2: "#000000",
                list_color_1: "#000000",
                list_color_2: "#000000",
                text_upercase: "",
                font_bold: "",
                font_italic: "",
                font_underline: "",
                urlPreview: ""
            },

            params: {
                id: this.id,
                client_id: ""
            },

            modal_bg: false,

            errors: [],
            sendForm: false,
            showError: false,
            showLoader: false,
        }
    },

    methods: {
        submit() {
            if (!this.sendForm) {
                this.sendForm = true;
                this.$store.dispatch('updateMenuTableApp', this.formData)
                    .then(() => {
                        this.showError = false
                        this.sendForm = false
                        this.errors = []
                        toast.success("Aplicativo atualizado com sucesso", {timeout: 1200});
                    }).catch((r) => {
                    this.showError = true;
                    this.sendForm = false
                    this.errors = r.response.data.errors;
                    window.scrollTo(0, 0)
                    toast.error("Seu formulário contém erros verificar!", {timeout: 1200});
                })
            }
        },

        loadItems() {
            this.$store.dispatch('loadMenuTableApp', this.params)
                .then((r) => {
                    this.formData = r;
                    this.formData.id = this.id
                    this.formData.client_id = this.params.client_id
                }).catch(() => {
                toast.error("Erro desconhecido !", {timeout: 1200});
            })
        },

        loadStorage() {
            this.$store.commit("ID_CLIENT", localStorage.getItem("id_client"))
            this.$store.dispatch("loadCompanyList", this.idClient)
            this.params.client_id = this.idClient
        },

        async checkACL() {
            this.$store.dispatch('ACLItems', 15)
                .then((r) => {
                    if (r.data.indexOf('app-menu-table-create') !== -1) {
                        this.showLoader = true
                    } else {
                        this.$router.push({name: 'home'})
                    }
                }).catch(() => {
                this.$router.push({name: 'home'})
            })
        },

        openModal() {
            this.modal_bg = !this.modal_bg
        },

        preview() {
            window.open(this.formData.urlPreview, "_blank")
        }
    },

    computed: {
        noOptionsText() {
            return this.$store.state.var.noOptionsText
        },

        noResultsText() {
            return this.$store.state.var.noResultsText
        },

        idClient() {
            return this.$store.state.var.idClient
        },

        selectActiveInative() {
            return this.$store.state.var.selectActiveInative
        },

        selectCompanyList() {
            return this.$store.state.var.selectCompanyList
        },

        selectAlignment() {
            return this.$store.state.var.selectAlignment
        },

        selectFontsList() {
            return this.$store.state.var.selectFontsList
        }
    }
}
</script>

<style scoped>

.modal-window {
    top: 0;
    left: 0;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    display: grid;
    position: fixed;
    place-items: center;
    background: rgba(0, 0, 0, 0.7);
}

</style>